import { validPassword } from '@utils';
import React, { useState } from 'react';
import PasswordIcon from '@icons/password.svg';
import OpenEyeIcon from '@icons/eye.svg';
import CloseEyeIcon from '@icons/hideeye.svg';
import { CustomInput, Input } from '../input';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

export const Password = (props: CustomInput) => {
  const { watch, formState } = useFormContext();
  const [showPwd, setShowPwd] = useState<boolean>(true);
  const inputValue = watch('password');
  const repwdinputValue = watch('re-password');
  const errors = Boolean(formState.errors?.password);

  if (props.PwdValidation && props.name == 'password') {
    const isContainsUppercase = /^(?=.*[A-Z])/;
    const isContainsLowercase = /^(?=.*[a-z])/;
    const isContainsNumber = /^(?=.*[0-9])/;
    const isValidLength = /^.{8,24}$/;
    const hasSpecialCharacters = new RegExp(/[!@#$%^&*]/, 'gm');

    let charCheck = false;
    let numberCheck = false;
    let alphaCheck = false;
    let specialCharsCheck = false;

    // Password must contain one lower & uppercase letter
    if (isContainsUppercase.test(inputValue) && isContainsLowercase.test(inputValue)) {
      charCheck = true;
    }

    // "Password must contain at least one Digit.";
    if (isContainsNumber.test(inputValue)) {
      numberCheck = true;
    }

    // "Password must be 8-24 Characters Long.";
    if (isValidLength.test(inputValue)) {
      alphaCheck = true;
    }

    // "Password must have a special char !@#$%^&*
    if (hasSpecialCharacters.test(inputValue)) {
      specialCharsCheck = true;
    }

    props.PwdValidation(
      { charCheck, numberCheck, alphaCheck, specialCharsCheck },
      !!repwdinputValue && inputValue != repwdinputValue,
    );
  }

  return (
    <Input
      wrapperClassName="w-full"
      name="password"
      type={showPwd ? 'password' : 'text'}
      icon={
        props.showIcon ? (
          <PasswordIcon
            placeholder="your.email@example.com"
            width={16}
            className={clsx('keep-color', {
              'stroke-primary': inputValue?.length > 0,
              'stroke-gray-500': inputValue?.length === 0,
              'stroke-red': errors,
            })}
          />
        ) : (
          ''
        )
      }
      rightIcon={
        showPwd ? (
          <CloseEyeIcon
            width={16}
            className={clsx('keep-color', {
              'stroke-primary': inputValue?.length > 0,
              'stroke-gray-500': inputValue?.length === 0,
              'stroke-red': errors,
            })}
            onClick={() => {
              setShowPwd(false);
            }}
          />
        ) : (
          <OpenEyeIcon
            width={16}
            className={clsx('keep-color', {
              'stroke-primary': inputValue?.length > 0,
              'stroke-gray-500': inputValue?.length === 0,
              'stroke-red': errors,
            })}
            onClick={() => {
              setShowPwd(true);
            }}
          />
        )
      }
      validations={{
        required: true,
        validate: (password) => {
          if (props.checkValidation && props.name == 'password' && !validPassword.test(password)) {
            return 'This password is invalid, please try again.';
          }
        },
      }}
      {...props}
    />
  );
};
