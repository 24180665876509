import { useAuthUser } from '@hooks';
import React from 'react';
import CoBorrowerDetails from './co-borrower-details';
import { DisAssociateCoBorrower } from './disassociation-consent';
import CoBorrowerInviteDisplay from './invite-display';
import { InviteCoBorrower } from './InviteCoBorrower';

const CoBorrowerWrapper = () => {
  const user = useAuthUser();
  return user?.profile?.coBorrower ? (
    <>
      <CoBorrowerDetails />
      <DisAssociateCoBorrower />
    </>
  ) : user?.profile?.coBorrowerInvited ? (
    <CoBorrowerInviteDisplay />
  ) : (
    <InviteCoBorrower />
  );
};

export default CoBorrowerWrapper;
