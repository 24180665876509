import { Select } from '../select';

export const HispanicOrigin = (props) => {
  return (
    <Select
      name="hispanicOrigin"
      showRequired={false}
      options={[
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ]}
      label="Hispanic Origin"
      {...props}
    />
  );
};
