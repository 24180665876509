import { FormWrapper, Steps } from '@components';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthUser, useBuyerSigninInfo } from '@hooks';
import { useMutation } from 'react-query';
import { ApiService, EUserType } from '@services/Api';

export const Step2 = () => {
  const { authenticationId, setBuyerInfo, step, email } = useBuyerSigninInfo();
  const { updateUserInfo } = useAuthUser();
  const methods = useForm();
  const [code, setCode] = useState([]);
  const strCode = code.join('');

  const signInMutation = useMutation(
    async () => {
      const resp = await ApiService.signIn({
        authenticationId,
        userType: EUserType.buyer,
        code: strCode,
      });
      return resp.data;
    },
    {
      onSuccess: (data) => {
        setBuyerInfo({ authenticationId: null });

        const remappedUserType = data.user.userType === 'buyer' ? 'buyer' : 'partner';
        updateUserInfo({
          ...data.user,
          userType: remappedUserType,
          accessToken: data.accessToken,
          signedIn: true,
        });
      },
      onError: () => {
        setCode([]);
      },
    },
  );

  const resendCodeMutation = useMutation(async () => {
    const resp = await ApiService.resendCode({ id: authenticationId, type: 'authentication' });
    return resp.data;
  });

  useEffect(() => {
    if (strCode.length >= 5) {
      signInMutation.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strCode]);

  return (
    <FormWrapper
      onBackClick={() => setBuyerInfo({ step: step - 1 })}
      isLoading={signInMutation.isLoading || resendCodeMutation.isLoading}
      maxWidth={450}
      methods={methods}
      containerClassName="lg:px-0 mb-0 lg:-mt-20"
    >
      <div className="flex flex-col lg:my-auto">
        <Steps.Title>We sent you a code!</Steps.Title>
        <div className="flex items-center gap-1 mt-2 mb-8">
          <p className="text-sm text-gray-500 dark:text-gray-400">Check your email {email}</p>
        </div>
        <Steps.CodeInput
          code={code}
          setCode={setCode}
          onCodeResent={resendCodeMutation.mutate}
          email={email}
          error={signInMutation.error || resendCodeMutation.error}
          sendCodeInfo
        />
        <Steps.ButtonContainer>
          <Steps.BackButton onClick={() => setBuyerInfo({ step: step - 1 })} />
        </Steps.ButtonContainer>
      </div>
    </FormWrapper>
  );
};
