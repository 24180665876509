import { useMutation } from 'react-query';
import { ApiService } from '@services/Api';
import { create } from 'zustand';
import { useAuthUser } from '@hooks/use-auth';
import { useDebtsQuery, useIncomeQuery, useSavingsQuery } from '../use-dashboard-queries';

export const useSyncStore = create<{
  isOpenSyncModal: boolean;
  setIsOpenSyncModal: (isOpenSyncModal: boolean) => void;
  setSyncStatus: (status: 'idle' | 'loading' | 'error' | 'successful') => void;
  syncStatus: 'idle' | 'loading' | 'error' | 'successful';
}>((set) => ({
  isOpenSyncModal: false,
  setIsOpenSyncModal: (isOpenSyncModal: boolean) => set({ isOpenSyncModal }),
  setSyncStatus: (status) => set({ syncStatus: status }),
  syncStatus: 'idle',
}));

export const useSyncData = (fetchMoneyDataOnMount = true) => {
  const { refetchUserInfo, updateUserInfo } = useAuthUser();
  const { setSyncStatus } = useSyncStore();
  const incomeQuery = useIncomeQuery(fetchMoneyDataOnMount);
  const debtsQuery = useDebtsQuery(fetchMoneyDataOnMount);
  const savingsQuery = useSavingsQuery(fetchMoneyDataOnMount);

  const mutation = useMutation(
    async () => {
      setSyncStatus('loading');

      // This will update the profile state to show the user that the data is being fetched
      const interval = setInterval(() => {
        refetchUserInfo();
        clearInterval(interval);
      }, 1000); // This is 100 because we want it to go off immediately

      const resp = await ApiService.syncVerifiedData();
      return resp.data;
    },
    {
      onSettled: () => {
        updateUserInfo({ dataSynced: true });
        refetchUserInfo();
        incomeQuery.refetch();
        debtsQuery.refetch();
        savingsQuery.refetch();
      },
    },
  );

  return mutation;
};
