import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

// These two add up, so since we want to logout user
// after 15m.
// 780000 - 13min .
const idleTimeout = 780000;
// 120000 - 2min .
const promptTimeout = 120000;

export const useIdleLogout = (user) => {
  const router = useRouter();

  const [showIdle, setShowIdle] = useState<boolean>(false);
  const [remaining, setRemaining] = useState<number>(idleTimeout);

  const showIdlePrompt = () => {
    setShowIdle(true);
  };

  const logoutAfterIdle = () => {
    setShowIdle(false);
    user.logout();
    router.push('/user/get-started');
  };

  const resetIdleTimer = () => {
    setShowIdle(false);
    idleTimer.activate();
  };

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptTimeout,
    onPrompt: showIdlePrompt,
    onIdle: logoutAfterIdle,
    startManually: true,
    stopOnIdle: true,
  });

  // Logged in time effects
  useEffect(() => {
    let interval;

    if (showIdle) {
      interval = setInterval(() => {
        setRemaining(Math.ceil(idleTimer.getRemainingTime() / 1000));
      }, 500);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showIdle]);

  useEffect(() => {
    if (user) {
      if (user.signedIn) {
        idleTimer.activate();
      } else {
        idleTimer.pause();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.signedIn]);

  return {
    showIdle,
    remaining,
    resetIdleTimer,
  };
};
