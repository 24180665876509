import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { YearCalendar } from '@mui/x-date-pickers/YearCalendar';
import { MonthCalendar } from '@mui/x-date-pickers/MonthCalendar';
import 'dayjs/locale/es-us';
import { Dayjs } from 'dayjs';

export const MonthModalContent = ({
  selectedDate,
  maxDate,
  onChange,
}: {
  maxDate?: Dayjs;
  selectedDate: Dayjs;
  onChange: (value: Dayjs) => void;
}) => {
  const handleChange = (selDate) => {
    if (
      selDate.get('year') === maxDate.get('year') &&
      maxDate.get('month') < selDate.get('month')
    ) {
      onChange(maxDate);
    } else {
      onChange(selDate);
    }
  };

  return (
    <div className="flex">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="us">
        <YearCalendar maxDate={maxDate} value={selectedDate} onChange={handleChange} />
        <MonthCalendar
          // need to disable future months like this as playing around with maxDate
          // state changes doesn't seem to work properly
          shouldDisableMonth={(monthDate: Dayjs) => {
            if (
              monthDate.get('year') === maxDate.get('year') &&
              maxDate.get('month') < monthDate.get('month')
            ) {
              return true;
            }

            return false;
          }}
          value={selectedDate}
          onChange={handleChange}
        />
      </LocalizationProvider>
    </div>
  );
};
