import { AutoCompleteLocation } from './address-autocomplete';
import { BirthDate } from './birth-date';
import { Checkbox } from './checkbox';
import { CheckBoxOptions } from './checkbox-options';
import { ChildrenSelect } from './children-select';
import { City } from './city';
import { CoBorrowerInput } from './co-borrower';
import { Country } from './country';
import { CountryPhone } from './country-phone';
import { Currency } from './currency';
import { Email } from './email';
import { ExpectedPurchaseAt } from './expected-purchase-at';
import { Gender } from './gender';
import { HispanicOrigin } from './hispanic-origin';
import { HousePriceGoal } from './house-price-goal';
import { Input } from './input';
import { MilitaryStatus } from './military-status';
import { MultiSelect } from './multi-select';
import { Name } from './name';
import { Occupation } from './occupation';
import { Password } from './password';
import { PurchaseHomeTimeDropdown } from './purchase-home-time-select';
import { Race } from './race';
import { Radio, RadioWrapper } from './radio';
import { ReasonForPurchase } from './reason-for-purchase';
import { Select } from './select';
import { SelectExpectedPurchasePeriod } from './select-expected-purchase-period';
import { SSN } from './ssn';
import { State } from './state';
import { Switch } from './switch';
import { TextArea } from './text-area';
import { TimePills } from './time-pills';
import { Title } from './title';
import { WishedLocation } from './wished-locations';
import { ZipCode } from './zip-code';
import { DateSelector } from './date';

export const CustomInput = {
  Email,
  Password,
  Input,
  Name,
  Select,
  Checkbox,
  TextArea,
  Radio,
  RadioWrapper,
  Switch,
  Country,
  City,
  Title,
  CountryPhone,
  Currency,
  Gender,
  Race,
  MilitaryStatus,
  ChildrenSelect,
  ReasonForPurchase,
  State,
  ZipCode,
  BirthDate,
  SSN,
  Occupation,
  HousePriceGoal,
  TimePills,
  SelectExpectedPurchasePeriod,
  PurchaseHomeTimeDropdown,
  CheckBoxOptions,
  HispanicOrigin,
  CoBorrowerInput,
  AutoCompleteLocation,
  MultiSelect,
  WishedLocation,
  ExpectedPurchaseAt,
  DateSelector,
};
