import { apiRequestClient } from '../client';
import {
  Buyer,
  BuyerOnboardingStatus,
  BuyerProfile,
  CalculateScoreResponse,
  EOnboardingPathTaken,
  User,
} from '../types';

type UserFields = Pick<
  User,
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phone'
  | 'password'
  | 'acceptPhoneConsent'
  | 'declinePhoneConsent'
>;

export type SignUpBuyerBody = {
  authenticationId: string;
  accessCode: string;
  partnerCode: string;
  lenderCode: string;
  provider: string;
  partnerCodeStatus: boolean;
  associateIdStatus: boolean;
  associateId: string;
  inviteId?: string;
} & UserFields &
  Pick<Buyer, 'housePriceGoal' | 'wishedLocations' | 'expectedPurchaseMonthsPeriod'>;

/**
 * Signs up a new buyer and returns access token
 */
export const signUpBuyer = (body: SignUpBuyerBody) => {
  return apiRequestClient()
    .method('post')
    .append('buyer/sign-up')
    .setData(body)
    .build<{ accessToken: string; buyer: Buyer; user: User }>();
};

/**
 * Validates access code, throws error if invalid
 */
export const validateBuyerAccessCode = (body: { authenticationId: string; accessCode: string }) => {
  return apiRequestClient()
    .method('post')
    .append('buyer/validate-access-code')
    .setData(body)
    .build<void>();
};

export type BuyerProfileFields = Pick<
  BuyerProfile,
  | 'housePriceGoal'
  | 'expectedPurchaseMonthsPeriod'
  | 'wishedLocations'
  | 'dateOfBirth'
  | 'gender'
  | 'race'
  | 'hispanicOrigin'
  | 'children'
  | 'childrenUnder18Count'
  | 'street'
  | 'city'
  | 'state'
  | 'zipCode'
  | 'occupation'
  | 'militaryStatus'
  | 'reasonForPurchase'
  | 'userJourney'
  | 'isWelcomeFlashCardsShown'
  | 'isRewardsWelcomeShown'
>;
export const updateBuyer = (body: Partial<BuyerProfileFields>) => {
  return apiRequestClient()
    .method('patch')
    .append('buyer')
    .setData(body)
    .build<{ profile: BuyerProfile }>();
};

export type RefreshBuyerOnboardingStatusBody = {
  skipTransactions?: boolean;
  skipCreditReport?: boolean;
  skipIncome?: boolean;
};
export const refreshBuyerOnboardingStatus = (body: RefreshBuyerOnboardingStatusBody) => {
  return apiRequestClient()
    .method('put')
    .setData(body)
    .append('buyer/refresh-onboarding-status')
    .build<BuyerOnboardingStatus>();
};

//userjourney
export const updateUserJourney = (body: { userJourney: string }) => {
  return apiRequestClient()
    .method('patch')
    .append('buyer/user-journey')
    .setData(body)
    .build<{ profile: BuyerProfile }>();
};

// update onboarding path taken
export const updateOnboardingPath = (body: { onboardingPathTaken: EOnboardingPathTaken }) => {
  return apiRequestClient()
    .method('put')
    .append('buyer/onboarding-path')
    .setData(body)
    .build<void>();
};

/**
 * Calculates the buyer score
 */
export const calculateScore = (body: { creditScore: number }) => {
  return apiRequestClient()
    .method('post')
    .append('buyer/calculate-score')
    .setData(body)
    .build<CalculateScoreResponse>();
};

/**
 * [Careful, this is a destructive action]
 * Deletes the buyer, and all associated data, including the user, plaid's items, plaid's accounts, array's profile, debts, etc...
 */
export const deleteBuyer = () => {
  return apiRequestClient()
    .method('delete')
    .append('buyer')
    .build<{ id: string; userId: string }>();
};

/**
 * [SANDBOX ONLY]
 * Sync the verified data for the buyer
 */
export const syncVerifiedData = () => {
  return apiRequestClient().method('post').append('buyer/sync-monthly-verified-data').build<{
    savings: boolean;
    paystubs: boolean;
    incomes: boolean;
    transactions: boolean;
    debts: boolean;
  }>();
};

export const buyerPartialValidate = (body: Partial<BuyerProfile>) => {
  return apiRequestClient()
    .method('post')
    .append('buyer/partial-validate')
    .setData(body)
    .build<void>();
};
