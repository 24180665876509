import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Select } from '../select';
import ReactInputMask from 'react-input-mask';
import clsx from 'clsx';
import { extractNumbers } from '@utils';

const otherAmountFieldName = 'otherAmount';

export const PurchaseHomeTimeDropdown = ({
  defaultValue,
  otherAmountDefaultValue,
  otherAmountSelectionDefault = 'years',
}) => {
  const [selectedVal, setSelectedVal] = useState(defaultValue);
  const [otherAmountSelection, setOtherAmountSelection] = useState(otherAmountSelectionDefault);

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (selectedVal !== 'another') return;
    register(otherAmountFieldName, {
      required: true,
      value: getValues('expectedPurchaseMonthsPeriod') || 0,
    });
  }, [getValues, register, selectedVal]);

  return (
    <>
      <Select
        validations={{
          validate: (value) => {
            const numberVal = Number(extractNumbers(value));
            if (Number.isNaN(numberVal) || numberVal <= 0 || numberVal > 999) {
              return 'Expected purchase month is required or should be greater than 0.';
            }
            return null;
          },
        }}
        showRequired
        name="expectedPurchaseMonthsPeriod"
        options={[
          { value: 6, label: '6 months' },
          { value: 12, label: '1 year' },
          { value: 24, label: '2 years' },
          { value: 60, label: '5 years' },
          { value: 120, label: '10 years' },
          { value: 'another', label: 'Another amount' },
        ]}
        showLabel={false}
        alternate
        placeholder="Select"
        value={selectedVal}
        onChange={({ value }) => setSelectedVal(value)}
      />

      {selectedVal === 'another' && (
        <div className="flex items-center mt-[10px]">
          <div
            className={clsx('border border-[#635E7D] border-r-0 rounded-l h-[48px] w-full', {
              'border-red': !!errors[otherAmountFieldName],
            })}
          >
            <ReactInputMask
              mask="999"
              name={otherAmountFieldName}
              defaultValue={otherAmountDefaultValue}
              onChange={(e) => {
                setValue('expectedPurchaseMonthsPeriod', +e.target.value);
                setValue(otherAmountFieldName, e.target.value, {
                  shouldDirty: true,
                  shouldTouch: true,
                  shouldValidate: true,
                });
              }}
              maskPlaceholder=""
              className="h-full px-3 w-full text-sm text-gray-900 rounded-l"
            />
            {!!errors[otherAmountFieldName] && <div className="text-red">Required</div>}
          </div>
          <Select
            name="otherAmountSelect"
            options={[
              { label: 'Months', value: 'months' },
              { label: 'Years', value: 'years' },
            ]}
            shouldRegister={false}
            showLabel={false}
            alternate
            placeholder="Select"
            value={otherAmountSelection}
            onChange={({ value }) => {
              setValue('otherAmountSelect', value);
              setOtherAmountSelection(value);
            }}
          />
        </div>
      )}
    </>
  );
};
