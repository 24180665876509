import { Select } from '../select';

export const Race = (props) => {
  return (
    <Select
      name="race"
      showRequired={false}
      options={[
        { label: 'American Indian or Alaskan Native', value: 'American Indian or Alaskan Native' },
        { label: 'Asian', value: 'Asian' },
        { label: 'Black or African American', value: 'Black or African American' },
        { label: 'Multi-Racial', value: 'Multi-Racial' },
        {
          label: 'Native Hawaiian or Other Paciific Islander',
          value: 'Native Hawaiian or Other Paciific Islander',
        },
        { label: 'White', value: 'White' },
        { label: 'I Prefer Not to Say', value: 'I Prefer Not to Say' },
      ]}
      label="Race"
      {...props}
    />
  );
};
