import { apiRequestClient } from '../client';
import { BuyerProfile, GoogleBuyerProfile, EUserFeedbackReason, CoBorrowerProfile } from '../types';

/**
 * Returns the current user profile information
 */
export const getProfile = () => {
  return apiRequestClient().method('get').append('auth/me').build<{
    profile: BuyerProfile;
  }>();
};

/**
 * Returns the current Google user profile information
 */
export const getGoogleProfile = () => {
  return apiRequestClient().method('get').append('auth/me').build<{
    profile: GoogleBuyerProfile;
  }>();
};

/**
 * Sends a referral link to the provided phone
 */
export const sendReferralLink = (phone: string) => {
  return apiRequestClient()
    .method('post')
    .append('send-referral-link')
    .setData({ phone })
    .build<{ ok: true }>();
};

/**
 * Sends a feedback to the support team
 */
export const sendFeedback = (reason: EUserFeedbackReason, feedback: string) => {
  return apiRequestClient()
    .method('post')
    .append('send-feedback')
    .setData({ reason, feedback })
    .build<{ ok: true }>();
};

/**
 * Gets the current user's position in the waiting list
 */
/*export const getWaitlistPosition = () => {
  return apiRequestClient()
    .method('get')
    .append('/user/waitlist-position')
    .build<{ position: number }>();
};*/

// /**
//  * Update the current user profile information,
//  */
// export const updateProfile = (
//   body: Partial<Pick<User, 'firstName' | 'lastName' | 'email' | 'phone'>>,
// ) => {
//   return apiRequestClient().method('patch').append('auth/me').setData(body).build<User>();
// };
