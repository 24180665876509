import { Input } from '../input';

export const Occupation = ({ required = true }: { required?: boolean }) => {
  return (
    <Input
      placeholder="Your job title"
      name="occupation"
      label="Occupation"
      validations={{ required }}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    />
  );
};
