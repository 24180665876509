import clsx from 'clsx';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Label } from '../label';

export const CheckBoxOptions = ({
  label,
  nameOnForm,
  options,
}: {
  label: string;
  nameOnForm: string;
  options: { label: string; value: string; disabled?: boolean }[];
}) => {
  const { setValue, getValues } = useFormContext();

  const defaultValue = useMemo(() => getValues(nameOnForm) || [], []);

  const handleSelect = (checked, value) => {
    const currValues = getValues(nameOnForm);

    if (checked) {
      currValues.push(value);
    } else {
      const valueIndex = currValues.indexOf(value);

      // according to the set up logic valueIndex should never be equal to -1
      // so if it became a -1 , check the logic of this flow you've changed
      if (valueIndex !== -1) {
        currValues.splice(valueIndex, 1);
      }
    }
    setValue(nameOnForm, currValues, { shouldDirty: true });
  };

  return (
    <div>
      <Label name={nameOnForm} className="mb-2">
        {label}
      </Label>
      {options.map((option, index) => (
        <label
          key={`option-${nameOnForm}-${index}`}
          className={clsx(
            'flex items-center gap-3 text-gray-800 dark:text-gray-100 cursor-pointer mb-4',
            {
              'opacity-50 pointer-events-none': !!option.disabled,
            },
          )}
        >
          <input
            disabled={option.disabled}
            className="rounded border-2 border-gray-300 bg-transparent text-primary cursor-pointer"
            type="checkbox"
            defaultChecked={defaultValue.includes(option.value)}
            onClick={(event) => {
              const target = event.target as HTMLInputElement;

              const checked = target.checked;
              handleSelect(checked, option.value);
            }}
          />
          <span className="text-sm font-medium text-justify">{option.label}</span>
        </label>
      ))}
    </div>
  );
};
