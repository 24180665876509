import { mockApi } from '@utils';
import { useMutation } from 'react-query';

export const useUpdateProfile = () => {
  const mutation = useMutation(async (values) => {
    await mockApi({ values });
  });

  const onSubmit = (values) => {
    mutation.mutate(values);
  };

  return { mutation, onSubmit };
};
