import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Label } from '../label';
import { InputProps } from '../input';
import { ErrorMessage } from '@components/error-message';
import InfoIcon from '@icons/info-2.svg';

export const TextArea = ({
  name,
  validations,
  responseError,
  showLabel = true,
  placeholder,
  icon,
  showRequired,
  mask,
  textArea = true,
  className,
  label: customLabel,
  maskPlaceholder,
  info,
  maxLength,
  description,
  labelClassName,
  ...inputProps
}: InputProps) => {
  const intl = useIntl();
  const {
    formState: { errors, isSubmitted },
    register,
    watch,
  } = useFormContext();

  const label = customLabel || intl.formatMessage({ id: `page.account.${name}` });
  const formatValidations = { ...validations };

  if (!validations?.pattern?.value) {
    formatValidations.pattern = {
      value: validations?.pattern,
      message: `This ${label.replace('*', '').toLowerCase()} is invalid, please try again.`,
    };
  }

  const requiredMessage = `${label.replace('*', '').toLowerCase()} is required.`;

  if (validations?.required || showRequired) {
    formatValidations.required =
      typeof validations.required === 'string' ? validations.required : requiredMessage;
  }

  if (mask && validations?.required) {
    formatValidations.validate = (value: string) => {
      if (value.includes('_')) {
        return requiredMessage;
      }
      return undefined;
    };
  }

  const errorInvalid = errors && errors[name]?.message && isSubmitted;

  const errorFieldAlreadyExists =
    responseError?.data?.error === 'fields_already_in_use' &&
    responseError?.data?.metadata?.fields?.includes(name.toLowerCase());

  const hasError = errorInvalid || errorFieldAlreadyExists;

  const qtyCharacters = watch(name)?.length;

  const allProps = {
    className: clsx(
      'border dark:border-black-dark w-full rounded bg-transparent text-sm placeholder:text-gray-400 text-gray-900 dark:text-white p-3',
      className,
      { 'border-red-500': hasError, 'border-gray-200': !hasError },
    ),
    type: 'text',
    placeholder: placeholder,
    ...register(name, {
      ...formatValidations,
      maxLength: {
        value: maxLength,
        message: `The entered text exceeds the ${maxLength} characters limit by ${
          qtyCharacters - maxLength
        } characters`,
      },
    }),
    ...inputProps,
  };

  return (
    <div className="flex flex-col">
      {showLabel && (
        <Label className={labelClassName} name={name}>
          <div className="flex flex-col">
            <div className="first-letter:capitalize">{label}</div>{' '}
            {description && (
              <div className="mt-1 text-sm text-gray-500 mb-2 font-normal">{description}</div>
            )}
          </div>
          {info && (
            <div className="relative group">
              <InfoIcon className="cursor-pointer keep-color" />
              <div className="absolute opacity-0 invisible transition-all group-hover:opacity-100 group-hover:visible lg:group-hover:block min-w-[369px] py-2 px-3 text-white text-left !leading-tight-[150%] bg-gray-700 rounded-lg text-sm font-normal z-10 -left-[30px] lg:-left-[177px] before -top-[150px]">
                <div className="w-[18px] overflow-hidden inline-block absolute -bottom-[12px] left-1/2 -translate-x-1/2 rotate-180">
                  <div className="h-[13px] w-[13px] bg-gray-700 rotate-45 transform origin-bottom-left" />
                </div>
                {info}
              </div>
            </div>
          )}
        </Label>
      )}
      <div className="relative">
        <div
          className={clsx('absolute -translate-y-1/2 top-1/2 ml-[17px]', {
            'text-error-500': hasError,
            'text-primary': !hasError,
          })}
        >
          {icon}
        </div>
        <textarea {...allProps} />
        {maxLength && textArea && (
          <div
            className={clsx('absolute bottom-2 right-2 text-xs  font-medium', {
              'text-red-500': maxLength - (qtyCharacters || 0) < 0,
              'text-gray-400': maxLength - (qtyCharacters || 0) >= 0,
            })}
          >
            {maxLength - qtyCharacters || 0}
          </div>
        )}
      </div>
      {errorInvalid ? (
        <ErrorMessage error={errors[name]?.message as string} className="text-xs" />
      ) : (
        errorFieldAlreadyExists && (
          <ErrorMessage error={`This ${name} is already taken. Please try again.`} />
        )
      )}
      <style jsx global>{`
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          display: none;
        }

        input[type='number'] {
          -moz-appearance: textfield;
        }
      `}</style>
    </div>
  );
};
