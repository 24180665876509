import { validEmail } from '@utils';
import EmailIcon from '@icons/email.svg';
import { CustomInput, Input } from '../input';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

export const Email = (props: CustomInput) => {
  const { watch, formState } = useFormContext();
  const inputValue = watch(props.name || 'email');
  const errors = Boolean(formState.errors?.[props.name || 'email']);
  return (
    <Input
      name={props.name || 'email'}
      icon={
        <EmailIcon
          placeholder="your.email@example.com"
          width={24}
          className={clsx('keep-color', {
            'stroke-primary': inputValue?.length > 0,
            'stroke-gray-500': inputValue?.length === 0,
            'stroke-red': errors,
          })}
        />
      }
      validations={{ required: true, pattern: validEmail }}
      {...props}
      className="text-sm"
    />
  );
};
