import { useAuthUser } from '@hooks';
import { ReactNode, useState } from 'react';
import { ProfileLayout } from '..';
import clsx from 'clsx';

import { Loading } from '@components/loading';
import { BuyerLayoutMenu } from './buyer-layout-menu';
import { BuyerLayoutProfileMenu } from './buyer-layout-profile-menu';
import { commonRoutes } from '@utils';
import { useMediaQuery } from 'react-responsive';
import RewardsPopUpWrapper from '@components/rewards/rewards-pop-up-wrapper';

export const BuyerLayout = ({
  children,
  headerContainerClassName = 'hidden lg:flex',
  mainClassName,
  showNeedHelp,
  headerClassName,
  logoLink = commonRoutes.buyer.auth.summary,
  hideProfile,
  alwaysShowLogo,
  customHeader,
}: {
  children: ReactNode;
  headerContainerClassName?: string;
  mainClassName?: string;
  showNeedHelp?: boolean;
  logoLink?: string;
  headerClassName?: string;
  hideProfile?: boolean;
  alwaysShowLogo?: boolean;
  customHeader?: ReactNode;
}) => {
  const { firstName, isLoadingUserInfo, profile, logout } = useAuthUser(true);

  const [showMenu, setShowMenu] = useState(false);

  const isDesktop = useMediaQuery({ minWidth: 1024 });

  return (
    <>
      <div
        className={clsx(
          'min-h-full bg-white',
          true ? 'bg-gray-100' : 'bg-cover  bg-center bg-sign-up-bg py-5',
        )}
      >
        <RewardsPopUpWrapper>
          <ProfileLayout
            logoLink={logoLink}
            mainClassName={mainClassName}
            headerClassName={headerClassName}
            alwaysShowLogo={alwaysShowLogo}
            headerContainerClassName={headerContainerClassName}
            leftMenu={
              <BuyerLayoutMenu
                hide={!isDesktop}
                welcomTitle={firstName}
                logout={logout}
                toggleMenu={() => setShowMenu((prevMenu) => !prevMenu)}
              />
            }
            rightMenu={!hideProfile && <BuyerLayoutProfileMenu />}
            customHeader={customHeader}
            toggleMenu={() => setShowMenu((prevMenu) => !prevMenu)}
            toggleHeaderLogo={() => setShowMenu(showMenu === false ? true : null)}
          >
            <Loading isLoading={isLoadingUserInfo} />

            {profile?.isInitialDataFetched && (
              <BuyerLayoutMenu
                hide={isDesktop || !showMenu}
                welcomTitle={firstName}
                logout={logout}
                toggleMenu={() => setShowMenu((prevMenu) => !prevMenu)}
              />
            )}

            <div
              className={clsx({
                hidden: !isDesktop && showMenu,
              })}
            >
              {children}
            </div>
          </ProfileLayout>
        </RewardsPopUpWrapper>
      </div>
    </>
  );
};
