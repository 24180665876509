import { apiRequestClient } from '../client';
import { PlaidItem } from './buyer-plaid-items';

/**
 * Create a link token for the Plaid Link frontend component, which allows the user to connect a bank account, for transactions.
 */
export const createBuyerIncomesLinkToken = () => {
  return apiRequestClient().method('post').append('/buyer/plaid/incomes/create-link-token').build<{
    link_token: string;
    expiration: string;
  }>();
};

/**
 * Exchange a public token for an access token
 */
export const exchangeBuyerIncomesPublicToken = (body: {
  publicToken: string;
  institutionId?: string;
}) => {
  return apiRequestClient()
    .method('post')
    .append('/buyer/plaid/incomes/exchange-public-token')
    .setData(body)
    .build<any>();
};

/**
 * List all income items connected to the buyer
 */
export const getBuyerIncomeItems = () => {
  return apiRequestClient().method('get').append('/buyer/plaid/incomes').build<PlaidItem[]>();
};
