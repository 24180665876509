import clsx from 'clsx';
import { ReactNode } from 'react';

const styles = {
  dropdown:
    'absolute shadow-[0px_20px_25px_-5px_rgba(0,_0,_0,_0.1)] rounded-lg filter-[drop-shadow(0px_10px_10px_rgba(0,_0,_0,_0.04))] flex flex-col min-w-[160px] right-0 top-8 bg-[#fff] transition z-10',
};

export const Dropdown = ({
  isOpen,
  children,
  className,
}: {
  isOpen: boolean;
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={clsx(
        styles.dropdown,
        { 'visible opacity-100': isOpen },
        { 'invisible opacity-0': !isOpen },
        className,
      )}
    >
      {children}
    </div>
  );
};
