import { useQuery } from 'react-query';
import { ApiService } from '@services/Api';

export const useIncomeQuery = (fetchOnMount = true) => {
  const incomesQuery = useQuery(
    ['buyer', 'incomes'],
    async () => {
      const resp = await ApiService.listBuyerIncomes();
      return resp.data.items;
    },
    {
      refetchOnWindowFocus: false,
      enabled: fetchOnMount,
    },
  );

  return incomesQuery;
};

export const useDebtsQuery = (fetchOnMount = true) => {
  const debtsQuery = useQuery(
    ['buyer', 'debts'],
    async () => {
      const resp = await ApiService.listBuyerDebts();
      return resp.data.items;
    },
    {
      refetchOnWindowFocus: false,
      enabled: fetchOnMount,
    },
  );
  return debtsQuery;
};

export const useSavingsQuery = (fetchOnMount = true) => {
  const savingsQuery = useQuery(
    ['buyer', 'savings'],
    async () => {
      const resp = await ApiService.listBuyerSavings();
      return resp.data.items;
    },
    {
      refetchOnWindowFocus: false,
      enabled: fetchOnMount,
    },
  );
  return savingsQuery;
};
