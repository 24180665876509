import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useAuthUser, useIsMounted } from '@hooks';
import { MobileIntroduction } from './mobile-introduction';
import { DesktopIntroduction } from './desktop-introduction';
import { Loading } from '@components/loading';

interface IntroductionState {
  step: number;
  completed: boolean;
  agreedToTerms: boolean;
}

interface IntroductionUpdaters {
  setIntroduction: (state: Partial<IntroductionState>) => void;
}

type IntroductionStore = IntroductionState & IntroductionUpdaters;

export const useIntroduction = create(
  persist<IntroductionStore>(
    (set) => ({
      step: 1,
      completed: false,
      agreedToTerms: false,
      setIntroduction: (state) => {
        set(state);
      },
    }),
    { name: 'use-introduction' },
  ),
);

export const introductionStepQuantity = 3;

export const Introduction = () => {
  const { isLoadingUserInfo } = useAuthUser();
  const isMounted = useIsMounted();
  return (
    isMounted && (
      <div className="h-full">
        <Loading isLoading={isLoadingUserInfo} />
        <div className="h-full ">
          <DesktopIntroduction />
        </div>
      </div>
    )
  );
};
