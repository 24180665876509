import { apiRequestClient } from '../client';

export type InviteCoBorrowerBody = {
  inviterId: string;
  email: string;
  firstName: string;
  lastName: string;
};

export interface IInviteData {
  id: string;
  coBorrowersEmail: string;
  coBorrowersFirstName: string;
  coBorrowersLastName: string;
  inviter: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

export type InviteCoBorrowerResponse = {
  valid: boolean;
  invite: IInviteData;
};

export type DisassociateCoBorrowerResponse = {
  status: 'dis-associated' | 'failure';
};

/**
 * invites co-borrower
 */
export const inviteCoBorrower = (body: InviteCoBorrowerBody) => {
  return apiRequestClient()
    .method('post')
    .append('co-borrower/invite')
    .setData(body)
    .build<InviteCoBorrowerResponse>();
};
/**
 * validates invite
 */
export const validateInvite = (id: any) => {
  return apiRequestClient()
    .method('get')
    .append(`co-borrower/validate/${id}`)
    .build<InviteCoBorrowerResponse>();
};

/**
 * accepts the invite
 */
export const acceptInvite = (body: { inviteId: string; decline?: boolean }) => {
  return apiRequestClient().method('post').append('co-borrower/accept').setData(body).build<{
    inviteId: string;
    status: 'linked' | 'redirect_to_sign_up' | 'declined';
  }>();
};

/**
 * disassociates the co-borrowers
 */
export const disassociateCoBorrower = () => {
  return apiRequestClient()
    .method('get')
    .append('co-borrower/dis-associate')
    .build<DisassociateCoBorrowerResponse>();
};
