import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type State = {
  mode: 'light' | 'dark';
};

type Methods = {
  toggle: () => void;
};

type Store = State & Methods;

const initialState: State = {
  mode: 'light',
};

export const useTheme = create(
  persist<Store>(
    (set) => ({
      ...initialState,
      toggle: () =>
        set((state) => {
          if (state.mode === 'light') {
            return { mode: 'dark' };
          }

          return { mode: 'light' };
        }),
    }),
    { name: 'dark-mode' },
  ),
);
