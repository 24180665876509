import { apiRequestClient } from '../client';
import { BuyerSaving } from '../types';

/**
 * List all buyer savings
 */
export const listBuyerSavings = () => {
  return apiRequestClient().method('get').append('/buyer/savings').build<{
    items: BuyerSaving[];
    total: number;
  }>();
};

export type CreateBuyerSavingBody = Pick<BuyerSaving, 'amount' | 'category' | 'date'>;
/**
 * Create a buyer savings
 */
export const createBuyerSaving = (body: CreateBuyerSavingBody) => {
  return apiRequestClient()
    .method('post')
    .append('/buyer/savings')
    .setData(body)
    .build<BuyerSaving>();
};

export type UpdateBuyerSavingBody = Partial<CreateBuyerSavingBody>;
/**
 * Create a buyer savings
 */
export const updateBuyerSaving = (id: string, body: UpdateBuyerSavingBody) => {
  return apiRequestClient()
    .method('patch')
    .append('/buyer/savings/' + id)
    .setData(body)
    .build<BuyerSaving>();
};

/**
 * Deletes a buyer saving
 */
export const deleteBuyerSaving = (id: string) => {
  return apiRequestClient()
    .method('delete')
    .append('/buyer/savings/' + id)
    .build<{ id: string }>();
};
