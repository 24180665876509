import { CompleteProfileFields } from '@hooks';
import { apiRequestClient } from '../client';

export const getBuyerCreditReportUserId = () => {
  return apiRequestClient().method('get').append('buyer/credit-report/user').build<{
    userId: string;
  }>();
};

export const getBuyerCreditReportUserToken = () => {
  return apiRequestClient().method('get').append('buyer/credit-report/user-token').build<{
    userId: string;
    userToken: string;
  }>();
};

export const createBuyerCreditReportUser = (body: Partial<CompleteProfileFields>) => {
  return apiRequestClient().method('post').append('buyer/credit-report/user').setData(body).build<{
    userId: string;
  }>();
};

export const updateBuyerCreditReportUserToken = (userToken: string) => {
  return apiRequestClient()
    .method('put')
    .setData({
      userToken,
    })
    .append('buyer/credit-report/update-user-token')
    .build<void>();
};

export const retrieveBuyerCreditReport = () => {
  return apiRequestClient()
    .method('put')
    .append('buyer/credit-report/retrieve-report')
    .build<{ score: number; userToken: string }>();
};

export const disconnectBuyerCreditReportUser = () => {
  return apiRequestClient()
    .method('delete')
    .append('buyer/credit-report/user')
    .build<void>();
};

