import { useFormContext } from 'react-hook-form';
import CloseIcon from '@icons/close.svg';

interface SkillTags {
  fieldName: string;
}

export const SkillTags = ({ fieldName = 'skills' }: SkillTags) => {
  const methods = useFormContext();
  const selectedSkills = methods.watch(fieldName);

  return (
    <>
      {selectedSkills?.length > 0 && (
        <div className="mt-8 flex gap-2 flex-wrap">
          {selectedSkills?.map((selectedSkill) => (
            <button
              onClick={() => {
                methods.setValue(
                  fieldName,
                  selectedSkills.filter((skill) => skill.value !== selectedSkill.value),
                  {
                    shouldDirty: true,
                    shouldValidate: true,
                  },
                );
              }}
              type="button"
              key={selectedSkill.value}
              className="px-4 py-2 border border-primary-500 rounded-full text-sm font-medium text-primary-500 flex items-center gap-2 hover:bg-primary-500 hover:text-white min-h-[40px]"
            >
              <div className="-mt-[1px] first-letter:capitalize text-left w-max">
                {selectedSkill.label}
              </div>
              <CloseIcon height={16} strokeWidth={2} />
            </button>
          ))}
        </div>
      )}
    </>
  );
};
