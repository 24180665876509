import { Toast } from '@utils';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Button } from '@components/button';
import { Loading } from '@components/loading';
import { Modal } from '@components/modal';
import { useAuthUser } from '@hooks';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ApiService } from '@services/Api';

export const DisAssociateCoBorrower = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { profile, refetchUserInfo } = useAuthUser();
  const qc = useQueryClient();

  const disassociationMutation = useMutation(
    async () => {
      const resp = await ApiService.disassociateCoBorrower();
      return resp.data;
    },
    {
      onSuccess: (data) => {
        if (data.status === 'dis-associated') {
          Toast({
            text: 'Successfully dis-associated',
          });
          qc.invalidateQueries(['user']);
          setIsOpen(false);
        }
      },
      onError: (error: AxiosRequestConfig) => {
        Toast({
          text: error?.data?.message || 'Something went wrong ',
          variant: 'error',
        });
        setIsOpen(false);
        refetchUserInfo();
      },
    },
  );

  return (
    <>
      <Modal
        open={isOpen && profile.coBorrower}
        onClose={() => {
          setIsOpen(false);
        }}
        panelClassName="w-full lg:max-w-[800px] rounded-lg py-10 px-8 text-left "
        title="Disassociate Co-Borrower / Co-Applicant"
      >
        Are you sure you want to disassociate from{' '}
        <b>
          {profile.coBorrowersFirstName} ({profile.coBorrowersEmail})
        </b>
        ?
        <Loading isLoading={disassociationMutation.isLoading} />
        <div className="flex flex-row gap-2 mt-4 border justify-between">
          <Button
            type="button"
            variant="red"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={() => {
              disassociationMutation.mutate();
            }}
          >
            Yea, sure.
          </Button>
        </div>
      </Modal>
      <Button
        type="button"
        variant="red"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Remove Co-Borrower / Co-Applicant
      </Button>
    </>
  );
};
