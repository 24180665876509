import clsx from 'clsx';
import { ErrorMessage } from '@components/error-message';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { EMilitaryStatus, EMonthlyRent, EReasonForPurchase } from '@services/Api';

interface IMultiSelectOptions {
  name: string;
  value: EMilitaryStatus | EMonthlyRent | EReasonForPurchase;
}
interface IMultiSelectProps {
  name: string;
  label: string;
  options: IMultiSelectOptions[];
  onChange?: (value: { name: string; value: any }) => void;
  defaultValue?: EMilitaryStatus | EMonthlyRent | EReasonForPurchase;
  required?: boolean;
  requiredMessage?: string;
}

const MultiSelectComponent: React.FC<IMultiSelectProps> = ({
  name,
  label,
  options,
  onChange,
  defaultValue,
  requiredMessage = '',
  required = true,
}) => {
  const methods = useFormContext();
  const errors = methods.formState.errors;

  const [selected, setSelected] = useState<EMilitaryStatus | EMonthlyRent | EReasonForPurchase>(
    defaultValue || methods.getValues(name) || null,
  );

  const onClick = useCallback(
    (selectedOption: { name: string; value: any }) => {
      setSelected(selectedOption.value);
      if (onChange) onChange(selectedOption);
      if (methods) methods.setValue(name, selectedOption.value);
    },
    [methods, name, onChange],
  );

  methods.register(name, {
    required:
      required &&
      (requiredMessage ||
        `${name.replace(/([a-z0-9])([A-Z])/g, '$1 $2').toLowerCase()} is required.`),
  });

  const fieldState = methods.getFieldState(name);

  const optionsRender = useMemo(
    () =>
      options.map((option) => (
        <MultiSelectOptions
          value={option}
          onClick={onClick}
          key={option.value}
          selected={option.value === selected}
        />
      )),
    [onClick, options, selected],
  );
  return (
    <div className=" pb-8 flex flex-col gap-6">
      <h1 className="text-xl font-bold text-[#635E7D] mr-3">{label}</h1>
      <div className="grid  row-auto gap-6 grid-cols-1 xs:grid-cols-2">{optionsRender}</div>
      {errors && errors?.[name]?.message && (
        <ErrorMessage error={errors?.[name]?.message as string} className="text-center" />
      )}
    </div>
  );
};

const MultiSelectOptions: React.FC<{
  value: IMultiSelectOptions;
  selected: boolean;
  onClick: (value: { name: string; value: any }) => void;
}> = ({ value, onClick, selected }) => {
  return (
    <div
      onClick={() => onClick(value)}
      className={clsx('border-2 border-gray rounded-md p-4 text-center  cursor-pointer', {
        'bg-[#ffbb5566] ': selected,
      })}
    >
      <span className="select-none text-[#1C1A27]">{value.name}</span>
    </div>
  );
};

export const MultiSelect = memo(MultiSelectComponent);
