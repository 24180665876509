import { useCalculateScore } from '@hooks/use-calculate-score';

export const useIsDataReady = () => {
  const query = useCalculateScore();

  return {
    isDataReady: Boolean(query.data?.grades?.totalScore?.value),
    isDataLoading: query.isLoading,
    isDataError: query.isError,
  };
};
