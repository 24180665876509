import { useAuthUser } from '@hooks';
import { Toast, commonRoutes } from '@utils';
import { Button } from '@components/button';
import { CustomInput } from '@components/custom-input';
import { InviteCoBorrower } from '@components/custom-input/co-borrower';
import { Form } from '@components/form';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import CopyTextIcon from '@icons/copy-text.svg';
import CoBorrowerDetails from './co-borrower-details';

interface IInviteCoBorrowerFields {
  coBorrowersFirstName: string;
  coBorrowersLastName: string;
  coBorrowersEmail: string;
  inviteeUserId: string;
}

const CoBorrowerInviteDisplay = () => {
  const { profile } = useAuthUser(true);

  const canResend = useMemo(
    () => dayjs().diff(profile.coBorrowerInvite?.createdAt, 'hours') >= 6,
    [profile?.coBorrowerInvite],
  );

  const link = useMemo(
    () =>
      `${window.location.origin}${commonRoutes.coBorrower.invite}/${profile.coBorrowerInvite.id}`,
    [profile?.coBorrowerInvite?.id],
  );

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(link).then(() => {
      Toast({ text: 'Referral link copied to clipboard.' });
    });
  }, [link]);

  if (!profile.coBorrowerInvited) return null;

  return (
    <>
      <CoBorrowerDetails />

      <Button
        variant="white"
        onClick={() => {
          handleCopyLink();
        }}
        type="button"
      >
        <div className="flex items-center gap-3">
          <CopyTextIcon className="keep-color" width={20} height={20} />
          <div className="mx-auto pr-5">Copy referral link</div>
        </div>
      </Button>
      <p>
        Last invite sent at: {dayjs(profile?.coBorrowerInvite?.createdAt).format('MM/DD/YYYY H:mm')}
      </p>
      <InviteCoBorrower label="Resend Code" disabled={!canResend} />
      <p className="text-sm">You can resend links 6 hours from last invite sent.</p>
    </>
  );
};

export default CoBorrowerInviteDisplay;
