import { Button, CodeInputModal, Loading, SendCodeModal } from '@components';
import { useAuthUser, useIsMounted } from '@hooks';
import ChevronDownIcon from '@icons/chevron-down.svg';
import MenuIcon from '@icons/hamburger-menu.svg';
import { client } from '@services';
import { commonRoutes } from '@utils';
import { AxiosRequestConfig } from 'axios';
import clsx from 'clsx';
import { E164Number } from 'libphonenumber-js';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MouseEventHandler, ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import { UserLogo } from './user-logo';
import { UserMenu } from './user-menu';

export const styles = {
  base: 'w-full pt-10 pb-11 lg:pt-5 lg:pb-5 border-0 lg:border-b dark:border-b-black-dark lg:border-gray',
  newbase: 'w-full pt-10 pb-11 lg:pt-7 lg:pb-20 border-0',
};

export interface HeaderProps {
  className?: string;
  containerClassName?: string;
  logo?: ReactNode;
  loginButton?: ReactNode;
  logoLink?: string;
  rightMenu?: ReactNode;
  alwaysShowLogo?: boolean;
  toggleMenu: MouseEventHandler;
  toggleHeaderLogo: MouseEventHandler;
}

export const Header = ({
  className,
  logo = (
    <div>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <Image
        src="https://mhp-icons.s3.amazonaws.com/white-lockup.png"
        alt="Logo"
        width={180}
        height={90}
        objectFit="contain"
      />
    </div>
  ),
  loginButton,
  containerClassName,
  logoLink = commonRoutes.getStarted,
  rightMenu: rightMenu,
  alwaysShowLogo,
  toggleMenu,
  toggleHeaderLogo,
}: HeaderProps) => {
  const intl = useIntl();
  const router = useRouter();
  const {
    phoneNumber,
    updateUserInfo,
    authenticationId,
    signedIn,
    firstName,
    lastName,
    userName,
    image,
    email,
    selectedAuth,
    profile,
  } = useAuthUser();

  const [openSendModal, setOpenSendModal] = useState<boolean>(false);
  const [openCodeInput, setOpenCodeInput] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const isDesktop = useMediaQuery({ minWidth: 1024 });

  const { mutate: mutateSendCode, error: errorCode } = useMutation<
    { authenticationId: string; type: string },
    AxiosRequestConfig,
    { phone?: string; email?: string }
  >(async (body) => {
    const { data } = await client.post<{
      authenticationId: string;
      type: string;
    }>(`${process.env.NEXT_PUBLIC_API_URL}/auth/${selectedAuth}`, body);
    return data;
  });

  const {
    mutate: mutateResendCode,
    error: errorResendCode,
    reset: resetResendCode,
  } = useMutation<unknown, AxiosRequestConfig, { id: string; type: string }>(
    (body: { id: string; type: string }) =>
      client.post(`${process.env.NEXT_PUBLIC_API_URL}/auth/resend-code`, body),
  );

  const {
    mutate: mutateVerifyCode,
    error: errorVerifyCode,
    reset: resetVerifyCode,
  } = useMutation<unknown, AxiosRequestConfig, { authenticationId: string; code: string }>(
    async (body: { authenticationId: string; code: string }) => {
      const { data } = await client.post(
        `${process.env.NEXT_PUBLIC_API_URL}/auth/sign-up/verify`,
        body,
      );

      return data;
    },
  );

  const { mutate: mutateVerifyCodeSignIn, error: errorSignin } = useMutation<
    { accessToken: string },
    AxiosRequestConfig,
    { authenticationId: string; code: string }
  >(async (body) => {
    const { data } = await client.post<{ accessToken: string }>(
      `${process.env.NEXT_PUBLIC_API_URL}/auth/sign-in`,
      body,
    );
    return data;
  });

  const [type, setType] = useState(null);

  const [isFakeLoading, setIsFakeLoading] = useState(false);

  const verifyCode = (code: string) => {
    setIsFakeLoading(true);
    if (type === 'signin') {
      mutateVerifyCodeSignIn(
        { authenticationId, code },
        {
          onSuccess: (data) => {
            setTimeout(() => {
              setIsFakeLoading(false);
              updateUserInfo({
                accessToken: data.accessToken,
                signedIn: true,
              });
              setOpenCodeInput(false);
            }, 2500);
          },
          onError: () => {
            setIsFakeLoading(false);
          },
        },
      );
    } else {
      mutateVerifyCode(
        {
          authenticationId,
          code,
        },
        {
          onSuccess: () => {
            setTimeout(() => {
              setIsFakeLoading(false);
              setOpenCodeInput(false);
              router.push('/account');
            }, 2500);
          },
          onError: () => {
            setIsFakeLoading(false);
          },
        },
      );
    }
  };

  const onPhoneSubmit = (phone: E164Number | undefined | string) => {
    if (phone) {
      mutateSendCode(
        { phone: phone as string },
        {
          onSuccess: (data) => {
            if (data.authenticationId) {
              updateUserInfo({
                authenticationId: data.authenticationId,
                phoneNumber: phone,
              });
              setType(data.type);
              setOpenSendModal(false);
              setOpenCodeInput(true);
            }
          },
        },
      );
    }
  };

  const onEmailSubmit = (email: string) => {
    if (email) {
      mutateSendCode(
        { email },
        {
          onSuccess: (data) => {
            if (data.authenticationId) {
              updateUserInfo({
                authenticationId: data.authenticationId,
                email,
              });
              setType(data.type);
              setOpenSendModal(false);
              setOpenCodeInput(true);
            }
          },
        },
      );
    }
  };

  const isMounted = useIsMounted();

  return (
    <header
      className={clsx(
        // styles.base
        styles.newbase,
        containerClassName,
        'relative  flex flex-col',
      )}
    >
      {/* <div className="absolute top-0 left-0 w-full h-full">
        <Image
          className="object-cover w-full h-full lg:rounded-3xl"
          src={'/images/headerImage.png'}
          // NOTE: these width & height props don't really control the
          // dimensions of the picture, they're just required by next Image
          // and maybe used for something else internally in the Image component
          // main thing controlling dimensions is the className
          width={500}
          height={500}
          layout="fill"
          alt="House"
        />
      </div> */}

      <Loading isLoading={isFakeLoading} />

      <div className="flex items-center justify-between w-full">
        <div className="z-10 flex flex-col gap-6 w-full  pl-[15px] md:px-[25px]  ">
          {!isDesktop && (
            <button type="button" className="mr-2.5 hover:text-[#D0CAE9]" onClick={toggleMenu}>
              <MenuIcon />
            </button>
          )}

          <p className="antialiased font-bold xs:text-s sm:text-xl md:text-xl lg:text-[20px] text-l font-plusJakarta leading-6">
            ACCOUNT SUMMARY
          </p>
          <p className="text-[14px] font-normal leading-4 font-plusJakarta">
            <span className="text-[14px] font-semibold leading-4 font-plusJakarta"> Welcome </span>{' '}
            {firstName} {lastName}
          </p>
        </div>
        <section className="flex justify-between w-full ">
          <div
            className={clsx(
              'w-full flex items-center  flex-row ml-[15px] md:mx-[30px]',
              {
                'justify-end': alwaysShowLogo,
              },
              className,
              'w-full',
            )}
          >
            {/* <div className="w-full md:flex h-fit">
            <Link prefetch={false} href={logoLink} className="">
              <a className="flex items-center " onClick={toggleHeaderLogo}>
                <Image
                  src="https://mhp-icons.s3.amazonaws.com/white-lockup.png"
                  width={130}
                  height={50}
                  className="absolute z-10 text-white xs:top-12 top-6 left-7 xs:scale-9"
                  alt="mhp-logo"
                />
              </a>
            </Link>
          </div> */}
            {isMounted && (
              <>
                {loginButton ? (
                  <>{loginButton}</>
                ) : (
                  <>
                    {!signedIn && router.pathname !== '/account' && (
                      <Button
                        className="py-3 !h-auto text-xs lg:text-[13px]"
                        onClick={() => setOpenSendModal(true)}
                      >
                        {intl.formatMessage({ id: 'layout.header.login' })}
                      </Button>
                    )}
                  </>
                )}

                {rightMenu
                  ? rightMenu
                  : profile?.isOnboarded && (
                      <div className="relative border border-red ">
                        <div className="flex items-center cursor-pointer">
                          <button
                            className="flex items-center p-2 bg-white rounded-full"
                            type="button"
                            onClick={() => setOpenMenu((old) => !old)}
                          >
                            <UserLogo
                              firstName={firstName}
                              lastName={lastName}
                              className="hidden mr-3 lg:flex"
                            />
                            <div className="items-center hidden lg:flex">
                              <p className="mr-3 font-medium tracking-[0.2px] text-sm">
                                {firstName}
                              </p>
                              <ChevronDownIcon className="keep-color" />
                            </div>
                          </button>
                        </div>
                        <UserMenu
                          open={openMenu}
                          firstName={firstName}
                          lastName={lastName}
                          userName={userName}
                          image={image}
                          setOpenMenu={setOpenMenu}
                          className="absolute hidden top-12 right-px lg:block"
                        />
                      </div>
                    )}
              </>
            )}

            <SendCodeModal
              open={openSendModal}
              onClose={() => setOpenSendModal(false)}
              onSubmit={(value) => {
                if (selectedAuth === 'phone') {
                  onPhoneSubmit(value);
                } else {
                  onEmailSubmit(value);
                }
              }}
              error={errorCode}
            />
            <CodeInputModal
              error={errorCode || errorVerifyCode || errorSignin || errorResendCode}
              open={openCodeInput}
              onClose={() => {
                setOpenCodeInput(false);
                resetVerifyCode();
                resetResendCode();
              }}
              phoneNumber={selectedAuth === 'phone' ? phoneNumber : undefined}
              onCodeResent={() => {
                mutateResendCode({
                  id: authenticationId,
                  type: 'authentication',
                });
              }}
              verifyCode={verifyCode}
              email={selectedAuth === 'email' ? email : undefined}
            />
          </div>
        </section>
      </div>
    </header>
  );
};
