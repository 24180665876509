import * as CryptoJS from 'crypto-js';
export const encryptText = (plainText?: string, encKey?: string) => {
  const cipherText = CryptoJS.AES.encrypt(plainText, encKey).toString();
  return cipherText;
};

export const decryptText = (encText?: string, encKey?: string) => {
  const deCipherText = CryptoJS.AES.decrypt(encText, encKey);
  const originalText = deCipherText.toString(CryptoJS.enc.Utf8);
  return originalText;
};
