import { ReactNode, useRef, useState } from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { CustomCodeInput } from './custom-code-input';
import { BackButton, Button, ButtonContainer, ContinueButton } from '@components/button';

const Title = ({ children, className }: { children: ReactNode; className?: string }) => (
  <h1 className={clsx('text-2xl font-medium text-gray-900 dark:text-white font-slab', className)}>
    {children}
  </h1>
);

const Paragraph = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex items-center gap-1 mt-2 mb-8">
      <p className="text-sm text-gray-500 dark:text-gray-400">{children}</p>
    </div>
  );
};

const CustomLink = ({ children, href }: { children: ReactNode; href: string }) => (
  <Link href={href}>
    <a className="text-sm font-bold text-primary hover:underline">{children}</a>
  </Link>
);

const ResendCode = ({ onCodeResent }: { onCodeResent: () => void }) => {
  const [duration, setDuration] = useState(0);
  const intervalId = useRef<NodeJS.Timer>(null);

  return (
    <div className="flex mt-2.5 space-x-2 w-fit mx-auto">
      {duration > 0 ? (
        <div className="flex items-center">
          <span className="text-sm">
            <span className="font-medium">Code resent.</span>{' '}
            <span>Wait {duration} seconds to try again.</span>
          </span>
        </div>
      ) : (
        <>
          <p
            style={{ fontFamily: 'PlusJakartaSans' }}
            className="text-sm text-gray-500 dark:text-gray-400 xs:text-xs"
          >
            Didn&apos;t receive the code?
          </p>
          <button
            style={{ fontFamily: 'PlusJakartaSans' }}
            type="button"
            className="xs:text-xs text-sm font-semibold text-primary hover:underline"
            onClick={() => {
              onCodeResent();
              setDuration(20);

              clearInterval(intervalId.current);
              intervalId.current = setInterval(() => {
                setDuration((d) => d - 1);
              }, 1000);
            }}
          >
            Click to resend
          </button>
        </>
      )}
    </div>
  );
};

export const Steps = {
  Title,
  Link: CustomLink,
  Button,
  ResendCodeButton: ResendCode,
  CodeInput: CustomCodeInput,
  ButtonContainer,
  BackButton,
  ContinueButton,
  Paragraph,
};
