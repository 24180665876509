import { useAuthUser } from '@hooks';
import { GenericModal } from '@components/modal/generic-modal';
import { useAuthRouteProtector } from './use-auth-route-protector';
import { useHubspotTracking } from './use-hubspot-tracking';
import { useIdleLogout } from './use-idle-logout';
import { useKeepMixpanelSessionAlive } from './use-keep-mixpanel-session-alive';
import { useSyncOnLogin } from './use-sync-on-login';

export const AppWrapper = ({ children }) => {
  const user = useAuthUser();

  useSyncOnLogin(user);

  const { showIdle, remaining, resetIdleTimer } = useIdleLogout(user);

  const { routeAccessDenied } = useAuthRouteProtector(user);

  useHubspotTracking();

  useKeepMixpanelSessionAlive(user);

  return (
    <>
      {!routeAccessDenied ? <>{children}</> : <div />}
      {showIdle && (
        <GenericModal
          title="You are about to be logged out"
          content={<div>Logging out in {remaining} seconds</div>}
          actionTitle="I'm still here"
          onActionClick={resetIdleTimer}
        />
      )}
    </>
  );
};
