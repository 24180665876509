export const MessagesIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 8.5C2 6.29086 3.79086 4.5 6 4.5H18C20.2091 4.5 22 6.29086 22 8.5V16.5C22 18.7091 20.2091 20.5 18 20.5H6C3.79086 20.5 2 18.7091 2 16.5V8.5ZM6 6.5C4.89543 6.5 4 7.39543 4 8.5V16.5C4 17.6046 4.89543 18.5 6 18.5H18C19.1046 18.5 20 17.6046 20 16.5V8.5C20 7.39543 19.1046 6.5 18 6.5H6Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.21917 8.8753C6.56418 8.44404 7.19347 8.37412 7.62473 8.71913L12 12.2194L16.3753 8.71913C16.8066 8.37412 17.4359 8.44404 17.7809 8.8753C18.1259 9.30657 18.056 9.93586 17.6247 10.2809L12 14.7806L6.37534 10.2809C5.94408 9.93586 5.87416 9.30657 6.21917 8.8753Z"
      fill="black"
    />
  </svg>
);
