import { commonRoutes } from '@utils';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const useAuthRouteProtector = (user) => {
  const router = useRouter();

  const [routeAccessDenied, setRouteAccessDenied] = useState(true);

  useEffect(() => {
    const newRouteAccessDenied =
      (Object.values(commonRoutes.buyer.auth) as string[]).includes(router.pathname) &&
      !user?.signedIn;

    if (newRouteAccessDenied) {
      // sets a redirect query param to the end of the sign-in link.
      const redirectLink = new URL(router.asPath, window.location.href);
      const redirectURLParams = new URLSearchParams();
      redirectURLParams.set('redirect', redirectLink.toString());
      const url = new URL(commonRoutes.buyer.signin, window.location.href);
      url.search = redirectURLParams.toString();
      router.replace(url.toString());
    }

    setRouteAccessDenied(newRouteAccessDenied);
  }, [user?.signedIn, router]);

  return {
    routeAccessDenied,
  };
};
