import { commonRoutes } from '@utils';

import { Button, Steps } from '@components';
import { useBuyerSignupInfo, useIsMounted } from '@hooks';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { create } from 'zustand';

export const useOpenTermsModal = create<{
  openTermsModal: boolean;
  setOpenTermsModal: (openTermsModal: boolean) => void;
}>((set) => ({
  openTermsModal: false,
  setOpenTermsModal: (openTermsModal) => set({ openTermsModal }),
}));

export const Step3 = ({ onFinish }) => {
  const { setBuyerInfo, step, ...rest } = useBuyerSignupInfo();
  const [bottom, setBottom] = useState(true);

  const intl = useIntl();
  const router = useRouter();
  const query = router.isReady && router.query;
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isMounted = useIsMounted();

  useEffect(() => {
    const timer1 = setTimeout(() => setBottom(false), 3000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);
  return (
    isMounted && (
      <div className="flex flex-col flex-grow bg-primary mt-11">
        <div className="">
          <div className="lg:max-w-[624px] lg:justify-center mx-auto flex flex-col  p-5 lg:p-[50px] bg-white rounded">
            <div className="flex flex-col w-full lg:justify-center">
              <Steps.Title className="mb-2.5 text-center text-[#1C1A27]">
                Agree To Our Terms
              </Steps.Title>
              <p className="text-[15px] text-[#635E7D] text-center  dark:text-gray-400 mb-5 lg:mb-[1.875rem]">
                {intl.formatMessage({ id: 'terms.shortdesc' })}
              </p>
            </div>

            <div className='relative after:content-[""] border border-[#9F98BE] p-5 rounded'>
              <div className="text-xs text-[#9F98BE] flex flex-col gap-5 overflow-auto max-h-[285px] pb-6">
                <p>{intl.formatMessage({ id: 'terms.last-updated' })}</p>
                <p>{intl.formatMessage({ id: 'terms.pre-intro' })}</p>
                <p>{intl.formatMessage({ id: 'terms.intro-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.intro-two' })}</p>
                <p>{intl.formatMessage({ id: 'terms.statement-of-courage-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.statement-of-courage' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Information-Collection-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.general-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.general' })}</p>
                <p>{intl.formatMessage({ id: 'terms.general-two' })}</p>
                <p>{intl.formatMessage({ id: 'terms.registeration-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.registeration' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Account-Deletion-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Account-Deletion' })}</p>
                <p>{intl.formatMessage({ id: 'terms.updating-your-personal-info-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.updating-your-personal-info' })}</p>
                <p>{intl.formatMessage({ id: 'terms.transactions-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.transactions' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Site-Access-Activity-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Site-Access-Activity' })}</p>
                <p>{intl.formatMessage({ id: 'terms.public-content-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.public-content' })}</p>
                <p>{intl.formatMessage({ id: 'terms.location-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.location' })}</p>
                <p>{intl.formatMessage({ id: 'terms.messages-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.messages' })}</p>
                <p>{intl.formatMessage({ id: 'terms.mobile-application-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.mobile-application' })}</p>
                <p>{intl.formatMessage({ id: 'terms.other-sites-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.other-sites' })}</p>
                <p>
                  {intl.formatMessage({ id: 'terms.info-provided-by-third-parties-about-you-h' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.info-provided-by-third-parties-about-you' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.info-provided-to-third-parties-about-you-h' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.info-provided-to-third-parties-about-you' })}
                </p>
                <p>{intl.formatMessage({ id: 'terms.info-you provide-about-a-third-party-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.info-you provide-about-a-third-party' })}</p>
                <p>
                  {intl.formatMessage({ id: 'terms.info-you provide-about-a-third-party-two' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.info-you provide-about-a-third-party-three' })}
                </p>
                <p>{intl.formatMessage({ id: 'terms.customer-service-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.customer-service' })}</p>
                <p>{intl.formatMessage({ id: 'terms.children-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.children' })}</p>
                <p>{intl.formatMessage({ id: 'terms.data-protection-and-security-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.data-protection-and-security' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information' })}</p>
                <ul className="pl-4 list-disc">
                  <li>
                    {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-one' })}
                  </li>
                  <li>
                    {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-two' })}
                  </li>
                  <li>
                    {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-three' })}
                  </li>
                  <li>
                    {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-four' })}
                  </li>
                </ul>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-five' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-six' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-seven' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-eight' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-nine' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-ten' })}</p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-eleven' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-twelve' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-thirteen' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-fourteen' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-fifteen' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-sixteen' })}
                </p>
                <p>
                  {intl.formatMessage({
                    id: 'terms.Disclosure-sharing-of-information-seventeen',
                  })}
                </p>
                <p>{intl.formatMessage({ id: 'terms.Safeguarding-Communications-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Safeguarding-Communications' })}</p>
                <p>{intl.formatMessage({ id: 'terms.cookies-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.cookies' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-two' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-three' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-four' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-five' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-six' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-seven' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Data-Rentetion-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Data-Rentetion' })}</p>
                <p>{intl.formatMessage({ id: 'terms.changes-to-this-privacy-policy-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.changes-to-this-privacy-policy' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Questions-Suggestions-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Questions-Suggestions' })}</p>
                <ul>
                  <li>{intl.formatMessage({ id: 'terms.about-to' })}</li>
                  <li>{intl.formatMessage({ id: 'terms.about-to-one' })}</li>
                  <li>{intl.formatMessage({ id: 'terms.about-to-two' })}</li>
                </ul>
              </div>
            </div>
            <div className="flex flex-row justify-between gap-4 mt-[3.123rem]">
              <Button
                className="w-full"
                variant="white"
                onClick={() => {
                  setBuyerInfo({
                    authenticationId: null,
                    email: '',
                    isEmailVerified: false,
                    password: '',
                    step: 1,
                  });
                  router.push(commonRoutes.buyer.getStarted);
                }}
              >
                {intl.formatMessage({ id: 'getstarted.back' })}
              </Button>
              <Button
                className="w-full"
                variant={'primary'}
                onClick={async () => {
                  setBuyerInfo({ agreedToTerms: true, step: step });
                  onFinish({ ...rest });
                }}
                disabled={bottom}
              >
                {intl.formatMessage({ id: 'getstarted.agreeTerms' })}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
