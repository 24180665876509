import states from '@data/states.json';
import { Select } from '../select';

export const State = () => {
  return (
    <Select
      name="state"
      label="State"
      options={Object.entries(states).map(([value, state]) => ({
        value,
        label: state,
      }))}
    />
  );
};
/*
import states from '@data/states.json';
import React, { useState } from 'react'
import Select, {components} from 'react-select';

export const State = () => {
  const setIsClearable= useState(false);
  const isSearchable = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  onchange
  const customStyles = {
    control: base => ({
      ...base,
      height: 45,
      minHeight: 45
    })
  };

  (inputProps: any) => <components.Input {...inputProps} autoComplete="yes">  </components.Input>
  return (
<div className = "row">
    <div className='col-md-15'>
      <span>State</span>
    <Select
      name="state"
      styles={customStyles}
      
      options={Object.entries(states).map(([value, state]) => ({
        value,
        label: state
        ,onchange={(value) => {
          if (shouldRegister) {
            (inputRegister as UseFormRegisterReturn<string>).onChange({
              target: { value, name },
            });
          }
          if (onChange) {
            onChange({ value, name });
          }
        }}
      }))}
    />
    </div>
    </div>

  );
};*/
