import CloseIcon from '@icons/close.svg';
import { useCallback, useEffect, useMemo, useState } from 'react';
import usePlaceAutoComplete from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useFormContext } from 'react-hook-form';
import { Input } from '../input';
import { ErrorMessage } from '@components';
interface AddressComponent {
  long_name: string;
  types: string[];
}
const LocationTags = ({
  locations,
  onDelete,
}: {
  locations: Partial<Record<'city' | 'state' | 'country' | 'placeId', string>>[];
  onDelete: (placeId: string) => void;
}) => {
  const locationsRender = useMemo(
    () =>
      locations.map((location) => {
        const data = { ...location };
        const placeId = data.placeId;
        delete data.placeId;
        return (
          <button
            onClick={() => onDelete(placeId)}
            type="button"
            key={placeId}
            className="px-4 py-2 border border-primary-500 rounded-full text-sm font-medium text-primary-500 flex items-center gap-2 hover:bg-primary-500 hover:text-white min-h-[40px]"
          >
            <div className="-mt-[1px] capitalize text-left w-max">
              {Object.values(data).join(', ')}
            </div>
            <CloseIcon height={16} strokeWidth={2} />
          </button>
        );
      }),
    [locations, onDelete],
  );
  return (
    <>
      {locations?.length > 0 && <div className="mt-6 flex gap-2 flex-wrap">{locationsRender}</div>}
    </>
  );
};

interface SearchLocationProps {
  fieldName?: string;
  required?: boolean;
}

export const WishedLocation = ({
  fieldName = 'wishedLocations',
  required = true,
}: SearchLocationProps) => {
  const [showPredictions, setShowPredictions] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { placePredictions, getPlacePredictions, placesService } = usePlaceAutoComplete({
    debounce: 200,
    language: 'en',
    apiKey: process.env.NEXT_PUBLIC_MAPS_API,

    options: {
      componentRestrictions: { country: 'us' },
      types: ['(cities)'],
    },
  });
  const methods = useFormContext();
  const [locations, setLocations] = useState<
    Partial<Record<'placeId' | 'state' | 'city' | 'country', string>>[]
  >(methods.getValues(fieldName) || []);

  const errorInvalid =
    methods.formState.errors &&
    methods.formState.errors[fieldName]?.message &&
    methods.formState.isSubmitted;

  useEffect(() => {
    methods.register(fieldName, {
      validate: (locations) => {
        if (!required) return true;
        if (!locations.length) {
          return 'Add at least one state or city.';
        }
        if (locations.length > 5) {
          return 'Choose no more than 5 states or cities.';
        }
      },
    });
    () => methods.unregister(fieldName);
  }, [fieldName, methods, required]);

  const onQueryChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value.length < 3) return;
    getPlacePredictions({
      input: event.target.value,
    });
    setShowPredictions(true);
  };

  // gets diff address components
  const getAddressComponentValue = (addressComponents: AddressComponent[], type: string) => {
    const component = addressComponents.find((component) => component.types.includes(type));
    return component ? component.long_name : '';
  };

  const handlePredictionsClick = useCallback(
    (placeId) => {
      placesService.getDetails(
        {
          placeId,
        },
        (place) => {
          const addressComponents = place?.address_components ?? [];
          const city = getAddressComponentValue(addressComponents, 'locality');
          const state = getAddressComponentValue(addressComponents, 'administrative_area_level_1');

          setLocations((locations) => {
            const data = [...locations, { placeId, city, state, country: 'USA' }];
            methods.setValue(fieldName, data, {
              shouldValidate: true,
              shouldDirty: true,
            });
            if (errorInvalid)
              methods.setError(fieldName, {
                message: '',
              });
            return data;
          });
          setShowPredictions(false);
          setSearchTerm('');
        },
      );
    },
    [fieldName, methods, placesService, errorInvalid],
  );

  const predictions = useMemo(
    () =>
      showPredictions && (
        <div className="absolute z-10 w-full mt-1 bg-white-100 border border-gray-300 rounded-md shadow-lg overflow-y-auto max-h-[250px]">
          {placePredictions.length ? (
            placePredictions.map((prediction) => (
              <div
                key={prediction.place_id}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  handlePredictionsClick(prediction.place_id);
                }}
              >
                {prediction.description}
              </div>
            ))
          ) : (
            <div className="px-4 py-2 hover:bg-gray-100 cursor-pointer">No cities found.</div>
          )}
        </div>
      ),
    [handlePredictionsClick, placePredictions, showPredictions],
  );

  const onDelete = useCallback(
    (placeId: string) => {
      const filteredLocations = locations.filter((location) => location.placeId !== placeId);
      methods.setValue(fieldName, filteredLocations);
      setLocations(filteredLocations);
    },
    [fieldName, locations, methods],
  );

  return (
    <section>
      <Input
        label=" "
        placeholder="Search by state or city..."
        className="w-full"
        name="locationSearch"
        onChange={onQueryChange}
        value={searchTerm}
      />
      {errorInvalid && (
        <ErrorMessage
          className="mt-3"
          error={methods.formState.errors?.[fieldName].message as string}
        />
      )}
      {/* predictions list */}
      {predictions}
      {/* tags */}
      <LocationTags locations={locations} onDelete={onDelete} />
    </section>
  );
};
