import { useFormContext } from 'react-hook-form';
import CloseIcon from '@icons/close.svg';
import SearchIcon from '@icons/search-normal.svg';
import clsx from 'clsx';
import { SkillTags } from '@components';
import { Combobox } from '@headlessui/react';
import { ReactNode, useMemo, useState } from 'react';
import { ListItemRecord } from '@services/Api';

interface SkillsComboboxProps {
  skills: ListItemRecord[];
  fieldName?: string;
  label?: ReactNode;
  isLoading?: boolean;
}

export const SkillsCombobox = ({
  skills,
  fieldName = 'skills',
  label = 'Add a skill*',
  isLoading,
}: SkillsComboboxProps) => {
  const methods = useFormContext();

  const selectedSkills = useMemo(() => methods.watch(fieldName) || [], [fieldName, methods]);

  const search = methods.watch('skillsSearch') || '';

  const skillsWithoutSelected = useMemo(() => {
    return skills
      ? skills.filter(
          (skill) => !selectedSkills.some((selectedSkill) => selectedSkill.value === skill.value),
        )
      : [];
  }, [selectedSkills, skills]);

  const filteredSkills = useMemo(() => {
    return search === ''
      ? skillsWithoutSelected
      : skillsWithoutSelected?.filter((skill) => {
          return skill.label.toLowerCase().includes(search.toLowerCase());
        });
  }, [search, skillsWithoutSelected]);

  return (
    <div className="relative">
      <Combobox
        value={selectedSkills}
        onChange={(value) => {
          methods.setValue(fieldName, value, {
            shouldDirty: true,
            shouldValidate: true,
          });

          methods.setValue('skillsSearch', '');
        }}
        multiple
        name={fieldName}
        nullable={false}
      >
        <div className="text-sm font-medium text-gray-800 dark:text-gray-100 mb-2">{label}</div>
        <div className="relative">
          <Combobox.Input
            className="border-gray-200 w-full h-[48px] rounded text-sm placeholder:text-gray-400 dark:bg-transparent dark:border-black-dark"
            placeholder="Type to search"
            onKeyDown={(e) => {
              methods.setValue('skillsSearch', e.currentTarget.value);
            }}
            onChange={undefined}
          />
          {search.length === 0 ? (
            <div>
              <SearchIcon className="keep-color absolute top-1/2 -translate-y-1/2 right-4" />
            </div>
          ) : (
            <button
              className="absolute top-1/2 -translate-y-1/2 right-4"
              onClick={() => {
                methods.setValue('skillsSearch', '');
              }}
              type="button"
            >
              <CloseIcon height={16} strokeWidth={2} className="keep-color" />
            </button>
          )}
        </div>
        <Combobox.Options
          className={clsx(
            'mt-2 shadow-[0px_8px_18px_0px_#11192814] border border-gray-200 overflow-auto max-h-[244px] rounded absolute z-10 bg-white w-full dark:border-black-dark dark:bg-black-background',
            { 'max-h-[244px]': filteredSkills.length > 0 },
          )}
        >
          {isLoading ? (
            <div className="flex justify-center align-center">
              <p className="text-sm flex items-center justify-center h-full py-6">Loading...</p>
            </div>
          ) : filteredSkills.length > 0 ? (
            <>
              {filteredSkills.map((skill) => (
                <Combobox.Option
                  className="text-sm p-3 border-b border-gray-200 min-h-[48px] flex items-center hover:bg-primary-500 hover:text-white dark:border-black-dark cursor-pointer last:border-b-0 top-0 w-full"
                  key={skill.value}
                  value={skill}
                >
                  <span className="first-letter:uppercase">{skill.label}</span>
                </Combobox.Option>
              ))}
            </>
          ) : (
            <p className="text-sm flex items-center justify-center h-full py-6">Skill not found.</p>
          )}
        </Combobox.Options>
        <SkillTags fieldName={fieldName} />
      </Combobox>
    </div>
  );
};
