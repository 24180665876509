import { apiRequestClient } from '../client';
import { IRewards } from '../types';

export const getRewardStats = () => {
  return apiRequestClient()
    .method('get')
    .append('/rewards/stats')
    .build<{ totalRewardPoints: number; totalRewardsAwarded: number }>();
};
export const getNotPoppedRewards = () => {
  return apiRequestClient().method('get').append('/rewards/popped').build<{
    items: Array<{
      id: number;
      points: number;
      hasPoppedUp: boolean;
      reward: {
        id: string;
        name: string;
        description: string;
        imageUrl: string;
      };
    }>;
  }>();
};
export const markPoppedRewards = ({
  data,
}: {
  data: {
    rewardsIds: number[];
  };
}) => {
  return apiRequestClient().method('post').append('/rewards/popped').setData(data).build<{
    items: Array<{
      id: number;
      points: number;
      hasPoppedUp: boolean;
      reward: {
        id: string;
        name: string;
        description: string;
        imageUrl: string;
      };
    }>;
  }>();
};
export const getRewards = ({ skip, limit }: { skip?: number; limit?: number }) => {
  const urlParams = new URLSearchParams();
  urlParams.set('skip', `${skip}`);
  urlParams.set('limit', `${limit}`);
  return apiRequestClient()
    .method('get')
    .append(`/rewards/rewards?${urlParams.toString()}`)
    .build<{ count: number; hasNext: boolean; items: Array<IRewards> }>();
};
export const getGifts = () => {
  return apiRequestClient().method('get').append('/rewards/gifts').build<Array<IRewards>>();
};
