import { apiRequestClient } from '../client';
import { BuyerIncome } from '../types';

/**
 * List all buyer incomes
 */
export const listBuyerIncomes = () => {
  return apiRequestClient().method('get').append('/buyer/incomes').build<{
    items: BuyerIncome[];
    total: number;
  }>();
};

type CreateBuyerIncomeBody = Pick<BuyerIncome, 'grossPayAmount' | 'category' | 'frequency'> & {
  // This is a hack to make the API happy, but this will be converted to startDate & endDate
  date: string; // ISO 8601
};
/**
 * Create a buyer incomes
 */
export const createBuyerIncome = (body: CreateBuyerIncomeBody) => {
  return apiRequestClient()
    .method('post')
    .append('/buyer/incomes')
    .setData(body)
    .build<BuyerIncome>();
};

type UpdateBuyerIncomeBody = Partial<CreateBuyerIncomeBody>;
/**
 * Create a buyer incomes
 */
export const updateBuyerIncome = (id: string, body: UpdateBuyerIncomeBody) => {
  return apiRequestClient()
    .method('patch')
    .append('/buyer/incomes/' + id)
    .setData(body)
    .build<BuyerIncome>();
};

/**
 * Deletes a buyer income
 */
export const deleteBuyerIncome = (id: string) => {
  return apiRequestClient()
    .method('delete')
    .append('/buyer/incomes/' + id)
    .build<{ id: string }>();
};
