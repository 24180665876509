import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { ArrayCreditAlerts } from '@components/array-credit-alerts';

// notification-menu.tsx
const NotificationScreen = ({ onClose, notifications }) => {
  const router = useRouter();
  const [isMessagesOpen, setIsMessagesOpen] = useState(false);

  return (
    <div className="py-10 px-4">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold">Notifications</h2>

        <button onClick={onClose} className="text-right" style={{ color: '#635E7D' }}>
          Close
        </button>
      </div>
      <ArrayCreditAlerts closeArrayAlerts={() => setIsMessagesOpen(false)} />

      <div>
        {notifications.length > 0 ? (
          notifications.map((notification, index) => {
            const { title, message, link } = JSON.parse(notification.notification);
            const notificationType = notification.notificationType;
            if (notificationType === 'seen') {
              return null; // Do not render this notification
            }
            const handleClick = () => {
              // Handle redirection based on notification type
              if (notificationType === 'message') {
                // Redirect to message page for message notifications
                router.push(
                  `/user/messages?id=${notification.senderID}&name=${encodeURIComponent(
                    title || '',
                  )}`,
                );
              } else if (link) {
                router.push(link);
              }
              // Do nothing if the notification type doesn't need redirection
            };

            return (
              <div
                key={index}
                className="mb-2 p-2 border border-gray-300 rounded"
                onClick={handleClick} // Attach the click handler
                style={{ cursor: 'pointer' }} // Make it look clickable
              >
                <p className="font-semibold">{title}</p>
                <p dangerouslySetInnerHTML={{ __html: message }} />
                <p className="text-xs text-gray-500">
                  Received: {new Date(notification.createdAt).toLocaleString()}
                </p>
                <p className="text-xs text-gray-500">
                  {/* {notification.notificationType}
                  {notification.senderID} */}
                </p>
                <p className="font-semibold">{notification.title}</p>
              </div>
            );
          })
        ) : (
          <p>No notifications</p>
        )}
      </div>
    </div>
  );
};

export default NotificationScreen;
