import { validName } from '@utils';
import { CustomInput, Input } from '../input';

export const Name = (props: CustomInput) => {
  return (
    <Input
      wrapperClassName="w-full"
      name="firstName"
      minLength={2}
      validations={{
        required: true,
        pattern: validName,
      }}
      {...props}
    />
  );
};
