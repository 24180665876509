import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';

export const useHubspotTracking = () => {
  const router = useRouter();

  const { setPathPageView } = useTrackingCode();

  useEffect(() => {
    setPathPageView(router.asPath);
  }, [router.asPath]);
};
