import clsx from 'clsx';
import { useIntl } from 'react-intl';
import Link from 'next/link';
import { useAuthUser, useIsMounted, useTheme } from '@hooks';
import { useState } from 'react';
import { useRouter } from 'next/router';
import ChartLineIcon from '@icons/chart-line.svg';
import ChevronRightIcon from '@icons/chevron-right.svg';
import CloseIcon from '@icons/close.svg';
import BurgerIcon from '@icons/burger.svg';
import DocIcon from '@icons/doc.svg';
import HeadPhoneIcon from '@icons/headphone.svg';
import LogoutIcon from '@icons/logout.svg';
import MoonIcon from '@icons/moon.svg';
import PostCardIcon from '@icons/postcard.svg';
import SunIcon from '@icons/sun.svg';
import UserIcon from '@icons/user.svg';
import WorldIcon from '@icons/world.svg';
import CompassIcon from '@icons/compass.svg';
import { IconButton, Modal } from '@components';

export const styles = {
  base: 'py-4 lg:py-5 pb-7 lg:mb-1 lg:pb-4 w-full lg:border-t lg:border-gray lg:dark:border-black-dark',
  menu_item: 'mt-6 flex items-center justify-between ',
  menu_item_text: 'text-black text-sm dark:text-white',
};

const customNavs = [
  { label: 'Support', link: '/' },
  { label: 'Privacy', link: '/' },
  {
    label: 'Terms',
    link: '/',
  },
];

interface SmallFooterProps {
  className?: string;
}

const SmallFooter = ({ className = 'container-default' }: SmallFooterProps) => {
  const { mode, toggle } = useTheme();
  const intl = useIntl();
  return (
    <div className={clsx('flex justify-between items', className)}>
      <div className="flex flex-col items-start justify-center w-full gap-4 lg:justify-start lg:flex-row lg:items-center">
        <div className="flex lg:items-center justify-between w-full lg:w-[max-content] lg:mr-3">
          <div>
            <nav className="flex gap-[30px] w-full">
              {customNavs.map((nav) => (
                <Link href={nav.link!} target="_blank" rel="noreferrer" key={nav.label}>
                  <a className="text-xs font-bold text-default">{nav.label}</a>
                </Link>
              ))}
            </nav>
            <p className="mt-4 text-xs text-neutral-gray-web-500 lg:hidden">
              {intl.formatMessage({ id: 'layout.footer.all-rights' })}
            </p>
          </div>
          <div className="border rounded-full max-w-[40px] h-[40px] border-neutral-ghost-white lg:hidden">
            <IconButton
              className="min-w-[38px] h-[38px] flex justify-center items-center !p-0"
              color="transparent"
              onClick={toggle}
              icon={mode === 'light' ? <MoonIcon /> : <SunIcon className="!w-4 dark:text-white" />}
            />
          </div>
        </div>
        <p className="hidden text-xs text-neutral-gray-web-500 lg:block">
          {intl.formatMessage({ id: 'layout.footer.all-rights' })}
        </p>
      </div>
      <div className="hidden lg:flex">
        <button className="flex items-center mr-5" type="button">
          <span className="mr-2 text-xs font-bold">English</span> <WorldIcon width={16} />
        </button>
        <div className="hidden border rounded-full border-neutral-ghost-white lg:block max-w-[40px] h-[40px]">
          <IconButton
            className="min-w-[38px] h-[38px] flex justify-center items-center p-0"
            color="transparent"
            onClick={toggle}
            icon={mode === 'light' ? <MoonIcon /> : <SunIcon className="!w-4 dark:text-white" />}
          />
        </div>
      </div>
    </div>
  );
};

const MobileMenu = () => {
  const intl = useIntl();
  const router = useRouter();
  const { mode, toggle } = useTheme();

  const { logout } = useAuthUser();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const MENU_ITEMS = [
    {
      icon: <CompassIcon width={20} />,
      text: 'page.settings.menu.discover',
      slug: 'discover',
      link: '/user/get-started',
    },
    {
      icon: <PostCardIcon width={20} />,
      text: 'cmpt.user-menu.strategy',
      slug: 'strategy',
      link: '#',
    },
    {
      icon: <ChartLineIcon width={20} />,
      text: 'cmpt.user-menu.brokerage',
      slug: 'brokerage',
      link: '#',
    },
    {
      icon: <UserIcon width={20} />,
      text: 'page.settings.menu.settings',
      slug: 'user',
      link: '/settings/user',
    },
    {
      icon: <BurgerIcon width={20} />,
      text: 'cmpt.user-menu.more',
      slug: 'support',
      onClick: () => {
        setIsMenuOpen(true);
      },
    },
  ];

  return (
    <>
      <div className="fixed bottom-0 left-0 z-10 flex w-full py-4 bg-white border-t border-black-divider dark:border-black-dark justify-evenly lg:hidden dark:bg-black-background">
        {MENU_ITEMS.map((item) => {
          const isActive = item.link === router.asPath;
          return item.onClick ? (
            <button
              className={clsx('flex flex-col', {
                'text-blue-ribbon': isActive,
                'text-black dark:text-white': !isActive,
              })}
              onClick={item.onClick}
              key={item.text}
              type="button"
            >
              <div className="flex justify-center w-full mb-2">{item.icon}</div>
              <span className="text-xs font-medium text-center ">
                {intl.formatMessage({ id: item.text as any })}
              </span>
            </button>
          ) : (
            <Link key={item.text} href={item.link}>
              <a
                className={clsx('flex flex-col', {
                  'text-blue-ribbon': isActive,
                  'text-black dark:text-white': !isActive,
                })}
              >
                <div className="flex justify-center w-full mb-2">{item.icon}</div>
                <span className="text-xs font-medium text-center">
                  {intl.formatMessage({ id: item.text as any })}
                </span>
              </a>
            </Link>
          );
        })}
      </div>
      <Modal
        wrapperClassName="h-full"
        panelClassName="h-full w-full"
        dialogClassName="z-50"
        open={isMenuOpen}
        onClose={() => {
          setIsMenuOpen(false);
        }}
      >
        <div className="px-6 mt-11">
          <button
            className="block mb-10 ml-auto dark:text-white"
            onClick={() => setIsMenuOpen(false)}
            type="button"
          >
            <CloseIcon width={20} />
          </button>

          <Link href={process.env.NEXT_PUBLIC_API_DOCS_URL} prefetch={false}>
            <a className={clsx(styles.menu_item)}>
              <div className="flex items-center ">
                <DocIcon className="mr-4 text-neutral-manatee dark:text-neutral-manatee keep-color" />
                <p className={clsx(styles.menu_item_text)}>
                  {intl.formatMessage({ id: 'cmpt.user-menu.api' })}
                </p>
              </div>
              <ChevronRightIcon className="dark:text-white" width={16} />
            </a>
          </Link>

          <Link href="/user/" prefetch={false}>
            <a className={clsx(styles.menu_item)}>
              <div className="flex items-center ">
                <HeadPhoneIcon
                  width={18}
                  className="mr-4 text-neutral-manatee dark:text-neutral-manatee"
                />
                <p className={clsx(styles.menu_item_text)}>
                  {intl.formatMessage({ id: 'cmpt.user-menu.support' })}
                </p>
              </div>
              <ChevronRightIcon className="dark:text-white" width={16} />
            </a>
          </Link>

          <button
            type="button"
            onClick={() => {
              logout();
              router.push('/user/get-started');
            }}
            className={clsx(styles.menu_item)}
          >
            <div className="flex items-center text-red-500 dark:text-red-500">
              <LogoutIcon className="mr-4 stroke-red-500 keep-color" />
              <span className={clsx(styles.menu_item_text, 'text-red-500 dark:text-red-500')}>
                {intl.formatMessage({ id: 'general.logout' })}
              </span>
            </div>
          </button>

          <div className={clsx('justify-between items container-default !px-0 mt-14')}>
            <div className="flex items-center justify-between mb-9">
              <button className="flex items-center" type="button">
                <span className="mr-2 text-xs font-bold">English</span> <WorldIcon width={16} />
              </button>
              <div className="border rounded-full border-neutral-ghost-white max-w-[40px] h-[40px]">
                <IconButton
                  className="min-w-[38px] h-[38px] flex justify-center items-center p-0"
                  color="transparent"
                  onClick={toggle}
                  icon={mode === 'light' ? <MoonIcon /> : <SunIcon className="!w-4 text-white" />}
                />
              </div>
            </div>
            <div className="flex flex-col items-start justify-center gap-4 lg:flex-row lg:items-center">
              <nav className="flex gap-[30px]">
                {customNavs.map((nav) => (
                  <Link href={nav.link!} target="_blank" rel="noreferrer" key={nav.label}>
                    <a className="text-xs font-bold text-default">{nav.label}</a>
                  </Link>
                ))}
              </nav>
              <p className="text-xs text-neutral-gray-web-500">
                {intl.formatMessage({ id: 'layout.footer.all-rights' })}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

interface FooterProps {
  className?: string;
}

export const Footer = ({ className }: FooterProps) => {
  const { signedIn } = useAuthUser();
  const isMounted = useIsMounted();
  return (
    <footer className={clsx(styles.base)}>
      {signedIn && isMounted && <MobileMenu />}
      {isMounted && <SmallFooter className={className} />}
    </footer>
  );
};

const CustomMobileMenu = () => {
  const intl = useIntl();

  const { logout } = useAuthUser();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { mode, toggle } = useTheme();

  return (
    <>
      <Modal
        wrapperClassName="h-full"
        panelClassName="h-full w-full"
        dialogClassName="z-50"
        open={isMenuOpen}
        onClose={() => {
          setIsMenuOpen(false);
        }}
      >
        <div className="px-6 mt-11">
          <button
            className="block mb-10 ml-auto dark:text-white"
            onClick={() => setIsMenuOpen(false)}
            type="button"
          >
            <CloseIcon width={20} className="keep-color" />
          </button>

          <Link href="/user" prefetch={false}>
            <a className={clsx(styles.menu_item)}>
              <div className="flex items-center ">
                <HeadPhoneIcon
                  width={18}
                  className="mr-4 text-neutral-manatee dark:text-neutral-manatee"
                />
                <p className={clsx(styles.menu_item_text)}>
                  {intl.formatMessage({ id: 'cmpt.user-menu.support' })}
                </p>
              </div>
              <ChevronRightIcon className="dark:text-white" width={16} />
            </a>
          </Link>

          <button
            type="button"
            onClick={() => {
              logout();
            }}
            className={clsx(styles.menu_item)}
          >
            <div className="flex items-center ">
              <LogoutIcon className="mr-4 text-neutral-manatee dark:text-white keep-color" />
              <span className={clsx(styles.menu_item_text)}>
                {intl.formatMessage({ id: 'general.logout' })}
              </span>
            </div>
          </button>

          <div className={clsx('justify-between items container-default !px-0 mt-14')}>
            <div className="flex items-center justify-between mb-9">
              <button className="flex items-center" type="button">
                <span className="mr-2 text-xs font-bold">English</span> <WorldIcon width={16} />
              </button>
              <div className="border rounded-full border-neutral-ghost-white max-w-[40px] h-[40px]">
                <IconButton
                  className="min-w-[38px] h-[38px] flex justify-center items-center p-0"
                  color="transparent"
                  onClick={toggle}
                  icon={mode === 'light' ? <MoonIcon /> : <SunIcon className="!w-4 text-white" />}
                />
              </div>
            </div>
            <div className="flex flex-col items-start justify-center gap-4 lg:flex-row lg:items-center">
              <nav className="flex gap-[30px]">
                {customNavs.map((nav) => (
                  <Link href={nav.link!} target="_blank" rel="noreferrer" key={nav.label}>
                    <a className="text-xs font-bold text-default">{nav.label}</a>
                  </Link>
                ))}
              </nav>
              <p className="text-xs text-neutral-gray-web-500">
                © My Home Pathway, 2022. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export const CustomSmallFooter = ({ className = 'container-default' }: SmallFooterProps) => {
  const { mode, toggle } = useTheme();
  return (
    <div className={clsx('flex justify-between items', className)}>
      <div className="flex flex-col items-start justify-center w-full gap-4 lg:justify-start lg:flex-row lg:items-center">
        <div className="flex lg:items-center justify-between w-full lg:w-[max-content] lg:mr-3">
          <div>
            <nav className="flex gap-[30px] w-full">
              {customNavs.map((nav) => (
                <Link href={nav.link!} target="_blank" rel="noreferrer" key={nav.label}>
                  <a className="text-xs font-bold text-default">{nav.label}</a>
                </Link>
              ))}
            </nav>
            <p className="mt-4 text-xs text-neutral-gray-web-500 lg:hidden">
              © My Home Pathway, 2022. All rights reserved.
            </p>
          </div>
          <div className="border rounded-full max-w-[40px] h-[40px] border-neutral-ghost-white lg:hidden">
            <IconButton
              className="min-w-[38px] h-[38px] flex justify-center items-center !p-0"
              color="transparent"
              onClick={toggle}
              icon={mode === 'light' ? <MoonIcon /> : <SunIcon className="!w-4 dark:text-white" />}
            />
          </div>
        </div>
        <p className="hidden text-xs text-neutral-gray-web-500 lg:block">
          © My Home Pathway, 2022. All rights reserved.
        </p>
      </div>
      <div className="hidden lg:flex">
        <button className="flex items-center mr-5" type="button">
          <span className="mr-2 text-xs font-bold">English</span> <WorldIcon width={16} />
        </button>
        <div className="hidden border rounded-full border-neutral-ghost-white lg:block max-w-[40px] h-[40px]">
          <IconButton
            className="min-w-[38px] h-[38px] flex justify-center items-center p-0"
            color="transparent"
            onClick={toggle}
            icon={mode === 'light' ? <MoonIcon /> : <SunIcon className="!w-4 dark:text-white" />}
          />
        </div>
      </div>
    </div>
  );
};
