import { useAuthUser, useClickOutside } from '@hooks';
import Bell from '@icons/bxs-bell.svg';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import Envelope from '@icons/bxs-envelope.svg';
import ChatBoxesIcon from '@icons/chat-boxes.svg';
import ChevronUp from '@icons/chevron-up.svg';
import MessageBoxIcon from '@icons/message-box.svg';
import ProfileIcon from '@icons/profile.svg';
import SMSIcon from '@icons/sms.svg';
import { commonRoutes } from '@utils';
import clsx from 'clsx';
import { ArrayCreditAlerts } from '@components/array-credit-alerts';
import { UserLogo } from '@components/layout/header/user-logo';
import { DashboardInfoModal } from '@components/modal/dashboard-info-modal';
import { FeedbackModal } from '@components/modal/feedback-modal';
import { ShareModal } from '@components/modal/share-modal';
import { useHandleReferralLink } from '@hooks/use-handle-referral-link';
import { SignOutMenuIcon } from '@iconComponents/SignOutMenuIcon';
import { useRouter } from 'next/router';
import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { io } from 'socket.io-client';
import NotificationScreen from './notification-menu';
import { EOnboardingStepStatus } from '../../../../services/Api';

const ProfileDropdownItem = ({
  Icon,
  text,
  onClick,
}: {
  Icon: (props) => JSX.Element;
  text: string;
  onClick: MouseEventHandler;
}) => (
  <button
    type="button"
    className="flex text-[#1C1A27] mb-[20px] hover:bg-gray-50"
    onClick={onClick}
  >
    <Icon />
    <div className="ml-[10px] text-[15px] font-normal whitespace-nowrap">{text}</div>
  </button>
);
let newSocket = null;

export const BuyerLayoutProfileMenu = () => {
  const { firstName, lastName, email, isLoadingUserInfo, profile, logout } = useAuthUser(true);
  const [isUserInfoOpenDesktop, setIsUserInfoOpenDesktop] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [isMessagesOpen, setIsMessagesOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notifications, setNotifications] = useState([]); // Store multiple notifications
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);

  const refClickOutSideUserInfoDesktop = useRef<HTMLDivElement>(null);

  useClickOutside(refClickOutSideUserInfoDesktop, () => {
    setIsUserInfoOpenDesktop(false);
  });

  const router = useRouter();
  const handleReferralLink = useHandleReferralLink();
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const user = useAuthUser();

  const checkIncompleteSetup = () => {
    const setUpLeftCount = [
      profile?.onboardingTransactionAccountStatus !== EOnboardingStepStatus.completed,
      profile?.onboardingCreditReportStatus !== EOnboardingStepStatus.completed,
      profile?.onboardingIncomeAccountStatus !== EOnboardingStepStatus.completed,
    ].reduce((accumulator, currVal) => (currVal ? accumulator + 1 : accumulator), 0);

    return setUpLeftCount > 0;
  };

  const fetchNotifications = async () => {
    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/notifications/notifications-list/${user.id}`,
      );
      const data = await response.json();
      // const visibleNotifications = data.filter(
      //   (notification) => notification.notificationType !== "seen"
      // );
      setNotifications(data);
      const seenNotifications = data.filter(
        (notification) => notification.notificationType === 'seen',
      );

      if (seenNotifications.length > 0) {
        const latestSeenNotification = seenNotifications.reduce((latest, current) => {
          return new Date(latest.createdAt) > new Date(current.createdAt) ? latest : current;
        });

        console.log('Latest seen notification:', latestSeenNotification);

        // Check for notifications created after the latest "seen" notification
        const newerNotifications = data.filter(
          (notification) =>
            new Date(notification.createdAt) > new Date(latestSeenNotification.createdAt),
        );

        if (newerNotifications.length > 0) {
          setHasUnreadNotifications(true);
          console.log(
            'Newer notifications found after the latest seen notification:',
            newerNotifications,
          );
        } else {
          console.log('No notifications found after the latest seen notification.');
        }
      } else {
        console.log('No seen notifications found.');
      }
      // Check if the account setup is incomplete
      if (checkIncompleteSetup()) {
        const setupIncompleteNotification = {
          notification: JSON.stringify({
            message: 'Your account setup is incomplete. Please complete all steps.',
          }),
          createdAt: new Date().toISOString(),
        };

        // Add the setup incomplete notification on top
        data.unshift(setupIncompleteNotification);
        setHasUnreadNotifications(true);
      }

      // Set notifications
      setNotifications(data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };
  const sendNotification = async () => {
    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/notifications/seen/${user.id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      const result = await response.json();
      setHasUnreadNotifications(false);
      return result;
    } catch (error) {
      console.error('Error sending notification:', error);
      throw error;
    }
  };
  useEffect(() => {
    console.log('Rendering WebSocket connection...');

    if (newSocket == null && !newSocket?.connected) {
      newSocket = io(`${process.env.NEXT_PUBLIC_API_URL}`, {
        transports: ['websocket'],
      });

      newSocket.on('connect', () => {
        console.log('Connected to WebSocket server');
      });

      newSocket.on('disconnect', () => {
        console.log('Disconnected from server.');
      });

      newSocket.on('notification', (message) => {
        console.log('Received notification:', message);
        setHasUnreadNotifications(true);

        if (message.notificationType === 'message' && message.receiverID === user.id) {
          const notification = {
            notification: JSON.stringify({ message: message.text || `You have a new message!` }),
            senderID: message.senderID || null,
            receiverID: user.id,
            notificationType: 'message',
            createdAt: new Date(),
          };
          setHasUnreadNotifications(true);

          setNotifications((prevNotifications) => {
            const updatedNotifications = [notification, ...prevNotifications];
            return updatedNotifications.sort(
              (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
            );
          });

          console.log('Notification of message:', notification);
        } else {
          console.log('You can’t see the notification');
        }
      });
    }
    fetchNotifications();
  }, [user.id]);

  const handleNotificationClick = (e) => {
    e.stopPropagation();

    setIsNotificationOpen(!isNotificationOpen);
    // fetchNotifications();

    if (!isNotificationOpen) {
      sendNotification();
      setHasUnreadNotifications(false);
    }
  };

  const handleCloseNotification = () => {
    setIsNotificationOpen(false);
  };

  return (
    <>
      <div className="items-start gap-2 flex  z-[1]  rounded pl-4 pr-4 pb-2 pt-2 align-self-start xs:mr-2 xs:scale-9">
        <div className="relative" ref={refClickOutSideUserInfoDesktop}>
          <div
            onClick={() => {
              setIsUserInfoOpenDesktop((o) => !o);
            }}
            className="flex items-center gap-1 cursor-pointer select-none"
          >
            {!isDesktop && <Envelope className="w-8 ml-1.5 text-[#635E7D]" />}
            {/* <button
              type="button"
              className="text-[#635E7D] hover:text-[#D0CAE9]"
              onClick={(e) => {
                e.stopPropagation();
                setIsMessagesOpen(true);
              }}
            >
              <Bell className="w-8" />
            </button> */}

            <button
              type="button"
              className="relative"
              style={{ color: '#10B395' }}
              onClick={handleNotificationClick}
            >
              {hasUnreadNotifications && (
                <span className="absolute top-0 left-4 h-2 w-2 bg-red-500 rounded-full"></span>
              )}

              {isNotificationOpen && (
                <div
                  className={`fixed top-0 right-0 h-full w-80 bg-white shadow-lg overflow-y-auto z-50 transition-transform transform ${
                    isNotificationOpen ? 'translate-x-0' : 'translate-x-full'
                  }`}
                >
                  <NotificationScreen
                    onClose={handleCloseNotification}
                    notifications={notifications}
                  />
                </div>
              )}
              <NotificationsOutlinedIcon className="mr-2" />
            </button>

            {isDesktop && (
              <div className="text-[15px] text-[#1C1A27] text-right mr-[4px]">
                <div>
                  {firstName} {lastName}
                </div>
                <div className="text-[#635E7D]">{email}</div>
              </div>
            )}
            <UserLogo firstName={firstName} lastName={lastName} />
            <ChevronUp
              width={20}
              className={clsx('keep-color ml-1.5 text-[#1C1A27] transition-transform', {
                'rotate-[0deg]': isUserInfoOpenDesktop,
                'rotate-[180deg]': !isUserInfoOpenDesktop,
              })}
            />
          </div>

          <div
            className={clsx(
              ' absolute shadow-[0px_20px_25px_-5px_rgba(0,_0,_0,_0.1)] rounded-lg filter-[drop-shadow(0px_10px_10px_rgba(0,_0,_0,_0.04))] flex flex-col min-w-[201px] right-0 top-10 bg-white transition font-medium z-[10]',
              'top-14 pt-[20px] pl-[20px] pr-[20px]',
              { 'visible opacity-100': isUserInfoOpenDesktop },
              { 'invisible opacity-0': !isUserInfoOpenDesktop },
            )}
          >
            <ProfileDropdownItem
              onClick={() => {
                setIsUserInfoOpenDesktop(false);
                router.push(commonRoutes.buyer.auth.settings);
              }}
              Icon={ProfileIcon}
              text="Profile"
            />
            <ProfileDropdownItem
              onClick={() => {
                setIsShareOpen(true);
                setIsUserInfoOpenDesktop(false);
              }}
              Icon={SMSIcon}
              text="Invite with SMS"
            />
            <ProfileDropdownItem
              onClick={() => {
                handleReferralLink();
                setIsUserInfoOpenDesktop(false);
              }}
              Icon={ChatBoxesIcon}
              text="Copy Referral Link"
            />

            <ProfileDropdownItem
              onClick={() => {
                setIsFeedbackOpen(true);
                setIsUserInfoOpenDesktop(false);
              }}
              Icon={MessageBoxIcon}
              text="Give Feedback"
            />

            {!isDesktop && (
              <ProfileDropdownItem
                onClick={() => {
                  logout();
                  setIsUserInfoOpenDesktop(false);
                  router.push('/user/get-started');
                }}
                Icon={SignOutMenuIcon}
                text="Sign out"
              />
            )}
          </div>
        </div>
        <FeedbackModal
          open={isFeedbackOpen}
          onClose={() => {
            setIsFeedbackOpen(false);
          }}
        />
        <ShareModal isShareOpen={isShareOpen} setIsShareOpen={setIsShareOpen} />
        {isMessagesOpen && (
          <DashboardInfoModal title="Alerts" open onClose={() => setIsMessagesOpen(false)}>
            <ArrayCreditAlerts closeArrayAlerts={() => setIsMessagesOpen(false)} />
          </DashboardInfoModal>
        )}
      </div>
    </>
  );
};
