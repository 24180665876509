import { Label } from '../label';
import { useAnotherAmount } from '../time-pills';
import { useFormContext, UseFormRegisterReturn } from 'react-hook-form';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import ChevronDownIcon from '@icons/chevron-down.svg';
import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import ReactInputMask from 'react-input-mask';

interface InputSelectProps {
  showRequired?: boolean;
  name: string;
  options: { value: string; label: string }[];
  validations?: any;
  label?: string;
  showLabel?: boolean;
  className?: string;
  placeholder?: string;
  onChange?: ({ value, name }) => void;
  shouldRegister?: boolean;
  value?: string;
}

const SelectDateAmount = ({
  showRequired = true,
  name,
  options,
  validations,
  label: customLabel,
  showLabel = true,
  className,
  placeholder = 'Select...',
  onChange,
  shouldRegister = true,
  value,
}: InputSelectProps) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors, isSubmitted },
  } = useFormContext();

  const selected: string = shouldRegister ? watch(name) : value;

  const intl = useIntl();

  const formatValidations = { ...validations };

  const label = customLabel || intl.formatMessage({ id: `page.account.${name}` });

  if (!validations?.pattern?.value) {
    formatValidations.pattern = {
      value: validations?.pattern,
      message: `This ${label.replace('*', '').toLowerCase()} is invalid, please try again.`,
    };
  }

  const requiredMessage = `${label.replace('*', '').toLowerCase()} is required.`;

  if (validations?.required || showRequired) {
    formatValidations.required = requiredMessage;
  }

  const errorInvalid = errors && errors[name]?.message && isSubmitted;
  const inputRegister = shouldRegister ? register(name, formatValidations) : false;
  const currentExpectedPurchaseMonthsPeriod = watch('expectedPurchaseMonthsPeriod');

  const inputValue = currentExpectedPurchaseMonthsPeriod;

  return (
    <div className={className}>
      {showLabel && <Label name={name}>{label}</Label>}
      <div className="flex items-center">
        <div className="border border-gray-200 border-r-0 rounded-l h-[48px] w-full">
          <ReactInputMask
            mask="999"
            value={String(inputValue) || ''}
            onChange={(e) => {
              setValue('expectedPurchaseMonthsPeriod', e.target.value, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
              });
            }}
            maskPlaceholder=""
            className="h-full px-3 w-full text-sm text-gray-900 rounded-l"
          />
        </div>
        <Listbox
          {...inputRegister}
          value={selected}
          onChange={(value) => {
            if (shouldRegister) {
              (inputRegister as UseFormRegisterReturn<string>).onChange({
                target: { value, name },
              });
            }
            if (onChange) {
              onChange({ value, name });
            }
          }}
        >
          <div className="relative">
            <Listbox.Button
              className={clsx(
                'relative py-2 pr-10 text-left bg-white dark:bg-transparent cursor-default focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm border border-gray-200 dark:border-black-dark w-full px-3 rounded-r bg-transparent text-sm min-h-[48px]',
                { 'border-error-500': errorInvalid },
              )}
            >
              {selected ? (
                <span className="block truncate">
                  {options.find((option) => option.value === selected)?.label}
                </span>
              ) : (
                <span className="block text-gray-400 truncate">{placeholder}</span>
              )}
              <span className="absolute inset-y-0 right-0 flex items-center pr-[12px] pointer-events-none">
                <ChevronDownIcon className="text-gray-400 keep-color" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 w-full py-0 mt-1 overflow-auto text-base bg-white border border-gray-200 rounded max-h-60 focus:outline-none sm:text-sm dark:border-black-dark dark:bg-black-background ring-0">
                {options.map((item, i) => (
                  <Listbox.Option
                    key={i}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 px-3 border-b border-gray-200 pr-4 min-h-[48px] z-10 last:border-b-0 items-center flex text-sm dark:bg-black-background dark:text-white dark:border-black-dark ${
                        active
                          ? 'bg-primary dark:bg-primary text-white-500 dark:text-white-500'
                          : 'text-gray-900 bg-white'
                      }`
                    }
                    value={item.value}
                  >
                    {item.label}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </div>
  );
};

export const SelectExpectedPurchasePeriod = () => {
  const { isAnotherAmount, otherAmountSelection, setOtherAmountSelection } = useAnotherAmount();
  const methods = useFormContext();

  methods.register('expectedPurchaseMonthsPeriod', {
    required: 'Choose a valid amount',
    validate: (value) => {
      const minMonth = 1;
      const minYear = 1;
      const maxYear = 50;
      const maxMonth = 50 * 12;
      if (
        isAnotherAmount &&
        otherAmountSelection === 'months' &&
        (value < minMonth || value > maxMonth)
      ) {
        return `Enter a valid amount (${minMonth}-${maxMonth} months)`;
      }
      if (
        isAnotherAmount &&
        otherAmountSelection === 'years' &&
        (value < minYear || value > maxYear)
      ) {
        return `Enter a valid amount (${minYear}-${maxYear} years)`;
      }

      return null;
    },
  });

  return (
    isAnotherAmount && (
      <SelectDateAmount
        className="mt-6"
        name="otherAmountSelection"
        label="Other amount"
        validations={{ required: false }}
        value={otherAmountSelection}
        shouldRegister={false}
        onChange={({ value }) => {
          if (value) {
            setOtherAmountSelection(value);
          }
          methods.setValue('expectedPurchaseMonthsPeriod', null);
        }}
        showLabel={false}
        options={[
          { label: 'Months', value: 'months' },
          { label: 'Years', value: 'years' },
        ]}
      />
    )
  );
};
