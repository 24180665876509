import { extractNumbers } from '@utils';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { Currency } from '../currency';

export const HousePriceGoal = () => {
  const methods = useFormContext();
  return (
    <div className="relative">
      <div
        className={clsx('absolute left-3 top-1/2 -translate-y-1/2 -mt-[0px]', {
          '-mt-[12px] text-error-500': methods.formState.errors.housePriceGoal,
          'text-gray-400': !methods.watch('housePriceGoal'),
        })}
      >
        $
      </div>
      <Currency
        name="housePriceGoal"
        label="House buying goal"
        showLabel={false}
        placeholder="0"
        thousandSeparator=","
        className="pl-8"
        decimalSeparator=" "
        allowNegative={false}
        validations={{
          validate: (value) => {
            const numberVal = Number(extractNumbers(value));
            if (Number.isNaN(numberVal) || numberVal > 10000000 || numberVal < 50000) {
              return 'Please select an amount from 50k to 10m';
            }
            return null;
          },
        }}
      />
    </div>
  );
};
