import { ErrorMessage, Modal } from '@components';
import { AxiosRequestConfig } from 'axios';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useIntl } from 'react-intl';
import { E164Number } from 'libphonenumber-js';

const isArrayFull = (object: any[], length: number): boolean =>
  object.length === length && object.every((x) => x !== null && x !== undefined);

export interface CodeInputProps {
  /**
   * State of modal open.
   */
  open: boolean;

  /**
   * Callback function to be called when the modal is open and closed.
   */
  onClose: () => void;

  /**
   * Verify callback function.
   */
  verifyCode: (code: string) => void;

  /**
   * Phone number to be used.
   */
  phoneNumber?: string;

  /**
   * Callback function to resend code.
   */
  onCodeResent: () => void;

  error?: AxiosRequestConfig<any> | null | undefined;
  email?: string;
}

const BACKSPACE_KEY = 'Backspace';
const LEFT_KEY = 'ArrowLeft';
const RIGHT_KEY = 'ArrowRight';
const CODE_LENGTH = 5;

type InputType = number | null;

const styles = {
  input:
    'w-[45px] h-[45px] lg:w-[70px] lg:h-[70px] text-2xl p-0 text-center text-gray-900 font-semibold border border-gray rounded-lg hover:border-primary-500 focus:border-primary-500 focus:outline-none bg-transparent dark:border-black-dark dark:text-white',
};

export const CodeInputModal = ({
  open = false,
  onClose,
  onCodeResent,
  verifyCode,
  phoneNumber,
  error,
  email,
}: CodeInputProps) => {
  const intl = useIntl();

  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [code, setCode] = useState<InputType[]>([]);
  const [duration, setDuration] = useState(0);
  const intervalId = useRef<NodeJS.Timer>(null);

  const changeInputFocus = (index: number) => {
    const ref = inputRefs.current[index];
    if (ref) {
      ref.focus();
    }
  };

  const updateCode = (value: number | null, index: number) => {
    const temp = [...code];
    temp[index] = value;
    setCode([...temp]);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const key = event.nativeEvent.code;

    switch (key) {
      case BACKSPACE_KEY:
        event.preventDefault();
        updateCode(null, index);
        changeInputFocus(index - 1);
        break;
      case RIGHT_KEY:
        changeInputFocus(index + 1);
        break;
      case LEFT_KEY:
        changeInputFocus(index - 1);
        break;

      default:
        return;
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (e.target.value.length > 1) return;
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      updateCode(value, index);
      if (index < CODE_LENGTH - 1) {
        changeInputFocus(index + 1);
      }
    }
  };

  const onPasteNumber = (e: React.ClipboardEvent) => {
    const pasteValue = e.clipboardData.getData('Text');
    if (pasteValue.length === CODE_LENGTH && !isNaN(Number(pasteValue))) {
      setCode([...pasteValue.split('').map((e: string) => Number(e))]);
    }
  };

  useEffect(() => {
    if (isArrayFull(code, CODE_LENGTH)) {
      const verificationCode = code.join('');
      verifyCode(verificationCode);
    }
  }, [code]); // eslint-disable-line

  const formattedPhoneNumber = formatPhoneNumberIntl(phoneNumber as E164Number);
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setCode([]);
      }}
      title={intl.formatMessage({
        id: email ? 'cmpt.code-input.title-email' : 'cmpt.code-input.title',
      })}
    >
      <p className="mt-4 text-xs text-black lg:text-sm dark:text-black-gray ">
        {email ? (
          <>
            {intl.formatMessage({ id: 'cmpt.code-input.email' })} {email}
          </>
        ) : (
          <>
            {intl.formatMessage({ id: 'cmpt.code-input.phone-number' })}{' '}
            {formattedPhoneNumber?.slice(0, formattedPhoneNumber.length - 3)}
            -***
          </>
        )}
      </p>
      <div className="flex flex-wrap gap-3 mt-9 lg:gap-4">
        {new Array(CODE_LENGTH).fill(0).map((_, i) => (
          <input
            className={clsx(styles.input)}
            key={i}
            type="text"
            pattern="[0-9]*"
            ref={(el) => {
              if (el) {
                inputRefs.current[i] = el;
              }
            }}
            onKeyDown={(e) => onKeyDown(e, i)}
            value={code[i] ?? ''}
            onChange={(e) => onChange(e, i)}
            onPaste={onPasteNumber}
          />
        ))}
      </div>
      <div className="flex mt-6 space-x-2">
        {duration > 0 ? (
          <div className="flex items-center">
            <span className="text-xs lg:text-sm">
              <span className="font-medium">Code resent.</span>{' '}
              <span>Wait {duration} seconds to try again.</span>
            </span>
          </div>
        ) : (
          <>
            <p className="text-xs text-black lg:text-sm dark:text-black-gray">
              {intl.formatMessage({ id: 'cmpt.code-input.no-received' })}
            </p>
            <button
              type="button"
              className="mr-4 text-xs cursor-pointer lg:text-sm text-primary"
              onClick={() => {
                onCodeResent();
                setDuration(20);

                clearInterval(intervalId.current);
                intervalId.current = setInterval(() => {
                  setDuration((d) => d - 1);
                }, 1000);
              }}
            >
              {intl.formatMessage({ id: 'cmpt.code-input.resend' })}
            </button>
          </>
        )}
      </div>
      {error?.data?.error === 'invalid_fields' && error?.data?.metadata?.fields[0] === 'code' ? (
        <ErrorMessage error="The code you have entered does not match our records." />
      ) : (
        error && <ErrorMessage error={error?.data?.message} />
      )}
      <style>
        {`
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          `}
      </style>
    </Modal>
  );
};
