import { useAuthUser } from '@hooks';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';

const protectedFromSignedIn = ['/account'];

interface RouteProtectorProps {
  children: ReactNode;
}

export const RouteProtector = ({ children }: RouteProtectorProps) => {
  const router = useRouter();
  const { signedIn } = useAuthUser();

  if (signedIn && protectedFromSignedIn.includes(router.asPath)) {
    router.push('/');
  }

  return <>{children}</>;
};
