import clsx from 'clsx';
import { ReactNode } from 'react';
import CheckFilled from '@icons/check-filled.svg';

export const StepHighlight = ({
  isActive,
  className,
  name,
  stepCount,
  totalSteps,
  step,
}: {
  isActive?: boolean;
  className?: string;
  name?: string;
  stepCount?: string;
  totalSteps?: string;
  step?: any;
}) => {
  return (
    <li className="flex flex-col items-center mb-auto">
      <div className={clsx('flex flex-row items-center')}>
        {stepCount == '1' ? (
          <div className="w-[20px] sm:w-[29px]"></div>
        ) : (
          <>
            {step > stepCount ? (
              <div className="h-[3px] w-[20px] sm:w-[29px] bg-[#FFF1DD]"></div>
            ) : (
              <div className="h-[3px] w-[20px] sm:w-[29px] bg-[#F8F8F8]"></div>
            )}
          </>
        )}
        <div
          className={clsx(
            'h-[40px] !w-[40px] lg:h-[50px] lg:!w-[50px] rounded-full  flex items-center justify-center',
            className,
            {
              'bg-[#FFF1DD] border border-[#FFF1DD]': isActive,
              'bg-[#F8F8F8] ': !isActive,
              'border border-[#9F98BE]': step < stepCount,
            },
          )}
        >
          {step > stepCount ? (
            <div className="bg-[#FFBB55] h-[40px] w-[40px] lg:h-[50px] lg:w-[50px] rounded-full flex items-center justify-center">
              <CheckFilled width={25} className="text-[#FFBB55] " />
            </div>
          ) : (
            <div
              className={clsx('text-center', {
                'text-[#FFBB55]': isActive,
                'text-[#9F98BE]': !isActive,
              })}
            >
              {stepCount}
            </div>
          )}
        </div>
        {stepCount == totalSteps ? (
          <div className="w-[20px] sm:w-[29px]"></div>
        ) : (
          <>
            {step > stepCount ? (
              <div className="h-[3px] w-[20px] sm:w-[29px] bg-[#FFF1DD]"></div>
            ) : (
              <div className="h-[3px] w-[20px] sm:w-[29px] bg-[#F8F8F8]"></div>
            )}
          </>
        )}
      </div>
      {name && (
        <div
          className={clsx('text-[12px] mt-[10px] text-center max-w-[80px] sm:max-w-none', {
            'text-[#FFBB55]': step > stepCount,
            'text-[#9F98BE]': step <= stepCount,
          })}
        >
          {name}
        </div>
      )}
    </li>
  );
};

export const StepHighlightWrapper = ({ children }: { children: ReactNode }) => {
  return <ul className="flex items-center w-full justify-center">{children}</ul>;
};
