import { useFormContext } from 'react-hook-form';
import { Fragment } from 'react';
import { Switch as SwitchComponent } from '@headlessui/react';

interface SwitchProps {
  name: string;
  label: string;
}

export const Switch = ({ name, label }: SwitchProps) => {
  const methods = useFormContext();

  const value = methods.watch(name);
  const checked = value === true;

  return (
    <SwitchComponent
      checked={checked}
      onChange={(value) => {
        methods.setValue(name, value, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      }}
      as={Fragment}
    >
      {({ checked }) => (
        /* Use the `checked` state to conditionally style the button. */
        <div className="flex items-center gap-4 mt-8 text-sm font-medium cursor-pointer">
          <button
            className={`${
              checked ? 'bg-primary' : 'bg-gray-200 dark:bg-black-dark'
            } relative inline-flex h-6 w-11 items-center rounded-full shrink-0`}
          >
            <span
              className={`${
                checked ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </button>
          {label}
        </div>
      )}
    </SwitchComponent>
  );
};
