import clsx from 'clsx';
import { forwardRef } from 'react';

const styles = {
  base: 'h-[1px] border-none block w-full bg-black-divider dark:bg-white-divider',
};

export interface DividerProps extends React.HTMLAttributes<HTMLHRElement> {}

export const Divider = forwardRef<HTMLHRElement, DividerProps>(function Divider(
  { className, ...props },
  ref
) {
  const classes = clsx(styles.base, className);

  return <hr className={classes} ref={ref} {...props} />;
});
