import { Loading } from '@components/loading';
import { FormHTMLAttributes, ReactNode } from 'react';
import { FormProvider } from 'react-hook-form';

export interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
  methods: any;
  children: ReactNode;
  isLoading?: boolean;
  wrapperClassName?: string;
}

export const Form = ({ children, methods, wrapperClassName, isLoading, ...props }: FormProps) => {
  return (
    <div className={wrapperClassName}>
      <FormProvider {...methods}>
        <form {...props}>
          <Loading isLoading={isLoading} />

          {children}
        </form>
      </FormProvider>
    </div>
  );
};
