import Image from 'next/image';
import ErrorIllustration from '../../../public/images/error.png';
import ErrorIllustration2 from '../../../public/images/error-2.png';
import { Button } from '../button';
import { useRouter } from 'next/router';
import ArrowLeft from '@icons/arrow-left.svg';
import { useAuthUser } from '@hooks';
import { commonRoutes } from '@utils';

export const ErrorState = ({
  type = 'something-went-wrong',
}: {
  type?:
    | 'something-went-wrong'
    | 'maintenance'
    | '404'
    | 'something-went-wrong-button'
    | 'wrong_associate_id'
    | 'invalid_co_borrowing_link';
}) => {
  const router = useRouter();
  const { signedIn } = useAuthUser();
  const handleGoBack = () => {
    if (signedIn) {
      router.push(commonRoutes.buyer.auth.summary);
    } else {
      router.push(commonRoutes.buyer.signup);
    }
  };

  const arrowBack = (
    <button className="z-[1] lg:hidden ml-6 -mt-8 w-max" onClick={handleGoBack} type="button">
      <ArrowLeft />
    </button>
  );

  const wrapperClassName =
    'text-center h-full flex justify-center flex-col w-full text-white bg-primary';

  if (type === 'maintenance') {
    return (
      <section className={wrapperClassName}>
        <div>
          <Image src={ErrorIllustration2} alt="" />
        </div>
        <h2 className="font-semibold text-xl mb-4 mt-10">Working on it!</h2>
        <p className="text-[15px]">
          We&apos;re down or undergoing maintenance <br /> right now but we&apos;ll be back soon.
        </p>
      </section>
    );
  }

  if (type === '404') {
    return (
      <section className={wrapperClassName}>
        {arrowBack}
        <div>
          <Image src={ErrorIllustration} alt="" />
        </div>
        <h2 className="font-semibold text-xl mb-4 mt-10">Oops, page not found!</h2>
        <p className="text-[15px]">
          Sorry for the headache, we can&apos;t find what <br /> you&apos;re looking for.
        </p>

        <div className="w-max mx-auto mt-10">
          <Button className="w-max" variant="black" onClick={handleGoBack}>
            GO BACK
          </Button>
        </div>
      </section>
    );
  }
  if (type === 'wrong_associate_id') {
    return (
      <section className="text-center m-auto flex justify-center flex-col w-[70%] rounded-lg p-4  bg-white">
        <h2 className="font-semibold text-xl mb-4 mt-10">Link doesn&apos;t look right!</h2>
        <p className="text-[15px]">
          Sorry but the associate link doesn&apos;t look right?
          <br /> Could you please check in with your realtor about the link?
        </p>

        <div className="w-max mx-auto mt-10">
          <Button className="w-max" variant="white" onClick={handleGoBack}>
            GO BACK
          </Button>
        </div>
      </section>
    );
  }
  if (type === 'invalid_co_borrowing_link') {
    return (
      <section className={wrapperClassName}>
        <h2 className="font-semibold text-xl mb-4 mt-10">Link doesn&apos;t look right!</h2>
        <p className="text-[15px]">
          The link you clicked on is either invalid or has expired.
          <br /> If you were trying to connect your account with your co-borrower,
          <br /> can you please ask them to resend the link?
          <br /> Or you can sign-up and send them a link to join you as a co-borrower!
        </p>

        <div className="w-max mx-auto mt-10">
          <Button className="w-max" variant="white" onClick={handleGoBack}>
            Sign Up
          </Button>
        </div>
      </section>
    );
  }

  return (
    <section className={wrapperClassName}>
      {arrowBack}
      <div>
        <Image src={ErrorIllustration} alt="" />
      </div>
      <h2 className="font-semibold text-xl mb-4 mt-10">Oops, something went wrong!</h2>
      <p className="text-[15px]">
        Sorry for the headache, we’re having <br /> trouble reaching this page.
      </p>

      {type === 'something-went-wrong-button' && (
        <div className="w-max mx-auto mt-10">
          <Button
            className="w-max"
            variant="black"
            onClick={() => {
              router.back();
            }}
          >
            GO BACK
          </Button>
        </div>
      )}
    </section>
  );
};
