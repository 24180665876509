import { Modal } from '@components/modal';
import CloseIcon from '@icons/close.svg';
import ArrowLeft from '@icons/arrow-left.svg';
import { ReactNode } from 'react';
import clsx from 'clsx';
import { useDashboardModal } from '@hooks';

export const DashboardInfoModal = ({
  onClose: onCloseProp,
  open,
  children,
  panelClassName,
  title,
  onBackButton,
  rightSideButton = <div className="w-[24px]"></div>,
}: {
  onClose: () => void;
  open: boolean;
  children: ReactNode;
  panelClassName?: string;
  title: ReactNode;
  rightSideButton?: ReactNode;
  onBackButton?: () => void;
}) => {
  const { setCurrentModal } = useDashboardModal();
  const onClose = () => {
    if (onCloseProp) {
      onCloseProp();
    } else {
      setCurrentModal(null);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      wrapperClassName="flex flex-col min-h-[100%] lg:p-4 items-center justify-center text-center lg:items-end lg:p-0"
      panelClassName={clsx(
        'w-full lg:max-w-[480px] text-left flex flex-col flex-grow lg:flex lg:overflow-visible',
        panelClassName,
      )}
    >
      <div className="flex items-center py-5 lg:py-6 px-6 border-b border-b-gray-200">
        <button
          className={clsx('z-[1]', {
            'lg:hidden': !onBackButton,
          })}
          onClick={() => {
            if (onBackButton) {
              onBackButton();
            } else {
              onClose();
            }
          }}
          type="button"
        >
          <ArrowLeft />
        </button>

        <div
          className={clsx('mx-auto lg:mx-0 lg:mr-auto w-max font-medium text-gray-900 lg:text-xl', {
            'lg:ml-3': onBackButton,
          })}
        >
          {title}
        </div>
        {rightSideButton}
        <button
          className={clsx('z-[1] lg:ml-4', {
            'lg:hidden': onBackButton,
            'hidden lg:block': !onBackButton,
          })}
          onClick={() => {
            onClose();
          }}
          type="button"
        >
          <CloseIcon width={24} strokeWidth={2} />
        </button>
      </div>
      {children}
    </Modal>
  );
};
