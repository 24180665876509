import clsx from 'clsx';

export const CashFlowItem = ({
  isIncome,
  title,
  subTitle,
  amount,
}: {
  isIncome?: boolean;
  title: string;
  subTitle: string;
  amount: number;
}) => (
  <div className="p-4 mb-2 border rounded border-[#DEDDE3]">
    <div className="flex justify-between mb-2">
      <div className="flex">
        <div
          className={clsx('w-5 h-2.5 my-auto', {
            'bg-[#10B395]': isIncome,
            'bg-[#FF9580]': !isIncome,
          })}
        />
        <div className="ml-2.5">{title}</div>
      </div>
      <div>${amount}</div>
    </div>
    <div className="text-[#635E7D]">{subTitle}</div>
  </div>
);
