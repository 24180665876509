import * as qs from 'qs';
import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';

export class RequestBuilder {
  config: AxiosRequestConfig;

  constructor(baseURL: string, config?: AxiosRequestConfig) {
    this.config = {
      baseURL,
      headers: {
        'content-type': 'application/json',
      },
      ...(config ?? {}),
    };

    return this;
  }

  contentType(type: string): RequestBuilder {
    this.config.headers['content-type'] = type;

    return this;
  }

  append(url: string): RequestBuilder {
    this.config.url = url;

    return this;
  }

  method(name: Method): RequestBuilder {
    this.config.method = name;

    return this;
  }

  params(params: Record<string, any>): RequestBuilder {
    this.config.params = params;

    return this;
  }

  setHeader(newHeader: Record<string, any>): RequestBuilder {
    this.config.headers = {
      ...this.config.headers,
      ...newHeader,
    };

    return this;
  }

  setData(data: any): RequestBuilder {
    this.config.data = data;

    if (
      this.config.headers['content-type'] ===
      'application/x-www-form-urlencoded'
    ) {
      this.config.data = qs.stringify(data);
    }

    if (this.config.headers['content-type'] === 'multipart/form-data') {
      this.config.data = data;
    }

    return this;
  }

  build<T = unknown>(): Promise<AxiosResponse<T>> {
    const client = axios.create(this.config);

    // Adding a response interceptor to handle responses and errors
    client.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        return response;
      },
      async (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const errorData = error.response;
          return Promise.reject(errorData);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          return Promise.reject(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          return Promise.reject(error.message);
        }
      }
    );

    return client.request(this.config);
  }
}
