import { useMutation } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { useForm } from 'react-hook-form';
import { Button, ErrorMessage, FormWrapper, Steps, CustomInput } from '@components';
import { useEffect, useState } from 'react';
import { useBuyerSignupInfo } from '@hooks';
import { ApiService, EUserType } from '@services/Api';
import { useIntl } from 'react-intl';
import CheckIcon from '@icons/checked-transparent.svg';
import ErrorIcon from '@icons/circled-close.svg';
import { encryptText } from '@utils/encryptionDecryption';
import { useMediaQuery } from 'react-responsive';
import { useRouter } from 'next/router';

export const Step2 = ({ onNextStep }) => {
  const { setBuyerInfo, email, authenticationId } = useBuyerSignupInfo();
  const methods = useForm();
  const [pwdCharCheck, setPwdCharCheck] = useState<boolean>(false);
  const [pwdNumberCheck, setPwdNumberCheck] = useState<boolean>(false);
  const [pwdAlphaCheck, setPwdAlphaCheck] = useState<boolean>(false);
  const [pwdConfirmPwdCheck, setPwdConfirmPwdCheck] = useState<any>(null);
  const intl = useIntl();
  const [verifyCodeMessage, setVerifyCodeMessage] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [code, setCode] = useState([]);
  const strCode = code.join('');
  const router = useRouter();
  const query = router?.query;

  const buyerFirstName = (query?.firstName as string) || '';
  const buyerLastName = (query?.lastName as string) || '';

  useEffect(() => {
    if (buyerFirstName) methods.setValue('firstName', buyerFirstName);
    if (buyerLastName) methods.setValue('lastName', buyerLastName);
  }, [buyerFirstName, buyerLastName, methods]);

  const isDesktop = useMediaQuery({ minWidth: 992 });

  const verifyCodeMutation = useMutation<
    ApiService.SignUpVerifyCodeResponse,
    AxiosRequestConfig,
    void
  >(
    async () => {
      const resp = await ApiService.signUpVerifyCode({
        authenticationId,
        userType: EUserType.buyer,
        code: strCode,
      });

      return resp.data;
    },
    {
      onSuccess: (result) => {
        setBuyerInfo({
          ...(result.partialSignupForm?.values ?? {}),
          isEmailVerified: true,
          password: '',
        });

        setVerifyCodeMessage('OTP verification successful.');
        setIsEmailVerified(true);
      },
    },
  );

  const resendCodeMutation = useMutation<void, AxiosRequestConfig, void>(async () => {
    verifyCodeMutation.reset();

    const resp = await ApiService.resendCode({
      id: authenticationId,
      type: 'authentication',
      emailCodeType: 'sign-up',
    });
    return resp.data;
  });

  useEffect(() => {
    if (strCode.length >= 5) {
      verifyCodeMutation.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBuyerInfo, strCode]);

  const PwdValidation = (value: any, confirmPwdCheck: any) => {
    setPwdCharCheck(value.charCheck);
    setPwdNumberCheck(value.numberCheck);
    setPwdAlphaCheck(value.alphaCheck);
    setPwdConfirmPwdCheck(confirmPwdCheck);
  };

  return (
    <FormWrapper
      isLoading={verifyCodeMutation.isLoading || resendCodeMutation.isLoading}
      onSubmit={methods.handleSubmit((values: any, rest) => {
        // setBuyerInfo({
        //   email,
        //   authenticationId,
        //   password: values.password,
        //   userType: 'buyer',
        //   step: step + 1,
        // });
        let emailSplit = email.split('@')[0];
        if (emailSplit.length > 8) {
          emailSplit = emailSplit.slice(0, 8);
        }
        const encryptedPassword = encryptText(values.password, emailSplit);

        //return;
        onNextStep({
          password: encryptedPassword,
          firstName: values.firstName,
          lastName: values.lastName,
        });
      })}
      methods={methods}
      maxWidth={350}
      containerClassName="lg:px-0 mb-0 lg:mt-0 xs:px-0 xs:h-full xs:scale-80"
    >
      <div className="max-w-[350px] lg:min-w-[624px] lg:justify-center mx-auto flex flex-col bg-white p-5 lg:p-[50px]">
        <Steps.Title className="text-center">Create Password</Steps.Title>

        <div className="flex flex-col items-center gap-1 mt-5 mb-2.5 mx-auto xs:mx-0">
          <p className="text-sm text-gray-500 dark:text-gray-400 mb-5">
            Complete this information to create your account
          </p>
          <p className="text-[15px] text-[#1C1A27] ">
            Please enter the verification code that was sent to {email}
          </p>
        </div>

        <div className="justify-center items-center">
          <Steps.CodeInput
            code={code}
            setCode={setCode}
            onCodeResent={resendCodeMutation.mutate}
            email={email}
            sendCodeInfo
            disable={isEmailVerified}
            error={verifyCodeMutation.error}
          />
        </div>

        {verifyCodeMessage && (
          <div className="text-sm font-semibold text-primary">{verifyCodeMessage}</div>
        )}
        <div className="grid gap-4 grid-cols-2 mt-2.5 xs:mt-7 xs:flex-row ">
          {/* <div className={!isDesktop ? "flex-row mt-2.5 xs:mt-7 " : "flex gap-4 grid-cols-2 mt-2.5 xs:mt-7 xs:flex-row "}> */}
          <CustomInput.Name disabled={!isEmailVerified} />
          <CustomInput.Name disabled={!isEmailVerified} name="lastName" label="Last name" />
        </div>
        <div
          className={`grid gap-4 grid-cols-2 relative mt-2.5 ${
            isEmailVerified ? 'enable-password-fields' : ''
          }`}
        >
          <CustomInput.Password
            name="password"
            label="Password"
            className={`${!isEmailVerified ? 'pointer-events-none' : ''}`}
            PwdValidation={PwdValidation}
            checkValidation={true}
            isError={pwdConfirmPwdCheck}
          />
          {pwdConfirmPwdCheck && (
            <ErrorMessage
              error={'Passwords do not match.' as string}
              className="text-xs absolute -bottom-6"
            />
          )}
          <CustomInput.Password
            name="re-password"
            className={`${!isEmailVerified ? 'pointer-events-none' : ''}`}
            label="Confirm password"
            isError={pwdConfirmPwdCheck}
          />
        </div>

        <div className="mt-5">
          <label className={`${!pwdConfirmPwdCheck ? `-mt-2` : `mt-2`} block text-xm`}>
            Password must contain:
          </label>
          <div className="flex flex-row items-center xs:mt-5 xs:mb-5">
            <div className="flex">
              {pwdCharCheck && <CheckIcon height={16} className="text-primary mr-2" />}
              {!pwdCharCheck && (
                <ErrorIcon height={16} className="text-error keep-color mr-2 mt-px" />
              )}
            </div>
            <div className="flex text-xm">Both upper and lowercase letters</div>
          </div>
          <div className="flex flex-row items-center xs:mb-5">
            <div className="flex">
              {pwdNumberCheck && <CheckIcon height={16} className="text-primary mr-2" />}
              {!pwdNumberCheck && (
                <ErrorIcon height={16} className="text-error keep-color mr-2 mt-px" />
              )}
            </div>
            <div className="flex text-xm">At least 1 number</div>
          </div>
          <div className="flex flex-row items-center xs:mb-5">
            <div className="flex">
              {pwdAlphaCheck && <CheckIcon height={16} className="text-primary mr-2" />}
              {!pwdAlphaCheck && (
                <ErrorIcon height={16} className="text-error keep-color mr-2 mt-px" />
              )}
            </div>
            <div className="flex text-xm">8 - 24 characters</div>
          </div>
        </div>

        <div className="mt-auto lg:mt-[30px]">
          <Steps.Button className="w-full">
            {intl.formatMessage({ id: 'page.account.createyouraccount' })}
          </Steps.Button>
          {/* <div className='w-[200px] border border-[#635E7D] my-5 mx-auto'></div>
                <Button
                    variant="text-primary"
                    className="text-sm font-normal text-[#1C1A27] hover:underline text-center mx-auto"
                    onClick={() => setBuyerInfo({ step: step - 1 })}
                    type="button"
                  >
                    {intl.formatMessage({ id: 'Back to Sign in' })}
            </Button>   */}
        </div>
      </div>
    </FormWrapper>
  );
};
