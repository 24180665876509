import { useSyncData } from '@hooks';
import { useEffect } from 'react';

export const useSyncOnLogin = (user) => {
  const syncDataMutation = useSyncData(false);

  // Sync data on login effect
  useEffect(() => {
    if (
      [
        user?.profile?.onboardingTransactionAccountStatus,
        user?.profile?.onboardingIncomeAccountStatus,
      ].some((val) => val === 'completed') &&
      !user?.profile?.isFetchingVerifiedData &&
      !user?.dataSynced
    ) {
      syncDataMutation.mutateAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user?.profile?.onboardingTransactionAccountStatus,
    user?.profile?.onboardingIncomeAccountStatus,
    user?.profile?.isFetchingVerifiedData,
    user?.dataSynced,
  ]);
};
