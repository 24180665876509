import { defaultErrorMessage, Toast } from '@utils';
import { Button } from '@components/button';
import { CustomInput } from '@components/custom-input';
import { Form } from '@components/form';
import { Loading } from '@components/loading';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { ApiService } from '@services/Api';
import { Modal } from '..';
import CloseIcon from '@icons/close.svg';
import ArrowLeft from '@icons/arrow-left.svg';
import { Dispatch, SetStateAction } from 'react';
import { AxiosRequestConfig } from 'axios';

export const ShareModal = ({
  isShareOpen,
  setIsShareOpen,
  description = "You'll be contributing to the growth and improvement of our services!",
}: {
  isShareOpen: boolean;
  setIsShareOpen: Dispatch<SetStateAction<boolean>>;
  description?: string;
}) => {
  const methods = useForm({
    defaultValues: {
      phone: '',
    },
  });

  const onSuccess = () => {
    setIsShareOpen(false);
    Toast({ text: 'Message sent.' });
  };

  const sendReferralLinkMutation = useMutation(
    async (phone: string) => {
      await ApiService.sendReferralLink(phone);
    },
    {
      onSuccess,
      onError: (error: AxiosRequestConfig) => {
        Toast({
          text:
            error.data?.statusCode === 400
              ? 'This phone number is invalid. Please, try again.'
              : defaultErrorMessage,
          variant: 'error',
        });
      },
    },
  );
  return (
    <Modal
      open={isShareOpen}
      onClose={() => {
        setIsShareOpen(false);
      }}
      wrapperClassName="flex flex-col lg:flex-row lg:min-h-[90%] min-h-[100%] lg:p-4 items-center justify-center text-center"
      panelClassName="w-full lg:max-w-[480px] lg:rounded-lg text-left flex flex-col flex-grow lg:block"
    >
      <Loading isLoading={sendReferralLinkMutation.isLoading} />
      <Form
        id="invite-sms"
        wrapperClassName="h-full flex flex-col flex-grow"
        className="h-full flex flex-col flex-grow"
        onSubmit={methods.handleSubmit((values: any) => {
          sendReferralLinkMutation.mutate(values.phone);
        })}
        methods={methods}
      >
        <div className="flex items-center py-4 lg:py-6 px-6">
          <button
            className="lg:hidden z-[1]"
            onClick={() => {
              setIsShareOpen(false);
            }}
            type="button"
          >
            <ArrowLeft />
          </button>{' '}
          <p className="mx-auto lg:mx-0 w-max font-medium font-slab text-gray-900 pr-5 lg:text-xl">
            Invite a friend
          </p>
          <button
            className="lg:block ml-auto hidden"
            onClick={() => setIsShareOpen(false)}
            type="button"
          >
            <CloseIcon strokeWidth={2} width={20} />
          </button>
        </div>
        <div className="p-6 pb-6 md:pb-0 border-t border-t-gray-200">
          <p className="text-sm text-gray-900 mb-6 lg:mb-4">{description}</p>
          <CustomInput.CountryPhone required />
        </div>
        <div className="mt-auto">
          <div className="flex flex-col lg:flex-row-reverse gap-4 ml-auto p-6 pb-12 lg:pb-6">
            <Button className="!w-full lg:!w-max" type="submit">
              Invite
            </Button>
            <Button
              onClick={() => {
                setIsShareOpen(false);
              }}
              className="!w-full lg:!w-max"
              variant="white"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
