import { link } from 'fs';

export const defaultErrorMessage =
  'We encountered an error on our side. Please try again in a few minutes.';

export const commonRoutes = {
  getStarted: '/user/get-started',
  nyuAlumniFeature:
    'https://www.stern.nyu.edu/portal-partners/alumni/alumni-news-profiles/news/castleigh-johnson-mba-08-shares-what-led-him-launch-my-home-pathway#.YQiMxVRPMCs.linkedin',
  housingWire:
    'https://www.housingwire.com/podcast/the-importance-of-financial-education-for-minority-borrowers/',
  forbes:
    'https://www.google.com/url?q=https://www.forbes.com/sites/annefield/2021/05/29/app-focuses-on-closing-the-racial-wealth-gap-by-boosting-home-ownership/&sa=D&source=editors&ust=1628604599727140&usg=AOvVaw3OHgRNTD-RgPy3qZh4mH-n',
  nextAdvisor:
    'https://time.com/nextadvisor/mortgages/mortgage-news/june-7-mortgage-rates-remain-below-3-percent/',
  newYorkUniversity:
    'https://www.stern.nyu.edu/portal-partners/alumni/alumni-news-profiles/news/castleigh-johnson-mba-08-shares-what-led-him-launch-my-home-pathway',
  refinery:
    'https://www.refinery29.com/en-us/2021/09/10668382/pandemic-real-estate-boom-racism-problem',
  realSimple:
    'https://www.realsimple.com/work-life/money/money-planning/save-for-a-house-on-any-salary',
  nationalmortgageprofessional:
    'https://nationalmortgageprofessional.com/news/76604/home-pathway-launches-nationwide',
  next: 'https://nextmortgagenews.com/news/first-republic-to-implement-my-home-pathway-app/',
  transUnionCreditDispute: 'https://service.transunion.com/dss/login.page',
  experianCreditDispute: 'https://www.experian.com/disputes/main.html',
  equifaxCreditDispute: 'https://www.equifax.com/personal/credit-report-services/credit-dispute',

  buyer: {
    signup: '/user/sign-up',
    signin: '/user/sign-in',
    getStarted: '/user/get-started',
    //waitlist: '/user/waitlist',
    welcome: '/user/welcome',
    reset: '/user/reset-password',

    onboarding: {
      completeSetup: '/user/onboarding/complete-setup',
      generateCredit: '/user/onboarding/complete-setup/generate-credit',
      linkBankAccount: '/user/onboarding/complete-setup/link-bank-account',
      verifyIncome: '/user/onboarding/complete-setup/verify-income',
    },

    auth: {
      settings: '/user/settings',
      summary: '/user/summary',
      finances: '/user/finances',
      feedback: '/user/settings/feedback',
      accounts: '/user/settings/accounts',
      account: '/user/settings/profile',
      income: '/user/settings/income',
      financialliteracy: '/user/financialLiteracy',
      rewards: '/user/rewards',

      additionalresources: '/user/additional-resources',
      goodwilltemplate: '/user/additional-resources/goodwill-template',
      giftingTemplate: '/user/additional-resources/gifting-template',

      thankyou: '/user/thank-you',

      onboarding: '/user/onboarding',

      messages: '/user/messages',
    },
  },
  coBorrower: {
    invite: '/co-borrower/invite',
  },
  partner: {
    signup: '/partner/sign-up',
    signin: '/partner/sign-in',
    settings: '/partner/settings',
  },
} as const;

export const incomeCategories = [
  'SALARY',
  'UNEMPLOYMENT',
  'CASH',
  'GIG_ECONOMY',
  'RENTAL',
  'CHILD_SUPPORT',
  'MILITARY',
  'RETIREMENT',
  'LONG_TERM_DISABILITY',
  'BANK_INTEREST',
  'CASH_DEPOSIT',
  'TRANSFER_FROM_APPLICATION',
  'TAX_REFUND',
  'OTHER',
];

export const debtCategories = ['RENT', 'MORTGAGE', 'LOAN', 'CREDIT_CARD_DEBT'];

export const payFrequencies = ['WEEKLY', 'BIWEEKLY', 'SEMI_MONTHLY', 'MONTHLY'];

export const localStorageDataRef = {
  acceptInvite: 'invite_id_on_sign_up',
  acceptInviteDate: 'invite_id_on_sign_up_created_at',
};
