import { toUSD } from '@utils';
import { AxiosRequestConfig } from 'axios';
import { useBuyerCreditScore } from '@hooks/use-buyer-credit-score';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { ApiService, CalculateScoreResponse } from '@services/Api';

export const useCalculateScore = (options?) => {
  const creditScoreQuery = useBuyerCreditScore();

  const creditScore = creditScoreQuery.data?.creditScore || null;

  const query = useQuery<CalculateScoreResponse, AxiosRequestConfig>(
    ['buyer-calculate-score'],
    async () => {
      const resp = await ApiService.calculateScore({
        creditScore,
      });

      return resp.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: creditScoreQuery.isError || creditScoreQuery.isSuccess,
      ...options,
    },
  );
  const isUnavailable = query.isSuccess && !query?.data;

  return { ...query, error: creditScoreQuery?.error || query?.error, isUnavailable };
};

export const useScoreProps = () => {
  const { data } = useCalculateScore();

  const props = useMemo(() => {
    return {
      debtToIncomeProps: {
        ...data?.grades?.debtToIncome,
        minRangeLabel:
          data?.grades?.debtToIncome?.pointRangeMax != null
            ? `${Math.round(data?.grades?.debtToIncome?.pointRangeMax * 100)}%`
            : '',
        maxRangeLabel:
          data?.grades?.debtToIncome?.nextGrade?.pointRangeMax != null
            ? `${Math.round(data?.grades?.debtToIncome?.nextGrade?.pointRangeMax * 100)}%`
            : '',
        pointRangeMax: data?.grades?.debtToIncome?.nextGrade?.pointRangeMax,
        pointRangeMin:
          data?.grades?.debtToIncome?.pointRangeMax ??
          data?.config?.debtToIncome?.[data?.config?.debtToIncome?.length - 1]?.min,
        nextGrade: {
          ...data?.grades?.debtToIncome?.nextGrade,
          pointRangeMin: data?.grades?.debtToIncome?.nextGrade?.pointRangeMax,
        },
        config: data?.config?.debtToIncome,
        reverse: true,
        valueLabel:
          data?.grades?.debtToIncome?.value != null
            ? `${Math.round(data?.grades?.debtToIncome?.value * 100)}%`
            : '',
      },
      grossIncomeProps: {
        ...data?.grades?.grossIncome,
        minRangeLabel:
          data?.grades?.grossIncome?.pointRangeMin != null
            ? toUSD(data?.grades?.grossIncome?.pointRangeMin)
            : '',
        maxRangeLabel:
          data?.grades?.grossIncome?.nextGrade?.pointRangeMin != null
            ? toUSD(data?.grades?.grossIncome?.nextGrade?.pointRangeMin)
            : '',
        config: data?.config?.grossIncome,
        valueLabel:
          data?.grades?.grossIncome?.value != null ? toUSD(data?.grades?.grossIncome?.value) : '',
      },
      creditScoreProps: {
        ...data?.grades?.creditScore,
        minRangeLabel:
          data?.grades?.creditScore?.pointRangeMin != null
            ? data?.grades?.creditScore?.pointRangeMin
            : '',
        valueLabel: data?.grades?.creditScore?.value,
        maxRangeLabel:
          data?.grades?.creditScore?.nextGrade?.pointRangeMin != null
            ? data?.grades?.creditScore?.nextGrade?.pointRangeMin
            : '',
        config: data?.config?.creditScore,
      },
      downpaymentProps: {
        ...data?.grades?.downpaymentPercentageSaved,
        minRangeLabel:
          data?.grades?.downpaymentPercentageSaved?.pointRangeMin != null
            ? `${Math.round(data?.grades?.downpaymentPercentageSaved?.pointRangeMin * 100)}%`
            : '',
        maxRangeLabel:
          data?.grades?.downpaymentPercentageSaved?.nextGrade?.pointRangeMin != null
            ? `${Math.round(
                data?.grades?.downpaymentPercentageSaved?.nextGrade?.pointRangeMin * 100,
              )}%`
            : '',
        config: data?.config?.downpaymentPercentageSaved,
        valueLabel:
          data?.grades?.downpaymentPercentageSaved?.value != null
            ? `${Math.round(data?.grades?.downpaymentPercentageSaved?.value * 100)}%`
            : '',
      },
    };
  }, [
    data?.config?.creditScore,
    data?.config?.debtToIncome,
    data?.config?.downpaymentPercentageSaved,
    data?.config?.grossIncome,
    data?.grades?.creditScore,
    data?.grades?.debtToIncome,
    data?.grades?.downpaymentPercentageSaved,
    data?.grades?.grossIncome,
  ]);

  return props;
};
