export const extractNumbers = (str: string | number) => String(str)?.replace(/[^\d]/g, '');

export const toUSD = (usd: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 0,
  }).format(usd);
};

export const moneyToMonthly = ({ value, frequency }: { value: number; frequency: string }) => {
  let calculatedAmountBasedOnFrequency = value;

  if (!frequency) {
    return calculatedAmountBasedOnFrequency;
  }

  if (frequency.toLowerCase() === 'weekly') {
    calculatedAmountBasedOnFrequency = value * 4;
  }
  if (frequency.toLowerCase() === 'biweekly') {
    calculatedAmountBasedOnFrequency = Math.round((value * 26) / 12);
  }
  if (frequency.toLowerCase() === 'semi_monthly') {
    calculatedAmountBasedOnFrequency = value * 2;
  }

  return calculatedAmountBasedOnFrequency;
};
