import clsx from 'clsx';
// TODO: Maybe remove these components entirely?
import { useClickOutside } from '@hooks';
import CheckBoxIcon from '@icons/check-box.svg';
import ChevronUp from '@icons/chevron-up.svg';
import { useRouter } from 'next/router';

import CloseBoxIcon from '@icons/close-box.svg';
import CloseIcon from '@icons/close.svg';
import FilterIcon from '@icons/filter-2.svg';
import ForwardRightIcon from '@icons/forward-right.svg';
import HorizontalOptionsIcon from '@icons/horizontal_options.svg';
import InfoSquare from '@icons/info-square.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PinIcon2 from '@icons/pin-v2.svg';
import SortIcon from '@icons/sort.svg';
import ChevronsLeftRight from '@icons/chevrons-left-right.svg';
import SlidersHorizontal from '@icons/sliders-horizontal.svg';
import SpreadSheetIcon from '@icons/spreadsheet.svg';
import { capitalize } from '@utils';
import { FilterPill } from '@components';
import { ControlledCheckbox } from '@components/custom-input/checkbox';
import { ControlledRadio } from '@components/custom-input/radio';
import { Dropdown } from '@components/dropdown';
import { CleanModal } from '@components/modal';
import Image from 'next/image';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import {
  ApiService,
  BuyerRecommendation,
  EBuyerRecommendationStatus,
  EBuyerRecommendationType,
  ERecommendationGap,
} from '@services/Api';
import { UpdateBuyerPayload } from '../../services/Api/controllers';
import { Empty } from '../empty';
import { SuggestionItem, SuggestionStatus } from './suggestion-card';
import { Button } from '@mui/material';

const recommendationTypeLabels = {
  [EBuyerRecommendationType.action]: 'Action',
  [EBuyerRecommendationType.alert]: 'Attention',
};

const recommendationSectionData = [
  {
    icon: (
      <div className="bg-[#635E7D] rounded h-[15px] w-[15px]">
        <PinIcon2 className="text-[#fff] mt-[-2px] ml-[-2px]" />
      </div>
    ),
    text: 'Pinned',
    itemKey: 'pinned',
  },
  {
    icon: <InfoSquare className="text-[#635E7D]" />,
    text: 'Available',
    itemKey: 'active',
  },
  {
    icon: (
      <div className="bg-[#635E7D] rounded h-[15px] w-[15px]">
        <HorizontalOptionsIcon className="text-[#fff]" />
      </div>
    ),
    text: 'In progress',
    itemKey: 'in_progress',
  },
  {
    icon: <CheckBoxIcon className="text-[#635E7D]" />,
    text: 'Completed',
    itemKey: 'completed',
  },
  {
    icon: (
      <div className="bg-[#635E7D] rounded h-[15px] w-[15px]">
        <ForwardRightIcon className="text-[#fff]" />
      </div>
    ),
    text: 'Deferred',
    itemKey: 'deferred',
  },
  {
    icon: (
      <div className="bg-[#635E7D] rounded h-[15px] w-[15px]">
        <CloseBoxIcon className="text-[#fff] mt-[-2px] ml-[-3px]" />
      </div>
    ),
    text: 'Dismissed',
    itemKey: 'dismissed',
  },
  {
    icon: <SpreadSheetIcon className="text-[#635E7D]" />,
    text: 'History',
    itemKey: 'history',
  },
];

export const useSuggestions = () => {
  const suggestionQuery = useQuery(
    ['suggestions'],
    async () => {
      const { data } = await ApiService.getBuyerRecommendations();
      return data;
    },
    {
      refetchOnWindowFocus: true,
    },
  );

  const suggestionStatusMutation = useMutation(
    async ({ status, id }: UpdateBuyerPayload) => {
      await ApiService.updateBuyerRecommendations({ status, id });
    },
    {
      onSuccess: () => {
        suggestionQuery.refetch();
      },
    },
  );

  const handleSuggestionChange = ({ status, id }: UpdateBuyerPayload) => {
    suggestionStatusMutation.mutate({ status, id });
  };

  const suggestions = useMemo(() => {
    const newSuggestions = {
      active: [],
      in_progress: [],
      completed: [],
      deferred: [],
      dismissed: [],
      pinned: [],
      history: [],
      all: [],
    };

    suggestionQuery?.data?.items?.forEach((suggestionItem) => {
      newSuggestions.all.push(suggestionItem);

      if (suggestionItem.status === EBuyerRecommendationStatus.active) {
        newSuggestions.active.push(suggestionItem);
      }

      if (suggestionItem.status === EBuyerRecommendationStatus.in_progress) {
        newSuggestions.in_progress.push(suggestionItem);
      }

      if (suggestionItem.status === EBuyerRecommendationStatus.completed) {
        newSuggestions.completed.push(suggestionItem);
      }

      if (suggestionItem.status === EBuyerRecommendationStatus.deferred) {
        newSuggestions.deferred.push(suggestionItem);
      }

      if (suggestionItem.status === EBuyerRecommendationStatus.dismissed) {
        newSuggestions.dismissed.push(suggestionItem);
      }

      if (suggestionItem.status === EBuyerRecommendationStatus.pinned) {
        newSuggestions.pinned.push(suggestionItem);
      }

      if (suggestionItem.status === EBuyerRecommendationStatus.history) {
        newSuggestions.history.push(suggestionItem);
      }
    });

    return newSuggestions;
  }, [suggestionQuery?.data?.items]);

  return {
    suggestions: suggestions,
    handleSuggestionChange,
    qtyAvailableSuggestions: suggestions.active.length + suggestions.pinned.length,
  };
};


const initialActionFilter = [
  EBuyerRecommendationStatus.active,
  EBuyerRecommendationStatus.in_progress,
  EBuyerRecommendationStatus.completed,
  EBuyerRecommendationStatus.deferred,
  EBuyerRecommendationStatus.dismissed,
];

const initialAlertFilter = [
  EBuyerRecommendationStatus.active,
  EBuyerRecommendationStatus.pinned,
  EBuyerRecommendationStatus.history,
];

export const RecommendationOptions = ({
  actionable,
  activeActionRecoExists,
  activeAlertRecoExists,
  latestActionRecoDate,
  latestAlertRecoDate,
  ...suggestionItem
}: {
  activeActionRecoExists: boolean;
  activeAlertRecoExists: boolean;
  latestActionRecoDate?: Date;
  latestAlertRecoDate?: Date;
  status: SuggestionStatus;
  actionable: boolean;
} & SuggestionItem) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonClassName =
    'text-left text-sm font-medium px-4 py-2 bg-[#fff] hover:bg-gray-50 rounded-md';
  const refClickOutside = useRef<HTMLDivElement>(null);

  useClickOutside(refClickOutside, () => {
    setIsOpen(false);
  });

  const { handleSuggestionChange } = useSuggestions();

  const optionData = useMemo(() => {
    const initialActionRecoOptions = [
      {
        title: 'Dismiss',
        statusChange: EBuyerRecommendationStatus.dismissed,
      },
    ];

    const initialAlertRecoOptions = [
      {
        title: 'Pin',
        statusChange: EBuyerRecommendationStatus.pinned,
      },
    ];

    let options = [];
    if (suggestionItem.type === 'action') {
      if (suggestionItem.status !== EBuyerRecommendationStatus.active) {
        if (!activeActionRecoExists && suggestionItem.createdAt === latestActionRecoDate) {
          options = [
            {
              title: 'Mark as completed',
              statusChange: EBuyerRecommendationStatus.completed,
            },
            {
              title: 'Reset action',
              statusChange: EBuyerRecommendationStatus.active,
            },
          ];
        }
      } else {
        options = initialActionRecoOptions;
      }
      if (suggestionItem.status !== EBuyerRecommendationStatus.in_progress) {
        if (!activeActionRecoExists && suggestionItem.createdAt === latestActionRecoDate) {
          options = [
            {
              title: 'Reset action',
              statusChange: EBuyerRecommendationStatus.active,
            },
          ];
        }
      } else {
        [
          {
            title: 'Mark as completed',
            statusChange: EBuyerRecommendationStatus.completed,
          },
          {
            title: 'Reset action',
            statusChange: EBuyerRecommendationStatus.active,
          },
        ];
      }
    } else {
      if (suggestionItem.status !== EBuyerRecommendationStatus.pinned) {
        options = initialAlertRecoOptions;
      } else {
        if (!activeAlertRecoExists && suggestionItem.createdAt === latestAlertRecoDate) {
          options = [
            {
              title: 'Unpin',
              statusChange: EBuyerRecommendationStatus.active,
            },
          ];
        } else {
          options = [
            {
              title: 'Unpin',
              statusChange: EBuyerRecommendationStatus.history,
            },
          ];
        }
      }
    }

    return options;
  }, [
    suggestionItem,
    activeActionRecoExists,
    latestActionRecoDate,
    activeAlertRecoExists,
    latestAlertRecoDate,
  ]);

  return (
    <div ref={refClickOutside} className="relative">
      {!!optionData.length && (
        <>
          <button onClick={() => setIsOpen(true)} className="h-max" type="button">
            <MoreVertIcon />
          </button>
          <Dropdown isOpen={isOpen} className="border rounded-md">
            {optionData.map((optionItem, index) => (
              <button
                key={`optionItem-${index}`}
                onClick={() => {
                  handleSuggestionChange({
                    status: optionItem.statusChange,
                    id: suggestionItem.id,
                  });
                  setIsOpen(false);
                }}
                className={buttonClassName}
                type="button"
              >
                {optionItem.title}
              </button>
            ))}
          </Dropdown>
        </>
      )}
    </div>
  );
};

const RecommendationCard = ({
  item,
  activeActionRecoExists,
  activeAlertRecoExists,
  latestActionRecoDate,
  latestAlertRecoDate,
}: {
  item: SuggestionItem & {
    status: SuggestionStatus;
  };
  activeActionRecoExists: boolean;
  activeAlertRecoExists: boolean;
  latestActionRecoDate?: Date;
  latestAlertRecoDate?: Date;
}) => {
  const { title, type, description, createdAt, status, linkUrl, linkLabel } = item;
  const daysAgo =
    Number(new Date(createdAt).toLocaleDateString('en-US', { day: 'numeric' })) -
    Number(new Date().toLocaleDateString('en-US', { day: 'numeric' }));

  const { handleSuggestionChange } = useSuggestions();

  const actionsDisabled = status !== EBuyerRecommendationStatus.active;

  return (
    <div className="border border-[#C5C5C5] rounded-2xl p-6 mt-6">
      {/* <div className="flex flex-row items-center">
        <h4 className="text-[15px]">Gap: </h4>
        <h4 className="border  bg-yellow-munsell-1000 rounded-[10px] py-[5px] px-2.5 text-[15px] ml-2 text-cornflower-blue-50 capitalize">
          {item.gap}
        </h4>
      </div> */}
      <div className="flex justify-between">
        <div>
          <h3 className={'font-semibold mb-1 text-[#1C1A27]'}>{title}</h3>
          <p className="flex items-center text-[#1C1A27] text-[12px] font-light">
            {daysAgo > 0 ? (
              <>
                {daysAgo} day{daysAgo > 1 ? 's' : ''} ago
              </>
            ) : (
              'Today'
            )}{' '}
            {/* <span className="mx-[10px] text-[#1C1A27] text-[15px] font-semibold">•</span>{' '}
            <span
              className={clsx({
                'text-[#FFC235]': type === EBuyerRecommendationType.action,
                'text-[#FF433D]': type === EBuyerRecommendationType.alert,
              })}
            >
              {recommendationTypeLabels[type]}
            </span> */}
          </p>
        </div>
        <RecommendationOptions
          {...item}
          status={status}
          actionable={true}
          activeActionRecoExists={activeActionRecoExists}
          activeAlertRecoExists={activeAlertRecoExists}
          latestActionRecoDate={latestActionRecoDate}
          latestAlertRecoDate={latestAlertRecoDate}
        />
      </div>
      <p className="mt-3 empty:mt-0 text-[12px] font-normal">{description}</p>
      {item.type === EBuyerRecommendationType.action && (
        <div className="flex justify-start mt-[10px] gap-3 flex-wrap">
          <div className="border border-[#C5C5C5] my-2 p-2 rounded-md flex flex-row justify-center items-center gap-2 w-fit">
            <Image
              alt="badge img"
              width={18}
              height={18}
              className="filter brightness-90 opacity-50"
              src="/icons/pocket.png"
            />
            <h4 className="text-[12px] font-semibold text-black-900 ">{item.benefit}</h4>
          </div>
          <Button
            variant="text"
            className={clsx({
              'pointer-events-none': actionsDisabled,
              '!text-[#635E7D] opacity-50': actionsDisabled,
            })}
            onClick={() => {
              handleSuggestionChange({
                status: EBuyerRecommendationStatus.in_progress,
                id: item.id,
              });
              linkUrl && window.open(linkUrl, '_blank');
            }}
            style={{
              borderColor: '#2CE25D',
              color: '#2CE25D',
              borderRadius: '35px',
              lineHeight: '1.5',
            }}
            sx={{
              '&:hover': {
                borderColor: '#2CE25D',
                backgroundColor: 'rgba(44, 226, 93, 0.1)',
              },
            }}
          >
            Accept
          </Button>
          <Button
            variant="text"
            className={clsx('ml-[20px]', {
              'pointer-events-none': actionsDisabled,
              '!text-[#635E7D] opacity-50': actionsDisabled,
            })}
            onClick={() => {
              handleSuggestionChange({
                status: EBuyerRecommendationStatus.dismissed,
                id: item.id,
              });
            }}
            style={{
              borderColor: '#F7210F',
              color: '#F7210F',
              borderRadius: '35px',
            }}
            sx={{
              '&:hover': {
                borderColor: '#F7210F',
                backgroundColor: 'rgba(247, 33, 15, 0.1)',
              },
            }}
          >
            Decline
          </Button>
          <Button
            variant="text"
            className={clsx('ml-[20px]', {
              'pointer-events-none': actionsDisabled,
              '!text-[#635E7D] opacity-50': actionsDisabled,
            })}
            onClick={() => {
              handleSuggestionChange({
                status: EBuyerRecommendationStatus.deferred,
                id: item.id,
              });
            }}
            style={{
              borderColor: '#696767',
              color: '#696767',
              borderRadius: '35px',
            }}
            sx={{
              '&:hover': {
                borderColor: '#696767',
                backgroundColor: 'rgba(105, 103, 103, 0.1)',
              },
            }}
          >
            Defer
          </Button>
        </div>
      )}
    </div>
  );
};

const RecommendationSection = ({
  icon,
  text,
  items = [],
  activeActionRecoExists,
  activeAlertRecoExists,
  latestActionRecoDate,
  latestAlertRecoDate,
}: {
  icon: ReactNode;
  text: string;
  items?: SuggestionItem[];
  activeActionRecoExists: boolean;
  activeAlertRecoExists: boolean;
  latestActionRecoDate?: Date;
  latestAlertRecoDate?: Date;
}) => {
  const [open, setOpen] = useState(true);

  return (
    <div>
      <button
        className="flex justify-between text-[#1C1A27] w-full"
        onClick={() => setOpen((prevState) => !prevState)}
      >
        <div className="flex items-center">
          {icon}
          <div className="text-[15px] ml-[10px]">
            {text} ({items.length})
          </div>
        </div>
        <ChevronUp
          className={clsx('transition-transform', {
            'rotate-0': open,
            'rotate-180': !open,
          })}
          width={20}
        />
      </button>
      {open && (
        <div>
          {items.map((item, index) => (
            <RecommendationCard
              key={`rec-card-${index}`}
              item={item}
              activeActionRecoExists={activeActionRecoExists}
              activeAlertRecoExists={activeAlertRecoExists}
              latestActionRecoDate={latestActionRecoDate}
              latestAlertRecoDate={latestAlertRecoDate}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export const Suggestions = () => {
  const [tab, setTab] = useState<'action' | 'alert' | 'view_all'>('action');
  const [sort, setSort] = useState<'newest-to-oldest' | 'oldest-to-newest'>('newest-to-oldest');
  const [filter, setFilter] = useState<string[]>(initialActionFilter);
  const [suggestions, setSuggestions] = useState<{ [k: string]: BuyerRecommendation[] }>({});

  const { suggestions: suggestionsState } = useSuggestions();
   


  const sortAndFilterSuggestions = () => {
    const newSuggestions = {
      active: suggestionsState.active.filter((suggestion) => suggestion.type === tab),
      in_progress: suggestionsState.in_progress.filter((suggestion) => suggestion.type === tab),
      completed: suggestionsState.completed.filter((suggestion) => suggestion.type === tab),
      deferred: suggestionsState.deferred.filter((suggestion) => suggestion.type === tab),
      dismissed: suggestionsState.dismissed.filter((suggestion) => suggestion.type === tab),
      pinned: suggestionsState.pinned.filter((suggestion) => suggestion.type === tab),
      history: suggestionsState.history.filter((suggestion) => suggestion.type === tab),
    };

    const sortedSuggestions = Object.fromEntries(
      Object.entries(
        Object.fromEntries(
          Object.entries(newSuggestions).filter(([key, value]) =>
            filter.includes(key as SuggestionStatus),
          ),
        ) as Record<keyof SuggestionItem, SuggestionItem[]>,
      ).map(([key, value]) => {
        return [
          key,
          value.sort((suggestionA, suggestionB) =>
            sort === 'newest-to-oldest'
              ? new Date(suggestionA.createdAt).getTime() -
                new Date(suggestionB.createdAt).getTime()
              : new Date(suggestionB.createdAt).getTime() -
                new Date(suggestionA.createdAt).getTime(),
          ),
        ];
      }),
    );

    setSuggestions(sortedSuggestions);
  };

  useEffect(() => {
    const newFilter = tab === 'action' ? [...initialActionFilter] : [...initialAlertFilter];
    setFilter(newFilter);
  }, [tab]);

  useEffect(() => {
    sortAndFilterSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, sort, suggestionsState]);

  const activeActionRecoExists = useMemo(
    () => suggestionsState.active.find((activeSuggestion) => activeSuggestion.type === 'action'),
    [suggestionsState],
  );

  const activeAlertRecoExists = useMemo(
    () => suggestionsState.active.find((activeSuggestion) => activeSuggestion.type === 'alert'),
    [suggestionsState],
  );

  const latestActionRecoDate = useMemo(() => {
    let allActionRecos = suggestionsState.all.filter((suggestion) => suggestion.type === 'action');

    allActionRecos = allActionRecos.sort(
      (suggestionA, suggestionB) =>
        new Date(suggestionA.createdAt).getTime() - new Date(suggestionB.createdAt).getTime(),
    );

    return allActionRecos[0]?.createdAt;
  }, [suggestionsState]);

  const latestAlertRecoDate = useMemo(() => {
    let allAlertRecos = suggestionsState.all.filter((suggestion) => suggestion.type === 'alert');

    allAlertRecos = allAlertRecos.sort(
      (suggestionA, suggestionB) =>
        new Date(suggestionA.createdAt).getTime() - new Date(suggestionB.createdAt).getTime(),
    );

    return allAlertRecos[0]?.createdAt;
  }, [suggestionsState]);

  const [currentModal, setCurrentModal] = useState(null);
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const refClickOutside = useRef<HTMLDivElement>(null);

  useClickOutside(refClickOutside, () => {
    if (isDesktop) {
      setCurrentModal(null);
    }
  });

  const sortOptions = (
    <>
      <ControlledRadio
        onChange={() => {
          setSort('newest-to-oldest');
        }}
        checked={sort === 'newest-to-oldest'}
        label="Newest to oldest"
      />
      <ControlledRadio
        onChange={() => {
          setSort('oldest-to-newest');
        }}
        checked={sort === 'oldest-to-newest'}
        label="Oldest to newest"
      />
    </>
  );

  const filterOptions = (tab === 'action' ? initialActionFilter : initialAlertFilter).map(
    (suggestionStatus) => (
      <ControlledCheckbox
        key={suggestionStatus}
        onChange={() => {
          setFilter((s) =>
            s.includes(suggestionStatus)
              ? s.filter((suggestion) => suggestion !== suggestionStatus)
              : [...s, suggestionStatus],
          );
        }}
        checked={filter.includes(suggestionStatus)}
        label={capitalize(suggestionStatus === 'active' ? 'available' : suggestionStatus)}
      />
    ),
  );

  const recommendationsExist = useMemo(() => {
    if (tab === 'action') {
      return !!(
        suggestions.active?.length ||
        suggestions.in_progress?.length ||
        suggestions.completed?.length ||
        suggestions.deferred?.length ||
        suggestions.dismissed?.length
      );
    }

    // otherwise we check what needs to exist for tab='alert'
    return !!(
      suggestions.active?.length ||
      suggestions.pinned?.length ||
      suggestions.history?.length
    );
  }, [suggestions, tab]);

  const router = useRouter();

  return (
    <>
      <div>
        <div className="px-4">
          <div id="step2" className="flex justify-between items-center">
            <h1
              className="text-[16px] font-md leading-5 flex items-center"
              style={{ fontFamily: 'PlusJakartaSans', fontWeight: 600 }}
            >
              To-do List
              <span className="ml-2 rounded-full w-6 h-6 flex items-center justify-center border border-[#C5C5C5]">
                {(suggestions.active?.length || 0) +
                  (suggestions.in_progress?.length || 0) +
                  (suggestions.deferred?.length || 0)}
              </span>
            </h1>
            <div
              className="flex bg-transparent py-2 px-6 justify-between items-center gap-4 rounded-full"
              style={{ border: '1px solid #EBEDEF' }}
              ref={refClickOutside}
            >
              <div className="flex w-full justify-center items-center relative">
                <div
                  className=" text-transparent cursor-pointer"
                  onClick={() => setCurrentModal('sort')}
                >
                  <ChevronsLeftRight />
                </div>
                <Dropdown
                  className="min-w-[220px] !hidden lg:!flex text-[#1C1A27]"
                  isOpen={currentModal === 'sort'}
                >
                  <div className="flex items-center justify-between px-5 pt-5">
                    <h6 className="font-medium">Sort by</h6>
                  </div>

                  <div className="flex flex-col gap-5 p-5">{sortOptions}</div>
                </Dropdown>
              </div>

              <div className="flex w-full justify-center items-center relative">
                <div
                  className=" text-transparent cursor-pointer"
                  onClick={() => setCurrentModal('filter')}
                >
                  <SlidersHorizontal />
                </div>
                <Dropdown
                  className="min-w-[220px] !hidden lg:!flex text-[#1C1A27]"
                  isOpen={currentModal === 'filter'}
                >
                  <div className="flex items-center justify-between px-5 pt-5">
                    <h6 className="font-medium">Filter</h6>
                  </div>

                  <div className="flex flex-col gap-5 p-5">{filterOptions}</div>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className="flex w-[176px] justify-between gap-2 my-4">
            <FilterPill
              text="Action"
              active={tab === EBuyerRecommendationType.action}
              onClick={() => setTab(EBuyerRecommendationType.action)}
            />
            <FilterPill
              text="Attention"
              active={tab === EBuyerRecommendationType.alert}
              onClick={() => setTab(EBuyerRecommendationType.alert)}
            />
          </div>

          <hr />
        </div>
        <div className="mt-3">
          {recommendationsExist ? (
            <>
              {recommendationSectionData
                .filter(({ itemKey }) => suggestions[itemKey]?.length)
                .map(({ icon, text, itemKey }, index) => (
                  <React.Fragment key={`recc-section-${index}`}>
                    {!!index && <div className="bg-[#D0CAE9] h-px my-[30px]" />}
                    <RecommendationSection
                      icon={icon}
                      text={text}
                      items={suggestions[itemKey]}
                      activeActionRecoExists={activeActionRecoExists}
                      activeAlertRecoExists={activeAlertRecoExists}
                      latestActionRecoDate={latestActionRecoDate}
                      latestAlertRecoDate={latestAlertRecoDate}
                    />
                  </React.Fragment>
                ))}
            </>
          ) : (
            <Empty
              main="No recommendations available"
              flavor={
                <>
                  Hang on... that&apos;s a good thing! <br /> You deserve a break.
                </>
              }
            />
          )}
          <button
            id="history-button"
            className="w-28 border flex justify-center mt-5 items-center gap-2 border-[#222B36] rounded-full text-[#3C3C3C] py-2 gap font-medium text-sm"
            onClick={() => router.push('/user/summary/reco-history')}
          >
            <Image src="/images/history.png" alt="history" width={20} height={20} />
            <p>History</p>
          </button>
        </div>
      </div>
      {!isDesktop && (
        <CleanModal
          onClose={() => {
            setCurrentModal(null);
          }}
          panelClassName="!rounded-none !rounded-t-lg !p-0"
          wrapperClassName="!p-0 !items-end"
          open={currentModal === 'sort'}
        >
          <div className="flex items-center justify-between px-6 py-4 border-b border-b-gray-200">
            <h6 className="text-gray-900 font-medium">Sort</h6>
            <button
              className="block ml-auto dark:text-white"
              onClick={() => setCurrentModal(null)}
              type="button"
            >
              <CloseIcon width={20} strokeWidth={2} className="keep-color" />
            </button>
          </div>

          <div className="flex flex-col gap-6 p-6 pb-0 mb-12">{sortOptions}</div>
        </CleanModal>
      )}
      {!isDesktop && (
        <CleanModal
          onClose={() => {
            setCurrentModal(null);
          }}
          panelClassName="!rounded-none !rounded-t-lg !p-0"
          wrapperClassName="!p-0 !items-end"
          open={currentModal === 'filter'}
        >
          <div className="flex items-center justify-between px-6 py-4 border-b border-b-gray-200">
            <h6 className="text-gray-900 font-medium">Filter</h6>
            <button
              className="block ml-auto dark:text-white"
              onClick={() => setCurrentModal(null)}
              type="button"
            >
              <CloseIcon width={20} strokeWidth={2} className="keep-color" />
            </button>
          </div>

          <div className="flex flex-col gap-6 p-6 pb-0 mb-12">{filterOptions}</div>
        </CleanModal>
      )}
    </>
  );
};
