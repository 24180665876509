import clsx from 'clsx';
import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';

export const Checkbox = ({
  label,
  validations,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  validations?: Record<string, unknown>;
}) => {
  const { register } = useFormContext();
  return (
    <label
      className={clsx('flex items-center gap-3 text-gray-800 dark:text-gray-100 cursor-pointer', {
        'text-gray-300': props.disabled,
      })}
      key={props.name}
    >
      <input
        className="rounded border-2 border-gray-300 bg-transparent text-primary cursor-pointer"
        type="checkbox"
        name={props.name}
        {...register(props.name, validations)}
        {...props}
      />
      <span className="text-sm font-medium">{label}</span>
    </label>
  );
};

export const ControlledCheckbox = ({
  label,
  ...props
}: {
  label: string;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {
  return (
    <label className="flex items-center gap-3 text-gray-800 dark:text-gray-100 cursor-pointer">
      <input
        className="rounded border-2 border-gray-300 bg-transparent text-[#1DAB37] cursor-pointer"
        type="checkbox"
        {...props}
      />
      <span className="text-sm font-normal">{label}</span>
    </label>
  );
};
