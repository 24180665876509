import { Select } from '../select';

export const Gender = (props) => {
  return (
    <Select
      showRequired={false}
      name="gender"
      options={[
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Transgender', value: 'transgender' },
        { label: 'Non Binary', value: 'non_binary' },
        { label: 'Prefer Not To Say', value: 'prefer_not_to_say' },
      ]}
      label="Sex"
      {...props}
    />
  );
};
