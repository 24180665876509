import Image from 'next/image';
import { ReactNode } from 'react';
import EmptyImage from '../../../public/images/empty.png';

export const Empty = ({
  main,
  flavor,
  hideImage,
}: {
  main: ReactNode;
  flavor: ReactNode;
  hideImage?: boolean;
}) => {
  return (
    <div className="text-center justify-center min-h-[400px] bpt-6 -mt-2 px-4 flex flex-col items-center  mx-6 lg:mx-0">
       {!hideImage && (
        <div className="max-w-[400px] flex mb-2">
          <Image src="/images/layers.png" alt="layer" width={50} height={50} />
        </div>
      )}
      <p className="text-center font-plusJakarta text-[13px] text-[#696767] leading-[12px] font-medium mb-1">{main}</p>
      <p className="text-center text-[13px] font-plusJakarta text-[#696767] leading-5 font-medium">{flavor}</p>
     
    </div>
  );
};
