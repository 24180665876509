import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Select } from '../select';

export const amountOptions = [
  { value: 6, label: 'Immediately (3-6 months)' },
  { value: 12, label: 'Soon (6-12 months)' },
  { value: 24, label: 'Later (12-24 months)' },
  { value: 60, label: 'Future (24+ months)' },
];

export const useAnotherAmount = create(
  persist<{
    isAnotherAmount?: boolean;
    setIsAnotherAmount: (isAnotherAmount: boolean) => void;
    otherAmountSelection: 'years' | 'months';
    setOtherAmountSelection: (otherAmountSelection: 'years' | 'months') => void;
  }>(
    (set) => ({
      isAnotherAmount: false,
      setIsAnotherAmount: (isAnotherAmount: boolean) => set({ isAnotherAmount }),
      setOtherAmountSelection: (otherAmountSelection: 'years' | 'months') =>
        set({ otherAmountSelection }),
      otherAmountSelection: 'months',
    }),
    { name: 'another-amount' },
  ),
);

const TimePill = ({
  label,
  isActive,
  onClick,
  ...buttonProps
}: {
  label: string;
  isActive: boolean;
  onClick: () => void;
} & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      type="button"
      className={clsx('font-medium rounded-full h-[32px] text-sm  px-3', {
        'bg-primary-500 text-white cursor-default': isActive,
        'bg-gray-200 hover:bg-gray-300 text-gray-600': !isActive,
      })}
      {...buttonProps}
      onClick={() => {
        onClick();
      }}
    >
      {label}
    </button>
  );
};

export const TimePills = ({
  showLabel = true,
  alternate,
  showRequired = true,
}: {
  showLabel?: boolean;
  alternate?: boolean;
  showRequired?: boolean;
}) => {
  // const methods = useFormContext();
  // const currentExpectedPurchaseMonthsPeriod = methods.watch('expectedPurchaseMonthsPeriod');
  // const { isAnotherAmount, setIsAnotherAmount, setOtherAmountSelection } = useAnotherAmount();
  return (
    <>
      {/* {amountOptions.map((option) => (
        <TimePill
          key={option.value}
          label={option.label}
          isActive={option.value === currentExpectedPurchaseMonthsPeriod && !isAnotherAmount}
          onClick={() => {
            // setIsAnotherAmount(false);
            // if (option.label.includes('month')) {
            //   setOtherAmountSelection('months');
            // } else {
            //   setOtherAmountSelection('years');
            // }
            methods.setValue('expectedPurchaseMonthsPeriod', option.value);
          }}
        />
      ))} */}
      {/* <TimePill
        label="Another amount"
        isActive={isAnotherAmount}
        onClick={() => {
          setIsAnotherAmount(true);
          methods.setValue('expectedPurchaseMonthsPeriod', null);
        }}
      /> */}
      <Select
        name="purchaseTime"
        options={amountOptions}
        showLabel={showLabel}
        alternate={alternate}
        label="When do you hope to purchase a home"
        placeholder="Select"
        showRequired={showRequired}
      />
    </>
  );
};
