import { useIsMounted } from '@hooks';
import { ReactElement } from 'react';

export const Switch = ({
  test,
  children,
}: {
  test: number;
  children: {
    props: {
      value: number | number[];
    };
  }[];
}) => {
  const stepToRender = children?.find((child) => {
    const props = child.props.value;

    return Array.isArray(props)
      ? props.some((value) => value === test)
      : props === test;
  });

  if (!stepToRender) {
    throw new Error(
      `The component of step ${test} wasn't rendered successfuly, check if there's really a component rendering on this step.`
    );
  }

  const isMounted = useIsMounted();
  return isMounted && ((<>{stepToRender}</>) as ReactElement);
};
