import { useAuthUser } from '@hooks';
import { commonRoutes } from '@utils';
import { BuyerLayout } from '@components/layout/buyer-layout';
import { ReactNode } from 'react';

export const SettingsDesktopLayout = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: any;
}) => {
  const { profile } = useAuthUser();

  return (
    <BuyerLayout
      logoLink={commonRoutes.buyer.auth.summary}
      showNeedHelp
      mainClassName="h-full lg:h-[calc(100%-85px)] lg:!pb-32 "
      headerContainerClassName={'undefined'}
    >
      <div className="flex">
        <div className="w-full bg-white flex">
          <div
            className={
              className ? className : 'w-full max-w-[640px] ml-auto mr-auto lg:ml-[100px] lg:mr-0'
            }
          >
            {children}
          </div>
        </div>
      </div>
    </BuyerLayout>
  );
};
