export const CheckFilledIcon = ({
  backgroundColor = 'currentColor',
  pathColor = '#fff',
  width = 15,
}: {
  backgroundColor?: string;
  pathColor?: string;
  width?: number;
}) => (
  <svg viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" width={width}>
    <circle
      cx={11}
      cy={11.5}
      r={10}
      fill={backgroundColor}
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path d="m6 11.5 3.33 3.75L16 7.75" fill="currentColor" />
    <path
      d="m6 11.5 3.33 3.75L16 7.75"
      stroke={pathColor}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
