import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import mixpanel from 'mixpanel-browser';





const SESSION_ACTIVE = 'session_active';

export const useKeepMixpanelSessionAlive = (user) => {
  const sessionTrackingId = useMemo(() => (Math.random() + 1).toString(36).substring(7), []);
  useEffect(() => {
    const currentUrl = window.location.href;
  
    if (currentUrl.includes('dev')) {
      console.log('This is the development environment');
    } else if (currentUrl.includes('staging')) {
      console.log('This is the staging environment');
    } else {
      console.log('This is the production environment');
      mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN);
    }
  }, []);
  const sendActivityTrackingEvent = () => {
    if (
      !localStorage.getItem('sessionTrackingId') ||
      !localStorage.getItem('lastSessionUpdateDate') ||
      // So if more than 6mins have past since last session update
      // most likely the previous opened tab has been closed and the current one can pick up the slack
      dayjs().diff(dayjs(localStorage.getItem('lastSessionUpdateDate')), 'minutes') > 6
    ) {
      localStorage.setItem('sessionTrackingId', sessionTrackingId);
    }

    // and if the current sessionTrackingId is equal to the current tabs sessionTrackingId
    // this tab can be sending out the keep session alive events
    if (localStorage.getItem('sessionTrackingId') === sessionTrackingId) {
      localStorage.setItem('lastSessionUpdateDate', dayjs().toISOString());
      if (user?.email) {
        mixpanel.track(SESSION_ACTIVE, { distinct_id: user.email });
      }
    }
  };

  useEffect(() => {
    let interval;

    if (user.signedIn) {
      // 5minutes - 300000ms
      interval = setInterval(sendActivityTrackingEvent, 300000);
    } else {
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [user.signedIn]);
};
