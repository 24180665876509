import { apiRequestClient } from "../client";
import { ListItemRecord } from "../types";

/**
 * Searches for skills and returns a list of matching skills
 */
export const searchSkills = (str?: string) => {
  return apiRequestClient()
    .method("get")
    .append("resources/skills" + (str && `?search=${str}`))
    .build<{ total: number; items: ListItemRecord[] }>();
};

/**
 * Searches for industries and returns a list of matching skills
 */
export const searchIndustries = (str?: string) => {
  return apiRequestClient()
    .method("get")
    .append("resources/industries" + (str && `?search=${str}`))
    .build<{ total: number; items: ListItemRecord[] }>();
};
