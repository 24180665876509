import { ErrorMessage, Steps } from '@components';
import { AxiosRequestConfig } from 'axios';
import clsx from 'clsx';
import React, { Dispatch, SetStateAction, useRef } from 'react';
// @ts-ignore

const BACKSPACE_KEY = 'Backspace';
const LEFT_KEY = 'ArrowLeft';
const RIGHT_KEY = 'ArrowRight';
const CODE_LENGTH = 5;

export interface CustomCodeInputProps {
  onCodeResent: () => void;
  error?: AxiosRequestConfig;
  sendCodeInfo?: boolean;
  disable?: boolean;
  phoneNumber?: string;
  email?: string;
  code?: number[];
  setCode: Dispatch<SetStateAction<number[]>>;
}

export const CustomCodeInput = ({
  error,
  onCodeResent,
  disable,
  phoneNumber,
  email,
  sendCodeInfo,
  code,
  setCode,
}: CustomCodeInputProps) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (e.target.value.length > 1) return;

    // Note: this is a specific fix
    // for android + chrome as apperantly android + chrome does not
    // fire the "backspace" event, which would handle this instead.
    // So we check if an empty string would be passed in and we can
    // assume that the user tried "emptying" that space
    if (e.target.value === '') {
      updateCode(null, index);
      changeInputFocus(index - 1);
    } else {
      const value = Number(e.target.value);
      if (!isNaN(value)) {
        updateCode(value, index);
        if (index < CODE_LENGTH - 1) {
          changeInputFocus(index + 1);
        }
      }
    }
  };

  const inputRefs = useRef<HTMLInputElement[]>([]);

  const changeInputFocus = (index: number) => {
    const ref = inputRefs.current[index];
    if (ref) {
      ref.focus();
    }
  };

  const updateCode = (value: number | null, index: number) => {
    const temp = [...code];
    temp[index] = value;
    setCode([...temp]);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const key = event.nativeEvent.code;

    switch (key) {
      case BACKSPACE_KEY:
        event.preventDefault();
        updateCode(null, index);
        changeInputFocus(index - 1);
        break;
      case RIGHT_KEY:
        changeInputFocus(index + 1);
        break;
      case LEFT_KEY:
        changeInputFocus(index - 1);
        break;

      default:
        return;
    }
  };

  const onPasteNumber = (e: React.ClipboardEvent) => {
    const pasteValue = e.clipboardData.getData('Text');
    if (pasteValue.length === CODE_LENGTH && !isNaN(Number(pasteValue))) {
      setCode([...pasteValue.split('').map((e: string) => Number(e))]);
    }
  };

  return (
    <div className="mb-4">
      <div className=" xs:pl-75 lg:pl-3 ">
        <section className="w-fit mx-auto flex gap-3 lg:gap-4  xs:gap-3">
          {new Array(CODE_LENGTH).fill(0).map((_, i) => (
            <input
              className={clsx(
                'text-xl p-0 text-center  sm:w-16 md:w-16 h-16 text-gray-900 font-semibold border-0 outline outline-1 dark:outline-gray-800 rounded hover:!outline-2 dark:hover:outline-primary  hover:outline-primary dark:focus:outline-primary focus:outline-primary bg-transparent dark:border-black-dark dark:text-white aspect-1 w-full dark:active:outline-primary active:outline-primary hover:outline-offset-2 border-transparent focus:border-transparent xs:w-[45px] focus:ring-0',
                {
                  'outline-red': error,
                  'outline-gray': !error,
                },
              )}
              key={i}
              disabled={disable}
              type="number"
              pattern="[0-9]*"
              ref={(el) => {
                if (el) {
                  inputRefs.current[i] = el;
                }
              }}
              onKeyDown={(e) => onKeyDown(e, i)}
              value={code[i] ?? ''}
              onChange={(e) => onChange(e, i)}
              onPaste={onPasteNumber}
            />
          ))}
        </section>
      </div>

      {error && (
        <ErrorMessage
          className="w-[max-content] block"
          error={
            error?.data?.statusCode === 400
              ? error?.data?.message || 'Invalid code'
              : error?.data?.statusCode === 500
              ? undefined
              : error?.data?.message
          }
        />
      )}
      {!disable && <Steps.ResendCodeButton onCodeResent={onCodeResent} />}
      <style>
        {`
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          `}
      </style>
    </div>
  );
};
