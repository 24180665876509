import { commonRoutes } from '@utils';
import { Button } from '@components/button';
import { Modal } from '@components/modal';
import CloseIcon from '@icons/close.svg';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import { useIntroduction } from '@components/introduction';
import { useMediaQuery } from 'react-responsive';
import { useIsMounted } from '@hooks';
import { create } from 'zustand';
import { useState, useEffect } from 'react';
export const useOpenTermsModal = create<{
  openTermsModal: boolean;
  setOpenTermsModal: (openTermsModal: boolean) => void;
}>((set) => ({
  openTermsModal: false,
  setOpenTermsModal: (openTermsModal) => set({ openTermsModal }),
}));

export const TermsModal = () => {
  const { openTermsModal, setOpenTermsModal } = useOpenTermsModal();
  const [bottom, setBottom] = useState(true);

  const intl = useIntl();
  const router = useRouter();
  const query = router.isReady && router.query;

  const { setIntroduction } = useIntroduction();
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isMounted = useIsMounted();

  //   const handleScroll = (e) => {
  //     const bottomcheck = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  //     if(bottomcheck===true){
  //       setBottom(false)
  //     }
  // };
  let btn_class: string = bottom ? 'primary' : 'white';
  useEffect(() => {
    const timer1 = setTimeout(() => setBottom(false), 3000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);
  return (
    isMounted && (
      <>
        {isDesktop ? (
          <Modal
            wrapperClassName="flex min-h-[100%] items-center justify-center text-center"
            panelClassName="w-full lg:max-w-xl py-6 text-left h-full rounded-lg"
            onClose={() => setOpenTermsModal(false)}
            open={openTermsModal}
          >
            <div className="flex justify-between px-6 pb-6 mb-6 border-b border-b-gray-300">
              <h2 className="font-medium text-2xl font-slab text-gray-900">
                Before we continue, please read our terms
              </h2>{' '}
              <button
                className="block ml-auto dark:text-white"
                onClick={() => setOpenTermsModal(false)}
                type="button"
              >
                <CloseIcon strokeWidth={2} width={20} />
              </button>
            </div>
            <div className='relative after:content-[""] px-6'>
              <div className="text-xs flex flex-col gap-5 overflow-auto max-h-[285px] pb-6">
                <p>{intl.formatMessage({ id: 'terms.last-updated' })}</p>
                <p>{intl.formatMessage({ id: 'terms.pre-intro' })}</p>
                <p>{intl.formatMessage({ id: 'terms.intro-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.intro-two' })}</p>
                <p>{intl.formatMessage({ id: 'terms.statement-of-courage-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.statement-of-courage' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Information-Collection-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.general-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.general' })}</p>
                <p>{intl.formatMessage({ id: 'terms.general-two' })}</p>
                <p>{intl.formatMessage({ id: 'terms.registeration-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.registeration' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Account-Deletion-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Account-Deletion' })}</p>
                <p>{intl.formatMessage({ id: 'terms.updating-your-personal-info-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.updating-your-personal-info' })}</p>
                <p>{intl.formatMessage({ id: 'terms.transactions-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.transactions' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Site-Access-Activity-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Site-Access-Activity' })}</p>
                <p>{intl.formatMessage({ id: 'terms.public-content-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.public-content' })}</p>
                <p>{intl.formatMessage({ id: 'terms.location-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.location' })}</p>
                <p>{intl.formatMessage({ id: 'terms.messages-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.messages' })}</p>
                <p>{intl.formatMessage({ id: 'terms.mobile-application-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.mobile-application' })}</p>
                <p>{intl.formatMessage({ id: 'terms.other-sites-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.other-sites' })}</p>
                <p>
                  {intl.formatMessage({ id: 'terms.info-provided-by-third-parties-about-you-h' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.info-provided-by-third-parties-about-you' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.info-provided-to-third-parties-about-you-h' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.info-provided-to-third-parties-about-you' })}
                </p>
                <p>{intl.formatMessage({ id: 'terms.info-you provide-about-a-third-party-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.info-you provide-about-a-third-party' })}</p>
                <p>
                  {intl.formatMessage({ id: 'terms.info-you provide-about-a-third-party-two' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.info-you provide-about-a-third-party-three' })}
                </p>
                <p>{intl.formatMessage({ id: 'terms.customer-service-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.customer-service' })}</p>
                <p>{intl.formatMessage({ id: 'terms.children-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.children' })}</p>
                <p>{intl.formatMessage({ id: 'terms.data-protection-and-security-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.data-protection-and-security' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information' })}</p>
                <ul className="list-disc pl-4">
                  <li>
                    {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-one' })}
                  </li>
                  <li>
                    {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-two' })}
                  </li>
                  <li>
                    {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-three' })}
                  </li>
                  <li>
                    {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-four' })}
                  </li>
                </ul>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-five' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-six' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-seven' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-eight' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-nine' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-ten' })}</p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-eleven' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-twelve' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-thirteen' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-fourteen' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-fifteen' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-sixteen' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-seventeen' })}
                </p>
                <p>{intl.formatMessage({ id: 'terms.Safeguarding-Communications-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Safeguarding-Communications' })}</p>
                <p>{intl.formatMessage({ id: 'terms.cookies-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.cookies' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-two' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-three' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-four' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-five' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-six' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-seven' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Data-Rentetion-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Data-Rentetion' })}</p>
                <p>{intl.formatMessage({ id: 'terms.changes-to-this-privacy-policy-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.changes-to-this-privacy-policy' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Questions-Suggestions-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Questions-Suggestions' })}</p>
                <ul>
                  <li>{intl.formatMessage({ id: 'terms.about-to' })}</li>
                  <li>{intl.formatMessage({ id: 'terms.about-to-one' })}</li>
                  <li>{intl.formatMessage({ id: 'terms.about-to-two' })}</li>
                </ul>
              </div>
            </div>
            <div className="ml-auto flex gap-4 pt-6 border-t border-t-gray-300">
              <div className="px-6 w-max ml-auto flex gap-4">
                <Button
                  className="w-max"
                  variant="white"
                  onClick={() => {
                    setOpenTermsModal(false);
                  }}
                >
                  {intl.formatMessage({ id: 'getstarted.back' })}
                </Button>{' '}
                <Button
                  className="w-max"
                  variant={bottom ? 'red' : 'primary'}
                  onClick={async () => {
                    await router.push({ pathname: commonRoutes.buyer.signup, query });
                    setIntroduction({ agreedToTerms: true });
                    setOpenTermsModal(false);
                  }}
                  disabled={bottom}
                >
                  {intl.formatMessage({ id: 'getstarted.agreeTerms' })}
                </Button>
              </div>
            </div>
          </Modal>
        ) : (
          <Modal
            wrapperClassName="flex min-h-[100%] items-center justify-center text-center"
            panelClassName="w-full lg:max-w-xl py-4 px-6 text-left h-full rounded-tl-lg rounded-tr-lg"
            containerClassName="top-[unset] bottom-0 h-[inherit] w-full max-h-[88vh] overflow-y-hidden absolute"
            onClose={() => setOpenTermsModal(false)}
            open={openTermsModal}
          >
            <button
              className="block mb-4 ml-auto dark:text-white"
              onClick={() => setOpenTermsModal(false)}
              type="button"
            >
              <CloseIcon strokeWidth={2} width={20} />
            </button>
            <h2 className="font-medium text-2xl font-slab text-gray-900 mb-6">
              Before we continue, <br /> please read our terms
            </h2>
            <div className="relative">
              <div className="text-xs flex flex-col gap-5 overflow-auto pb-9 terms-container">
                <p>{intl.formatMessage({ id: 'terms.last-updated' })}</p>
                <p>{intl.formatMessage({ id: 'terms.pre-intro' })}</p>
                <p>{intl.formatMessage({ id: 'terms.intro-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.intro-two' })}</p>
                <p>{intl.formatMessage({ id: 'terms.statement-of-courage-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.statement-of-courage' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Information-Collection-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.general-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.general' })}</p>
                <p>{intl.formatMessage({ id: 'terms.general-two' })}</p>
                <p>{intl.formatMessage({ id: 'terms.registeration-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.registeration' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Account-Deletion-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Account-Deletion' })}</p>
                <p>{intl.formatMessage({ id: 'terms.updating-your-personal-info-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.updating-your-personal-info' })}</p>
                <p>{intl.formatMessage({ id: 'terms.transactions-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.transactions' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Site-Access-Activity-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Site-Access-Activity' })}</p>
                <p>{intl.formatMessage({ id: 'terms.public-content-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.public-content' })}</p>
                <p>{intl.formatMessage({ id: 'terms.location-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.location' })}</p>
                <p>{intl.formatMessage({ id: 'terms.messages-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.messages' })}</p>
                <p>{intl.formatMessage({ id: 'terms.mobile-application-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.mobile-application' })}</p>
                <p>{intl.formatMessage({ id: 'terms.other-sites-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.other-sites' })}</p>
                <p>
                  {intl.formatMessage({ id: 'terms.info-provided-by-third-parties-about-you-h' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.info-provided-by-third-parties-about-you' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.info-provided-to-third-parties-about-you-h' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.info-provided-to-third-parties-about-you' })}
                </p>
                <p>{intl.formatMessage({ id: 'terms.info-you provide-about-a-third-party-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.info-you provide-about-a-third-party' })}</p>
                <p>
                  {intl.formatMessage({ id: 'terms.info-you provide-about-a-third-party-two' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.info-you provide-about-a-third-party-three' })}
                </p>
                <p>{intl.formatMessage({ id: 'terms.customer-service-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.customer-service' })}</p>
                <p>{intl.formatMessage({ id: 'terms.children-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.children' })}</p>
                <p>{intl.formatMessage({ id: 'terms.data-protection-and-security-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.data-protection-and-security' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information' })}</p>
                <ul className="list-disc pl-4">
                  <li>
                    {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-one' })}
                  </li>
                  <li>
                    {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-two' })}
                  </li>
                  <li>
                    {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-three' })}
                  </li>
                  <li>
                    {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-four' })}
                  </li>
                </ul>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-five' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-six' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-seven' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-eight' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-nine' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-ten' })}</p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-eleven' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-twelve' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-thirteen' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-fourteen' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-fifteen' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-sixteen' })}
                </p>
                <p>
                  {intl.formatMessage({ id: 'terms.Disclosure-sharing-of-information-seventeen' })}
                </p>
                <p>{intl.formatMessage({ id: 'terms.Safeguarding-Communications-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Safeguarding-Communications' })}</p>
                <p>{intl.formatMessage({ id: 'terms.cookies-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.cookies' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-two' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-three' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-four' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-five' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-six' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Confidentiality-Security-seven' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Data-Rentetion-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Data-Rentetion' })}</p>
                <p>{intl.formatMessage({ id: 'terms.changes-to-this-privacy-policy-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.changes-to-this-privacy-policy' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Questions-Suggestions-h' })}</p>
                <p>{intl.formatMessage({ id: 'terms.Questions-Suggestions' })}</p>
                <ul>
                  <li>{intl.formatMessage({ id: 'terms.about-to' })}</li>
                  <li>{intl.formatMessage({ id: 'terms.about-to-one' })}</li>
                  <li>{intl.formatMessage({ id: 'terms.about-to-two' })}</li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col gap-4 py-6">
              <Button
                variant={bottom ? 'red' : 'primary'}
                onClick={() => {
                  router.push({ pathname: commonRoutes.buyer.signup, query });
                }}
                disabled={bottom}
              >
                {intl.formatMessage({ id: 'getstarted.agreeTerms' })}
              </Button>
              <Button
                variant="white"
                onClick={() => {
                  setOpenTermsModal(false);
                }}
              >
                {intl.formatMessage({ id: 'getstarted.back' })}
              </Button>
            </div>
            <style jsx global>{`
              .terms-container {
                max-height: 20vh;
              }

              @media (min-height: 500px) {
                .terms-container {
                  max-height: 30vh;
                }
              }

              @media (min-height: 600px) {
                .terms-container {
                  max-height: 40vh;
                }
              }

              @media (min-height: 800px) {
                .terms-container {
                  max-height: 50vh;
                }
              }
            `}</style>
          </Modal>
        )}
      </>
    )
  );
};
