import { Select } from '../select';

export const MilitaryStatus = (props) => {
  return (
    <Select
      name="militaryStatus"
      options={[
        { label: 'Not Applicable', value: 'notApplicable' },
        { label: 'Veteran', value: 'veteran' },
        { label: 'Active Duty', value: 'active' },
      ]}
      label="Military Status"
      {...props}
    />
  );
};
