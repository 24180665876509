import clsx from 'clsx';
import { MouseEventHandler } from 'react';

export const FilterPill = ({
  text,
  active,
  onClick,
}: {
  text: string;
  active: boolean;
  onClick: MouseEventHandler;
}) => (
  <div
    className={clsx(
      'px-[14px] py-1  text-[12px] leading-4 font-plusJakarta rounded-full flex items-center justify-center cursor-pointer',
      {
        'bg-[#E7E3F4] text-[#01130F] hover:bg-[#D0CAE9] rounded': !active,
        'bg-[#1C1A27] text-white pointer-events-none': active,
      },
    )}
    onClick={onClick}
  >
    {text}
  </div>
);
