import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { PurchaseHomeTimeDropdown } from '../purchase-home-time-select';
const normalExpectedPurchaseMonthsPeriodValues = [6, 12, 24, 60, 120];

export const ExpectedPurchaseAt = () => {
  const { getValues } = useFormContext();
  const { expectedPurchaseMonthsPeriodDefault, otherAmountSelectDefault, otherAmountDefaultValue } =
    useMemo(() => {
      let expectedVal: string | number = +getValues('expectedPurchaseMonthsPeriod') || 6;
      let otherSelectionVal = 'years';
      let otherAmount = 1;

      if (!normalExpectedPurchaseMonthsPeriodValues.includes(expectedVal)) {
        if (expectedVal % 12 !== 0) {
          otherSelectionVal = 'months';
          otherAmount = expectedVal;
        } else {
          otherAmount = expectedVal / 12;
        }

        expectedVal = 'another';
      }

      return {
        expectedPurchaseMonthsPeriodDefault: expectedVal,
        otherAmountSelectDefault: otherSelectionVal,
        otherAmountDefaultValue: otherAmount,
      };
    }, [getValues]);

  return (
    <PurchaseHomeTimeDropdown
      defaultValue={expectedPurchaseMonthsPeriodDefault}
      otherAmountDefaultValue={otherAmountDefaultValue}
      otherAmountSelectionDefault={otherAmountSelectDefault}
    />
  );
};
