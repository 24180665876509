export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageView = (url: string,) => {
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,

  });
};

export const addUserId = (userId: string) => {
  if (!userId) return
  window.gtag('set', GA_TRACKING_ID, {
    user_id: userId
  })
}

export const removeUserId = () => {
  window.gtag('set', GA_TRACKING_ID, {
    user_id: ''
  })
}



// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: {
  action: Gtag.EventNames | (string & {}),
  label?: string,
  category?: string,
  value?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams
}) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    ...value,
  });
}
export const setUserProperties = ({ label, value }: { label: string, value: Record<string, any> }) => {
  window.gtag("set", 'user_properties', value);
}
export const g = {
  pageView,
  addUserId,
  event,
  setUserProperties,
}