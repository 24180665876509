import { useAuthUser, useClickOutside } from '@hooks';
import ChartLineIcon from '@icons/chart-line.svg';
import ChevronRightIcon from '@icons/chevron-right.svg';
import DocIcon from '@icons/doc.svg';
import HeadPhoneIcon from '@icons/headphone.svg';
import LogoutIcon from '@icons/logout.svg';
import PostCardIcon from '@icons/postcard.svg';
import UserIcon from '@icons/user.svg';
import { Divider } from '@components';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useRef } from 'react';
import { UserLogo } from '../user-logo';

export type UserMenuProps = {
  /**
   * Classname.
   */
  className?: string;

  /**
   * Open menu.
   */
  open: boolean;

  /**
   * User first name.
   */
  firstName: string;

  lastName: string;

  userName: string;

  /**
   * User avatar.
   */
  image?: string;

  setOpenMenu: Dispatch<SetStateAction<boolean>>;
};

export const styles = {
  base: 'py-6 flex flex-col rounded-[10px] bg-white dark:bg-black-background border border-neutral-lavender-web dark:border-black-dark shadow-md shadow-[rgba(142, 151, 234, 0.15)] min-w-[279px]',
  avatar: 'w-[42px] h-[42px] rounded-full overflow-hidden',
  icon_wrapper:
    'w-[34px] h-[34px] rounded-full flex items-center justify-center bg-neutral-lavender-web dark:bg-black-dark mr-3 shrink-0 text-blue-ribbon',
  title: 'text-sm text-black dark:text-white font-medium ',
  description: 'text-[13px] text-neutral-manatee ',
  menu_item: 'mt-6 flex items-center justify-between mx-6',
  menu_item_text: 'text-black dark:text-white text-sm ',
};

export const UserMenu = (props: UserMenuProps) => {
  const { open, firstName, lastName, userName, image, className, setOpenMenu } =
    props;
  const intl = useIntl();

  const router = useRouter();
  const { logout } = useAuthUser();

  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, () => {
    setOpenMenu(false);
  });

  return open ? (
    <div ref={ref} className={clsx(styles.base, className)}>
      <div className="flex border-b border-white-300 dark:border-black-dark pb-[10px] mx-6">
        <div className={clsx(styles.avatar)}>
          {image ? (
            <Image src={image} alt="avatar" layout="fill" />
          ) : (
            <UserLogo firstName={firstName} lastName={lastName} />
          )}
        </div>
        <div className="ml-[10px]">
          <p className="text-[12px] font-medium font-plusJakarta leading-4 text-black dark:text-white">
            {firstName} {lastName}
          </p>
          <p className="text-[12px] font-normal font-plusJakarta leading-4 text-[#696767] dark:text-white">@{userName}</p>
        </div>
      </div>
      <Link href="#">
        <a className="flex items-center px-4 py-3 mx-2 mt-3 hover:bg-neutral-ghost dark:hover:bg-[#2E2F35]">
          <div className={clsx(styles.icon_wrapper)}>
            <PostCardIcon width={20} />
          </div>
          <div>
            <p className={clsx(styles.title)}>
              {intl.formatMessage({ id: 'cmpt.user-menu.strategy' })}
            </p>
            <p className={clsx(styles.description)}>
              {intl.formatMessage({ id: 'cmpt.user-menu.strategy-desc' })}
            </p>
          </div>
        </a>
      </Link>
      <Link href="#">
        <a className="flex items-center px-4 py-3 mx-2 hover:bg-neutral-ghost dark:hover:bg-[#2E2F35]">
          <div className={clsx(styles.icon_wrapper)}>
            <ChartLineIcon width={20} />
          </div>
          <div>
            <p className={clsx(styles.title)}>
              {intl.formatMessage({ id: 'cmpt.user-menu.brokerage' })}
            </p>
            <p className={clsx(styles.description)}>
              {intl.formatMessage({ id: 'cmpt.user-menu.brokerage-desc' })}
            </p>
          </div>
        </a>
      </Link>
      <Divider className="!mx-6 mt-3" />

      <Link href="/settings/user" prefetch={false}>
        <a>
          <div className={clsx(styles.menu_item)}>
            <div className="flex items-center ">
              <UserIcon className="mr-4 text-neutral-manatee" />

              <span className={clsx(styles.menu_item_text)}>
                {intl.formatMessage({ id: 'cmpt.user-menu.settings' })}
              </span>
            </div>
            <ChevronRightIcon width={16} />
          </div>
        </a>
      </Link>

      <Link href={process.env.NEXT_PUBLIC_API_DOCS_URL} prefetch={false}>
        <a>
          <div className={clsx(styles.menu_item)}>
            <div className="flex items-center ">
              <DocIcon
                width={20}
                className="mr-4 text-neutral-manatee keep-color"
              />
              <p className={clsx(styles.menu_item_text)}>
                {intl.formatMessage({ id: 'cmpt.user-menu.api' })}
              </p>
            </div>
            <ChevronRightIcon width={16} />
          </div>
        </a>
      </Link>

      <Link href="/user/" prefetch={false}>
        <a>
          <div className={clsx(styles.menu_item)}>
            <div className="flex items-center ">
              <HeadPhoneIcon className="mr-4 text-neutral-manatee" />
              <p className={clsx(styles.menu_item_text)}>
                {intl.formatMessage({ id: 'cmpt.user-menu.support' })}
              </p>
            </div>
            <ChevronRightIcon width={16} />
          </div>
        </a>
      </Link>

      <button
        onClick={() => {
          logout();
          router.push('/user/get-started');
        }}
        className="flex items-center mx-6 mt-6 text-red-500 dark:text-red-500"
      >
        <LogoutIcon className="mr-4 stroke-red-500 keep-color" />
        <p
          className={clsx(
            styles.menu_item_text,
            'text-red-500 dark:text-red-500'
          )}
        >
          {intl.formatMessage({ id: 'general.logout' })}
        </p>
      </button>
    </div>
  ) : null;
};
