import { CustomPagination, Loading } from '@components';
import { useRef } from 'react';
import { useQuery } from 'react-query';
import { ApiService } from '@services/Api';
import { PastRangeOptions } from '@services/Api/controllers';
import { TransactionHistoryFilters } from './transaction-history-filters';
import { TransactionItem } from './transaction-item';

const PAGE_SIZE = 8;

export const TransactionHistory = () => {
  const page = useRef(1);
  const rangeFilter = useRef<PastRangeOptions>('pastWeek');

  const { data: transactions, refetch } = useQuery(
    ['buyer-plaid-items-transactions'],
    async () => {
      const resp = await ApiService.getBuyerPlaidTransactions({
        page: page.current,
        pageSize: PAGE_SIZE,
        pastRange: rangeFilter.current,
      });

      return {
        ...resp.data,
        items: resp.data.items.map((transactionItem) => {
          const customDate = new Date(transactionItem.date);

          return {
            ...transactionItem,
            amount: transactionItem.amount * -1,
            date: customDate.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            }),
          };
        }),
      };
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  );

  const onChangePage = ({ selected }) => {
    page.current = selected + 1;
    refetch();
  };

  const onRangeFilterChange = (selectedFilter) => {
    page.current = 1;
    rangeFilter.current = selectedFilter;
    refetch();
  };

  return (
    <div>
      <div className="text-xl text-[#1C1A27] font-medium mb-8">Transaction History</div>
      <TransactionHistoryFilters
        onSelect={onRangeFilterChange}
        defaultValue={rangeFilter.current}
      />

      {transactions && (
        <div className="min-h-[500px]">
          {transactions.total ? (
            <>
              <div>
                {transactions.items.map((transactionItem) => (
                  <TransactionItem
                    key={transactionItem.transactionId}
                    name={transactionItem.name}
                    currencyCode={transactionItem.currencyCode}
                    amount={transactionItem.amount}
                    date={transactionItem.date}
                    pending={transactionItem.pending}
                  />
                ))}
              </div>
              {transactions.total > PAGE_SIZE && (
                <CustomPagination
                  pageCount={Math.ceil(transactions.total / PAGE_SIZE)}
                  onPageChange={onChangePage}
                  // Note: this works with ref, because whenever the page changes
                  // new data gets loaded, when new data gets loaded it rerenders this with the new ref
                  currentPage={page.current - 1}
                />
              )}
            </>
          ) : (
            <div>No transactions</div>
          )}
        </div>
      )}
    </div>
  );
};
