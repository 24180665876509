import { apiRequestClient } from '../client';
import { BuyerRecommendation } from '../types';
import { SuggestionStatus } from '../../../components/suggestions/suggestion-card';

/**
 * Right now just returns the recommendations, without filters
 */
export const getBuyerRecommendations = () => {
  return apiRequestClient()
    .method('get')
    .append('/buyer/recommendations/')
    .build<{ items: BuyerRecommendation[]; total: number }>();
};

export type UpdateBuyerPayload = { status: SuggestionStatus; id: string };

/**
 * Update the status of recommendations (this only happens for some recommendations)
 */
export const updateBuyerRecommendations = ({ status, id }: UpdateBuyerPayload) => {
  return apiRequestClient()
    .method('patch')
    .append('/buyer/recommendations/' + id)
    .setData({ status })
    .build();
};
