import OtherCategoryIcon from '@icons/dice-category-other.svg';
import BankFeesCategoryIcon from '@icons/bank-fees-category.svg';
import CashAdvanceCategoryIcon from '@icons/cash-advance-category.svg';
import CommunityCategoryIcon from '@icons/community-category.svg';
import FoodAndDrinkCategoryIcon from '@icons/food-and-drink-category.svg';
import HealthcareCategoryIcon from '@icons/healthcare-category.svg';
import InterestCategoryIcon from '@icons/interest-category.svg';
import PaymentCategoryIcon from '@icons/payment-category.svg';
import RecreationCategoryIcon from '@icons/recreation-category.svg';
import ServiceCategoryIcon from '@icons/service-category.svg';
import ShopsCategoryIcon from '@icons/shops-category.svg';
import TaxCategoryIcon from '@icons/tax-category.svg';
import TransferCategoryIcon from '@icons/transfer-category.svg';
import TravelCategoryIcon from '@icons/travel-category.svg';

// NOTE: this closely relates to the "getMainCategoryKey" function
// in "MonthlySpending" component which is currently the parent component of this.
const CATEGORY_ICONS = {
  'bank fees': BankFeesCategoryIcon,
  other: OtherCategoryIcon,
  'cash advance': CashAdvanceCategoryIcon,
  community: CommunityCategoryIcon,
  'food and drink': FoodAndDrinkCategoryIcon,
  healthcare: HealthcareCategoryIcon,
  interest: InterestCategoryIcon,
  payment: PaymentCategoryIcon,
  recreation: RecreationCategoryIcon,
  service: ServiceCategoryIcon,
  shops: ShopsCategoryIcon,
  tax: TaxCategoryIcon,
  transfer: TransferCategoryIcon,
  travel: TravelCategoryIcon,
};

export const MonthlySpendingItem = ({
  category,
  mainCategoryKey,
  amount,
  percentage,
}: {
  category: string;
  mainCategoryKey?: string;
  amount: number;
  percentage: number;
}) => {
  const CategoryIcon = CATEGORY_ICONS[mainCategoryKey] || PaymentCategoryIcon;

  return (
    <div className="flex mb-8">
      <div className="text-[#10B395] bg-[#CDFBE1] rounded-full mb-auto mr-3 h-10 w-10 flex justify-center items-center">
        <CategoryIcon />
      </div>

      <div className="w-full">
        <div className="flex justify-between text-sm font-semibold text-[#1C1A27] mb-3">
          <div>{category}</div>
          <div className="ml-2">${amount.toFixed(2)}</div>
        </div>
        <div className="h-1 w-full bg-[#DEDDE3] mb-3 rounded relative">
          <div
            className={`h-1 bg-[#10B395] rounded absolute z-10 hello`}
            // NOTE: need to use this, as there's no clear way of how to pass
            // such a variable to tailwind
            style={{ width: `${percentage}%` }}
          />
        </div>
        <div className="text-[#635E7D] text-xs">{percentage}%</div>
      </div>
    </div>
  );
};
