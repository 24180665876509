import clsx from 'clsx';
import { forwardRef, cloneElement, ButtonHTMLAttributes } from 'react';

const styles = {
  base: 'inline-block rounded-full focus:outline-none focus-visible:ring focus-visible:ring-opacity-50 max-w-min',
  transition: 'transition duration-300 ease-in-out',
  color: {
    'blue-ribbon':
      'bg-blue-ribbon-500 text-white hover:bg-blue-ribbon-600 ring-blue-ribbon-200',
    'spring-green':
      'bg-spring-green-500 text-white hover:bg-spring-green-600 ring-spring-green-200',
    'majorelle-blue':
      'bg-majorelle-blue-500 text-white hover:bg-majorelle-blue-600 ring-majorelle-blue-200',
    'cornflower-blue':
      'bg-cornflower-blue-500 text-white hover:bg-cornflower-blue-600 ring-cornflower-blue-200',
    'yellow-munsell':
      'bg-yellow-munsell-500 text-white hover:bg-yellow-munsell-600 ring-yellow-munsell-200',
    'elf-green':
      'bg-elf-green-500 text-white hover:bg-elf-green-600 ring-elf-green-200',
    success: 'bg-success-500 text-white hover:bg-success-600 ring-success-200',
    warn: 'bg-warn-500 text-white hover:bg-warn-600 ring-warn-200',
    error: 'bg-error-500 text-white hover:bg-error-600 ring-error-200',
    black: 'bg-black-500 text-white hover:bg-black-600 ring-black-200',
    white:
      'bg-neutral-ghost dark:bg-[#2d2d2d] dark:text-white text-black hover:bg-neutral-ghost-white ring-white-200',
    transparent:
      'bg-tranparent text-black hover:bg-neutral-ghost-white/40 ring-transparent',
  },
  disabled: 'bg-black-disabled cursor-not-allowed',
  size: {
    xs: 'p-2',
    sm: 'p-3',
  },
};

export interface IconButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
  /**
   * Defines the variant of the button
   */
  color?: string;
  /**
   * Defines the size of the button
   */
  size?: keyof typeof styles.size;
  /**
   * The icon that will be rendered, must use icons created through SvgIcon component
   */
  icon: JSX.Element;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function Button(
    {
      size = 'xs',
      type = 'button',
      color = 'blue-ribbon',
      disabled = false,
      className,
      icon,
      ...props
    },
    ref
  ): JSX.Element {
    const classes = clsx(
      styles.base,
      styles.transition,
      styles.size[size],
      disabled ? styles.disabled : styles.color[color],
      className
    );

    return (
      <button
        ref={ref}
        type={type}
        disabled={disabled}
        className={classes}
        {...props}
      >
        {cloneElement(icon, {
          size,
          color: disabled ? undefined : icon.props.color,
        })}
      </button>
    );
  }
);
