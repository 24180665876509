import { apiRequestClient } from '../client';
import { stringify } from 'querystring';

export type PlaidItem = {
  itemId: string;
  // accessToken: string;
  institutionId: string | null;
  institutionName: string | null;
  institutionLogo: string | null;
  institutionPrimaryColor: string | null;
  status: 'connected' | 'waiting_for_webhook' | 'error';
  incomeType: 'payroll' | 'document';
  userId: string;
  createdAt: Date;
  updatedAt: Date;
  plaidIncomePayStubs: any;
};

export type PlaidAccount = {
  accountId: string;
  accountBalanceAvailable: number | null;
  accountBalanceCurrent: number | null;
  accountBalanceLimit: number | null;
  accountBalanceIsoCode: string | null;
  accountBalanceLastUpdated: Date | null;
  name: string;
  mask: string | null;
  // TODO: add this enum
  // type: PlaidAccountType
  type: string;
  subType: string | null;
  itemId: string;
  item?: Pick<PlaidItem, 'institutionName' | 'institutionLogo' | 'institutionPrimaryColor'>;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
};

export type PlaidTransaction = {
  amount: number,
  buyerDebtId?: string,
  categories: string[],
  categoryId: string,
  createdAt: Date,
  currencyCode: string,
  date: Date,
  name: string,
  paymentChannel: string,
  pending: boolean,
  personalFinanceCategoryDetailed: string,
  personalFinanceCategoryPrimary: string,
  plaidAccountId: string,
  plaidItemId: string,
  transactionId: string,
  updatedAt: Date,
  userId: string,
}

/**
 * Create a link token for the Plaid Link frontend component, which allows the user to connect a bank account, for transactions.
 */
export const createBuyerTransactionsLinkToken = () => {
  return apiRequestClient().method('post').append('/buyer/plaid/items/create-link-token').build<{
    link_token: string;
    expiration: string;
  }>();
};

/**
 * Exchange a public token for an access token
 */
export const exchangeBuyerTransactionsPublicToken = (body: {
  publicToken: string;
  institutionId: string;
}) => {
  return apiRequestClient()
    .method('post')
    .append('/buyer/plaid/items/exchange-public-token')
    .setData(body)
    .build<any>();
};

type PlaidItemResponse = { accounts: PlaidAccount[] } & PlaidItem;
export const getBuyerTransactionsItems = async () => {
  return apiRequestClient().method('get').append('buyer/plaid/items').build<PlaidItemResponse[]>();
};

/**
 * Delete an income item, alogside all associated data(like income, transactions, savings, accounts, etc...)
 */
export const deleteBuyerPlaidItem = (itemId: string) => {
  return apiRequestClient()
    .method('delete')
    .append('/buyer/plaid/items/' + itemId)
    .build<{ id: string; userId: string }>();
};

export type PastRangeOptions = 'pastWeek' | 'pastMonth' | 'pastYear';

/**
 * Get buyer plaid transactions
 */
export const getBuyerPlaidTransactions = (query: { page?: number; pageSize?: number; pastRange?: PastRangeOptions }) => {
  return apiRequestClient()
    .method('get')
    .append(`/buyer/plaid/items/transactions?${stringify(query)}`)
    .build<{ total: number; items: PlaidTransaction[] }>();
};

export class ExpenseByCategory {
  categoryId?: string;
  category?: string;
  amount: number;
}


/**
 * Get buyer plaid expenses
 */
export const getBuyerPlaidExpenses = (monthDate: string) => {
  return apiRequestClient()
    .method('get')
    .append(`/buyer/plaid/items/expenses?monthDate=${monthDate}`)
    .build<ExpenseByCategory[]>();
};

/**
 * Get buyer plaid cashflow
 */
export const getBuyerPlaidCashflow = (monthDate: string) => {
  return apiRequestClient()
    .method('get')
    .append(`/buyer/plaid/items/cash-flow?monthDate=${monthDate}`)
    .build<{
      income: number;
      expenses: number;
      net: number;
      vsLastMonthPercentage: number;
      vsLastMonth: string;
    }>();
};

export type IncomeExpensesRangeItem = {
  income: number;
  expenses: number;
  monthDate: string;
}



/**
 * Get buyer plaid expenses & incomes for a requested range. Grouped by months.
 * manthDateStart & monthDateEnd range provided is considered inclusive
 */
export const getIncomeExpensesRange = (monthDateStart: string, monthDateEnd: string) => {
  return apiRequestClient()
    .method('get')
    .append(`/buyer/plaid/items/income-expenses-range?monthDateStart=${monthDateStart}&monthDateEnd=${monthDateEnd}`)
    .build<IncomeExpensesRangeItem[]>();
};