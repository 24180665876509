import { Input } from '../input';

export const Title = () => {
  return (
    <Input
      name="role"
      label="Title"
      placeholder="Senior Software Engineer"
      validations={{ required: true }}
    />
  );
};
