import { Input } from '../input';

export const SSN = () => {
  return (
    <Input
      name="ssn"
      label="Social Security Number"
      placeholder="000 - 00 - 0000"
      mask="999 - 99 - 9999"
      validations={{ required: true }}
    />
  );
};
