import { useEffect, useState } from 'react';
import { useRouter } from 'next/router'; // Assuming you're using Next.js for routing

interface CustomNotification {
  title: string;
  body: string;
  icon?: string;
  timestamp: Date;
  senderName?: string; // Optional: sender's name
  messageContent?: string; // Optional: message content
}

export const useNotification = () => {
  const [notifications, setNotifications] = useState<CustomNotification[]>([]);
  const router = useRouter(); // Use router for navigation

  const addNotification = (
    title: string,
    options?: NotificationOptions,
    senderName?: string,
    messageContent?: string
  ) => {
    if (Notification.permission === 'granted') {
      const notification = new Notification(title, options);

      notification.onclick = () => {
        window.focus();
        // Redirect to messages page (you might need to adjust the path)
        router.push('/messages');
      };

      const newNotification: CustomNotification = {
        title,
        body: options?.body || '',
        icon: options?.icon || '',
        timestamp: new Date(),
        senderName,
        messageContent,
      };

      setNotifications((prev) => [...prev, newNotification]);
    } else if (Notification.permission === 'default') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          addNotification(title, options, senderName, messageContent); // Retry adding the notification
        }
      });
    }
  };

  useEffect(() => {
    if (Notification.permission === 'default') {
      Notification.requestPermission();
    }
  }, []);

  return { notifications, addNotification };
};
