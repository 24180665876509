import { apiRequestClient } from '../client';
export const financialliteracyAllCourses = (search?: string) => {
  const params = new URLSearchParams();
  if (search) params.set('search', search);
  return apiRequestClient()
    .method('get')
    .append(`financialliteracy/allCourses?${params.toString()}`)
    .build<{
      items: {
        courseId: number;
        courseName: string;
        logoURL: string;
        tags: string[];
        sections: { sectionId: number; isSectionCompleted: boolean }[];
      }[];
    }>();
};
export const financialliteracyCourseAggr = (body: { courseId: number; sectionId: number }) => {
  return apiRequestClient()
    .method('put')
    .append('financialliteracy/courseAggregation')
    .setData(body)
    .build<{
      items: {
        courseId: number;
        courseName: string;
        logoURL: string;
        sections: {
          sectionId: number;
          isSectionCompleted: boolean;
          terms: {
            termId: number;
            termName: string;
            termDefinition: string;
            questions: {
              questionId: number;
              questionDescription: string;
              choices: {
                questionChoicePk: number;
                questionChoiceId: number;
                questionChoiceDescription: string;
              }[];
            }[];
          }[];
        }[];
      }[];
    }>();
};

export const financialliteracyUserCourseUpdate = (body: {
  courseId: number;
  sectionId: number;
  questionsResponse: {
    questionId: number;
    questionChoicePk: number;
  }[];
}) => {
  return apiRequestClient()
    .method('post')
    .append('financialliteracy/courseAggregation')
    .setData(body)
    .build<{
      items: string;
    }>();
};

export const financialLiteracyMarkSectionComplete = (body: {
  courseId: number;
  sectionId: number;
}) => {
  return apiRequestClient()
    .method('put')
    .append('financialliteracy/courseAggregation/mark-section-complete')
    .setData(body)
    .build<{
      items: string;
    }>();
};
