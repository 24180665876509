import { useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { Dayjs } from 'dayjs';
import { ApiService } from '@services/Api';
import { Loading } from '@components';
import clsx from 'clsx';
import CurrencyFormat from 'react-currency-format';
import { CashFlowItem } from './cash-flow-item';

export const CashFlow = ({ selectedMonth }: { selectedMonth: Dayjs }) => {
  const mounted = useRef(false);

  const {
    data: cashflow,
    isFetching,
    refetch,
  } = useQuery(
    ['buyer-plaid-items-cash-flow'],
    async () => {
      const resp = await ApiService.getBuyerPlaidCashflow(selectedMonth.format('YYYY-MM-DD'));

      return resp.data;
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (mounted.current) {
      refetch();
    } else {
      mounted.current = true;
    }
  }, [selectedMonth]);

  return (
    <div>
      <Loading isLoading={isFetching} />
      <div className="text-xl text-[#1C1A27] font-medium mb-2">Cash flow</div>
      <div className="text-sm text-[#635E7D] mb-8">
        Net amount received and disbursed this month
      </div>
      {cashflow && (
        <>
          <div className="mb-8">
            <CurrencyFormat
              prefix="$"
              displayType="text"
              thousandSeparator
              className="text-[#1C1A27] text-4xl"
              value={`${cashflow.net}`}
            />
          </div>
          <div
            className={clsx('text-sm mb-8 ml-3', {
              'text-[#10B395]': cashflow.vsLastMonthPercentage >= 0,
              'text-[#FF9580]': cashflow.vsLastMonthPercentage < 0,
            })}
          >
            {cashflow.vsLastMonthPercentage >= 0 ? '+' : ''}
            {cashflow.vsLastMonthPercentage}% vs {cashflow.vsLastMonth}
          </div>
          <CashFlowItem
            isIncome
            title="Income"
            subTitle="Money received"
            amount={cashflow.income}
          />
          <CashFlowItem title="Expenses" subTitle="Money spent" amount={cashflow.expenses} />
        </>
      )}
    </div>
  );
};
