import clsx from 'clsx';
import { createPortal } from 'react-dom';
import styles from './loading.module.scss';

interface LoadingProps {
  isLoading: boolean;
  fullscreen?: boolean;
  size?: number;
  loadingClassName?: string;
}

export const Loading = ({ isLoading, fullscreen = true, loadingClassName }: LoadingProps) => {

  return isLoading ? (
    fullscreen ? (
      createPortal(
        <div className={styles.loadingContainer}>
          <div className={clsx(styles.loading)} />
        </div>,
        document.body,
        'loading',
      )
    ) : (
      <div className="flex justify-center">
        <div className={clsx(styles.loading, '!border-gray-50', loadingClassName)} />
      </div>
    )
  ) : null;
};
