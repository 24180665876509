import { create } from 'zustand';

export type DashboardModalType =
  | 'editGoal'
  | 'infoModal'
  | 'creditScoreGrade'
  | 'creditScoreGrading'
  | 'creditScoreDetails'
  | 'incomeGrade'
  | 'incomeGrading'
  | 'incomeDetails'
  | 'downpaymentGrade'
  | 'downpaymentGrading'
  | 'downpaymentDetails'
  | 'debtToIncomeGrade'
  | 'debtToIncomeGrading'
  | 'debtToIncomeDetails'
  | 'generateCreditReport'
  | 'createCreditReportUser'
  | 'addIncome'
  | 'addTransactionItem';

export const useDashboardModal = create<{
  currentModal: DashboardModalType;
  setCurrentModal: (currentModal: DashboardModalType) => void;
}>((set) => ({
  currentModal: null,

  setCurrentModal: (currentModal: DashboardModalType) => set({ currentModal }),
}));
