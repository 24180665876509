import ReactPaginate from 'react-paginate';
import ChevronUp from '@icons/chevron-up.svg';

const pageItemClassName = 'text-[#9F98BE] hover:text-[#0AAA82]';

export const CustomPagination = ({
  onPageChange,
  pageCount,
  currentPage,
}: {
  onPageChange: (selectedItem: { selected: number }) => void;
  pageCount: number;
  currentPage: number;
}) => {
  return (
    <div>
      <ReactPaginate
        className="flex w-full justify-between items-center"
        breakClassName={pageItemClassName}
        breakLabel="..."
        nextClassName={pageItemClassName}
        nextLabel={<ChevronUp width={20} className="rotate-90" />}
        onPageChange={onPageChange}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousClassName={pageItemClassName}
        previousLabel={<ChevronUp width={20} className="rotate-[270deg]" />}
        renderOnZeroPageCount={null}
        pageClassName={pageItemClassName}
        activeClassName="text-[#0AAA82]"
        marginPagesDisplayed={1}
        forcePage={currentPage}
      />
    </div>
  );
};
