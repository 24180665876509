import { apiRequestClient } from '../client';
import { ICreateMessage, IUserFilter } from '../types';


export interface IPartnerUserResponse {
  users: {
    id: string;
    bankConnection: boolean;
    creditConnection: boolean;
    bankName: string | null;
    totalScoreGrade: string | null;
    creditGrade: string | null;
    debtToIncomeGrade: string | null;
    downpaymentPercentageSavedGrade: string | null;
    grossIncomeGrade: string | null;
    initialTotalScoreGrade: string | null;
    initialCreditGrade: string | null;
    initialDebtToIncomeGrade: string | null;
    initialDownpaymentPercentageSavedGrade: string | null;
    initialGrossIncomeGrade: string | null;
    createdAt: string;
    partnerCode: string | null;
    firstName: string;
    lastName: string;
  }[];
  next: boolean;
  pages: number;
  total: number;
}



export const listThreads = () => {
  return apiRequestClient()
    .method('get')
    .append('messages/user/threads')
    .build<IThreadsResponse[]>();
}

export const listMessages = async(adminUserId: string) => {
  return apiRequestClient()
    .method('get')
    .append(`messages/user?adminUserId=${adminUserId}`)
    .build<IMessageResponse[]>();
}

export const createMessage = async(data: ICreateMessage) => {
  return apiRequestClient()
    .method("post")
    .append(`messages/user`)
    .setData(data)
    .build()
}

export interface IMessageResponse {
  id: string
  adminId: string
  isSenderAdmin: boolean
  userId: string
  messageText: string
  createdAt: Date
}

export interface IThreadsResponse {
  adminId: string
  createdAt: Date
  messageText: string
  adminUser: {
    firstName: string
    lastName: string
  }
}


export const getAffiliatedUsersWithLimit = (
  page = 0,
  filters?: IUserFilter[],
  unassociated = false,
  limit = 50000 // Add a fourth parameter for limit
) => {
  const skip = page * limit; // Adjust the skip calculation to use the dynamic limit
  const params = new URLSearchParams({
    limit: String(limit), // Use the dynamic limit here
    skip: String(skip),
  });

  if (unassociated) params.set("unassociated", "true");

  if (filters && filters.length > 0) {
    params.append("filters", JSON.stringify(filters));
  }

  let request = apiRequestClient()
    .method("get")
    .append(`admin/users?${params.toString()}`);
  return request.build<IPartnerUserResponse>();
};