import '@styles/globals.scss';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useAuthUser, useBuyerSignupInfo, useIsMounted, useTheme } from '@hooks';
import { LocaleProvider } from '@contexts';
import { NextPage } from 'next';
import { AppWrapper, RouteProtector } from '@components';
import Head from 'next/head';
import { Toaster } from 'react-hot-toast';
import { useRouter } from 'next/router';
import * as gtag from '@lib/gtag';
import { TourProvider } from 'contexts/tour-guide';

export type NextPageWithLayout = NextPage & { getLayout?: (page: ReactElement) => ReactNode };

interface AppPropsWithLayout extends AppProps {
  Component: NextPageWithLayout;
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const [queryClient] = useState(() => new QueryClient());
  const router = useRouter();

  const getLayout = Component.getLayout ?? ((page) => page);
  const { mode } = useTheme();

  useEffect(() => {
    if (mode === 'light') {
      document.querySelector('html')?.classList.remove('dark');
    } else {
      document.querySelector('html')?.classList.add('dark');
    }
  }, [mode]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageView(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <title>My Home Pathway - We&apos;re here to help you get your mortgage approved</title>
        <meta name="description" content="Your founding team on demand." />
        <link
          rel="apple-touch-icon"
          type="image/png"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="apple-touch-precomposed"
          type="image/png"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#FFFFFF" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>

      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <LocaleProvider>
            <AppWrapper>
            <TourProvider>
              <RouteProtector>
                {getLayout(<Component {...pageProps} />)}
                <Toaster />
              </RouteProtector>
            </TourProvider>
            </AppWrapper>
          </LocaleProvider>
        </Hydrate>
        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
      </QueryClientProvider>
    </>
  );
};

export default App;
