import { useAuthUser, useBuyerSignupInfo } from '@hooks';
import { commonRoutes, localStorageDataRef } from '@utils';
import { Button } from '@components/button';
import { ErrorMessage } from '@components/error-message';
import { Loading } from '@components/loading';
import dayjs from 'dayjs';
import { GoogleAuthProvider, OAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { firebaseApp } from '@lib/firebase';
import * as gtag from '@lib/gtag';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { memo, useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { ApiService, EAuthenticationType, EUserType } from '@services/Api';
interface InputProps {
  token: string;
  provider: 'google' | 'apple';
  firstName?: string;
  lastName?: string;
}

const GoogleIcon = memo(function googleIcon() {
  return <Image src={'/icons/google.png'} alt="google" width="24" height="24" />;
});

const AppleIcon = memo(function appleIcon() {
  return <Image src={'/icons/apple.png'} alt="google" width="24" height="24" />;
});

export const BuyerSocialSigninV2 = ({
  skipToThirdStep,
  actionLabelProp = 'Sign Up',
  buttonStyle = {},
  signup = null,
}: {
  skipToThirdStep?: Function;
  actionLabelProp?: string;
  buttonStyle?: React.CSSProperties;
  signup?: Function | null;
}) => {
  const { setBuyerInfo, authenticationId, step, email, firstName, lastName, ...rest } =
    useBuyerSignupInfo();
  const [isLoading, setIsLoading] = useState(false);

  const [provider, setProvider] = useState<'google' | 'apple'>(null);

  const { updateUserInfo, signedIn } = useAuthUser();

  const router = useRouter();

  const onMutationSuccess = useCallback(
    async (result) => {
      const inviteId = localStorage.getItem(localStorageDataRef.acceptInvite);
      const inviteDate = localStorage.getItem(localStorageDataRef.acceptInviteDate);
      let inviteValid = false;
      if (inviteId && inviteDate) {
        const diff = dayjs(Date.now()).diff(new Date(inviteDate), 'days');
        if (diff > 2) inviteValid = false;
        else inviteValid = true;
      }
      if (result.authType === 'sign-in') {
        setBuyerInfo({ authenticationId: null });
        const remappedUserType = result.user.userType === 'buyer' ? 'buyer' : 'partner';
        if (!signedIn && result.accessToken)
          gtag.event({
            action: 'login',
            value: {
              user_id: result.user.id,
              provider,
            },
          });

        updateUserInfo({
          ...result.user,
          userType: remappedUserType,
          accessToken: result.accessToken,
          signedIn: true,
        });
        router.push(commonRoutes.buyer.auth.account);
      } else {
        if (!rest?.associateIdStatus && !rest?.partnerCodeStatus && !inviteValid) {
          setIsLoading(true);
          const res = await ApiService.savePartialSignUpForm({
            authenticationId: result.authenticationId,
            email: result.email,
            userType: EUserType.buyer,
            partialSignupForm: {
              values: {
                email: result.email,
                firstName: result.firstName,
                lastName: result.lastName,
                userType: result.userType,
                provider: result.provider,
                partnerCode: rest?.partnerCode,
                lenderCode: rest?.lenderCode,
                partnerCodeStatus: rest?.partnerCodeStatus,
              },
            } as any,
          });
        }

        setBuyerInfo({
          ...(result.partialSignupForm?.values ?? {}),
          email: result.email,
          authenticationId: result.authenticationId,
          firstName: result.firstName,
          lastName: result.lastName,
          userType: result.userType,
          provider: result.provider,
          partnerCode: rest?.partnerCode || '',
          lenderCode: rest?.lenderCode || '',
          partnerCodeStatus: rest?.partnerCodeStatus || '',
        });

        if (signup) {
          signup({
            authenticationId: result.authenticationId,
            firstName: result.firstName,
            lastName: result.lastName,
            userType: result.userType,
            email: result.email,
            accessCode: rest.accessCode ?? undefined,
            partnerCode: rest.partnerCode ?? undefined,
            lenderCode: rest.lenderCode ?? undefined,
            associateId: rest.associateIdStatus ? rest.associateId : undefined,
            housePriceGoal: 0,
            expectedPurchaseMonthsPeriod: 0,
            phone: null,
            wishedLocations: [],
            provider: result.provider,
            inviteId: localStorage.getItem(localStorageDataRef.acceptInvite),
          } as any);
        }

        setIsLoading(false);

        skipToThirdStep && skipToThirdStep();
      }
    },
    [
      rest?.lenderCode,
      rest?.partnerCode,
      rest?.partnerCodeStatus,
      rest?.associateIdStatus,
      router,
      setBuyerInfo,
      skipToThirdStep,
      updateUserInfo,
    ],
  );
  const startSSOMutation = useMutation(
    async (values: InputProps) => {
      setProvider(values.provider);
      const resp = await ApiService.startSSOLogin({
        token: values.token,
        userType: EUserType.buyer,
        type: ['/user/sign-up', '/user/sign-up-v2'].includes(router.pathname)
          ? EAuthenticationType.signup
          : EAuthenticationType.signin,
        provider: values.provider,
        referredBy: '',
        firstName: values?.firstName || '',
        lastName: values?.lastName || '',
      }).catch((error) => {
        throw (
          error?.data?.message ||
          'We encountered an error on our side. Please try again in a few minutes.'
        );
      });
      return resp.data;
    },
    {
      onSuccess: onMutationSuccess,
    },
  );

  const onClick = async (providerData: 'apple' | 'google') => {
    const auth = getAuth(firebaseApp);
    if (providerData === 'google') {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      await signInWithPopup(auth, provider).catch((e) => console.error(e));
    }
    if (providerData === 'apple') {
      const provider = new OAuthProvider('apple.com');

      provider.addScope('email');
      provider.addScope('name');

      await signInWithPopup(auth, provider).catch((e) => console.error(e));
    }
    if (!auth?.currentUser)
      throw 'We encountered an error on our side. Please try again in a few minutes.';
    const id_token = await auth.currentUser.getIdToken();
    const user = auth.currentUser;
    const [firstName, lastName] = user?.displayName
      ? [user.displayName?.split(' ').at(0), user.displayName?.split(' ').at(-1)]
      : [];
    startSSOMutation.mutate({
      token: id_token,
      provider: providerData,
      firstName,
      lastName,
    });
  };

  const actionLabel = useMemo(() => {
    if (actionLabelProp) {
      return actionLabelProp;
    }

    if (router.pathname === '/user/sign-in') {
      return 'Sign in';
    }
    return 'Sign up';
  }, [router.pathname]);
  return (
    <>
      <div className="text-center flex flex-col gap-4">
        <div>
          <button
            type="button"
            disabled={isLoading}
            className="w-full p-4 items-center align-middle justify-center  border-1 border-gray-900 shadow-lg  rounded-md flex  "
            onClick={() => onClick('google')}
            style={buttonStyle}
          >
            <GoogleIcon />
            <span className="!w-full">{actionLabel} with Google</span>
          </button>
          {startSSOMutation.isError && provider === 'google' && (
            <ErrorMessage error={startSSOMutation.error && (startSSOMutation.error as string)} />
          )}
        </div>
        <div>
          <button
            type="button"
            disabled={isLoading}
            className="w-full p-4 items-center align-middle justify-center  border-1 border-gray-900 shadow-lg  rounded-md flex  "
            onClick={() => onClick('apple')}
            style={buttonStyle}
          >
            <AppleIcon />
            <span className="w-full ml-[-13px]">{actionLabel} with Apple</span>
          </button>
          {startSSOMutation.isError && provider === 'apple' && (
            <ErrorMessage error={startSSOMutation.error && (startSSOMutation.error as string)} />
          )}
        </div>
      </div>
      <Loading isLoading={startSSOMutation.isLoading} />
    </>
  );
};
