import { Input } from '../input';

export const ZipCode = ({ name = 'zipCode' }) => {
  return (
    <Input
      wrapperClassName="w-full"
      mask="99999"
      validations={{ required: true }}
      placeholder="Enter ZIP"
      name={name || 'zipCode'}
      label="ZIP code"
    />
  );
};
