import { apiRequestClient } from '../client';
import { BuyerDebt } from '../types';

/**
 * List all buyer debts
 */
export const listBuyerDebts = () => {
  return apiRequestClient().method('get').append('/buyer/debts').build<{
    items: BuyerDebt[];
    total: number;
  }>();
};

type CreateBuyerDebtBody = Pick<BuyerDebt, 'amount' | 'category'> & {
  date: string;
};
/**
 * Create a buyer debts
 */
export const createBuyerDebt = (body: CreateBuyerDebtBody) => {
  return apiRequestClient().method('post').append('/buyer/debts').setData(body).build<BuyerDebt>();
};

type UpdateBuyerDebtBody = Partial<CreateBuyerDebtBody>;
/**
 * Create a buyer debts
 */
export const updateBuyerDebt = (id: string, body: UpdateBuyerDebtBody) => {
  return apiRequestClient()
    .method('patch')
    .append('/buyer/debts/' + id)
    .setData(body)
    .build<BuyerDebt>();
};

/**
 * Deletes a buyer debt
 */
export const deleteBuyerDebt = (id: string) => {
  return apiRequestClient()
    .method('delete')
    .append('/buyer/debts/' + id)
    .build<{ id: string }>();
};
