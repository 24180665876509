import { EReasonForPurchase } from '@services/Api';
import { Select } from '../select';

export const ReasonForPurchase = ({
  showLabel = true,
  alternate,
  showRequired = true,
}: {
  showLabel?: boolean;
  alternate?: boolean;
  showRequired?: boolean;
}) => {
  return (
    <Select
      name="reasonForPurchase"
      options={[
        { label: 'Build wealth', value: EReasonForPurchase.build_wealth },
        { label: 'Better schools', value: EReasonForPurchase.better_schools },
        { label: 'Safer neighborhood', value: EReasonForPurchase.safer_neighborhood },
        { label: 'Stop paying rent', value: EReasonForPurchase.stop_paying_rent },
        { label: 'Tax benefits', value: EReasonForPurchase.tax_benefits },
        { label: 'Place to raise my family', value: EReasonForPurchase.place_to_raise_family },
        { label: 'Other', value: EReasonForPurchase.other },
      ]}
      showLabel={showLabel}
      alternate={alternate}
      label="Reason for home purchase"
      placeholder="Select"
      showRequired={showRequired}
    />
  );
};
