import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, ReactNode, useEffect } from 'react';

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  title?: ReactNode;
  description?: string;
  children?: ReactNode;
  className?: string;
  panelClassName?: string;
  wrapperClassName?: string;
  dialogClassName?: string;
  containerClassName?: string;
}

export const styles = {
  dialog: 'relative z-50',
  scrollable: 'fixed inset-0 flex items-center justify-center p-4',
  center: 'flex min-h-full items-center justify-center',
  panel: 'bg-card p-12 rounded-[20px] h-[calc(100vh-80px)] overflow-y-auto overflow-x-hidden',
  title: 'text-[22px] font-medium text-default',
  description: 'text-sm text-default',
};

export const Modal = ({
  open,
  onClose,
  children,
  title,
  wrapperClassName = 'flex min-h-[90%] items-center justify-center p-4 text-center',
  panelClassName = 'w-full max-w-xl rounded-2xl py-10 px-8 text-left',
  dialogClassName = 'z-10',
  containerClassName,
}: ModalProps) => {
  useEffect(() => {
    if (open) {
      document.querySelector('html').style.overflow = 'hidden';
      return;
    }
    document.querySelector('html').style.overflow = 'unset';
  }, [open]);
  return (
    open && (
      <Transition appear={open} show={open} as={Fragment}>
        <Dialog
          as="div"
          open={open}
          className={clsx(dialogClassName, 'relative')}
          onClose={onClose}
        >
          <Transition.Child as={Fragment}>
            <div className="fixed inset-0 bg-black bg-opacity-25 dark:bg-black-dark dark:bg-opacity-90" />
          </Transition.Child>
          <div className={clsx('fixed inset-0 overflow-y-auto', containerClassName)}>
            <div className={wrapperClassName}>
              <Dialog.Panel
                className={clsx(
                  'translation-y-1/2 shadow-xl transform overflow-hidden bg-white dark:bg-black-background',
                  panelClassName,
                )}
              >
                {title && (
                  <Dialog.Title
                    as="h3"
                    className=" font-semibold leading-6 text-gray-900 dark:text-white lg:text-2xl mb-4"
                  >
                    {title}
                  </Dialog.Title>
                )}
                {children}
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition>
    )
  );
};

export const CleanModal = (props: ModalProps) => {
  return (
    props.open && (
      <Modal
        {...props}
        wrapperClassName={clsx(
          'flex min-h-[100%] items-center justify-center p-4 text-center',
          props?.wrapperClassName,
        )}
        panelClassName={clsx('w-full max-w-xl px-8 py-6 text-left', props?.panelClassName)}
        dialogClassName={clsx('z-10', props?.dialogClassName)}
      />
    )
  );
};
