import Image from 'next/image';
import { useFormContext } from 'react-hook-form';
import { CustomInput, Input } from '../input';
import clsx from 'clsx';

const Flags = () => {
  return <Image src={'/icons/flag.png'} alt="Picture of the author" width="22" height="16" />;
};
export const CountryPhone = (props: CustomInput) => {
  const { watch, formState } = useFormContext();
  const inputValue = watch(props.name || 'phone');

  const errors = Boolean(formState.errors?.phone);

  let phoneNumberValidationUS =
    /^(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/;

  return (
    <div>
      <Input
        name="phone"
        label="Phone number"
        placeholder="(123) 876-5432  - Phone Number is required."
        icon={<Flags />}
        mask="(999) 999-9999"
        validations={{
          pattern: phoneNumberValidationUS,
        }}
        className={clsx('keep-color', {
          'stroke-primary': inputValue?.length >= 10,
          'stroke-gray-500': inputValue?.length === 0,
          'stroke-red': errors,
        })}
        isError={errors}
        {...props}
      />
    </div>
  );
};
