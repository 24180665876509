import NDR from '@icons/NDR.svg';
import TransUnion from '@icons/Transunion.svg';
import ESUSU from '@icons/esus.svg';
import Habitat from '@icons/habitat.svg';
import SelfLender from '@icons/self-lender.svg';

export const PartnerList = ()=>{
    return (
        <>
        <div className="hidden xs:block sm:block md:block lg:block xl:block">
        <p className="font-semibold text-center">Partners who support our mission</p>
        <div className="xs:mt-7 sm:mt-7 xs:max-h-[100px] sm:max-h-[10px] mx-auto r-10" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '100%', margin: '20px' }}>
          <NDR
            className="keep-color md:mb-3 lg:mb-2.5"
            style={{
              width: '67.55px',
              height: '70px',
              top: '40px',
              left: '10px',
              marginRight: '15px'
            }}
          />
          <TransUnion
            className="keep-color"
            style={{
              width: '54.04px',
              height: '56.71px',
              top: '47px',
              left: '102.29px',
              borderRadius: '11px',
              marginRight: '15px',
            }}
          />
          {/* <ESUSU
            className="keep-color"
            style={{
              width: '144.75px',
              height: '76.85px',
              top: '40px',
              left: '191.07px',
              marginRight: '15px',
            }}
          /> */}
          <Habitat
            className="keep-color"
            style={{
              width: '67.55px',
              height: '70px',
              top: '43px',
              left: '370.56px',
              marginRight: '15px',
            }}
          />
          <SelfLender
            className="keep-color"
            style={{
              width: '51.15px',
              height: '52px',
              top: '56px',
              left: '472.85px',
            }}
          />
      </div>
      </div>
      </>
        
    )
}