import { useUserStore } from '@hooks';
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

const defaultHeaders: AxiosRequestHeaders = {
  'Content-Type': 'application/json',
  'x-api-key': process.env.NEXT_PUBLIC_SERVER_API_KEY || '',
  'Access-Control-Allow-Origin': '*',
};

/**
 * Base Axios instance to be used in all requests
 */
export const client = axios.create({
  timeout: 60 * 1 * 1000, // 1 minute
  headers: defaultHeaders,
});

// Ref: https://axios-http.com/docs/interceptors
client.interceptors.request.use((config: AxiosRequestConfig) => {
  const accessToken = useUserStore?.getState?.()?.accessToken;
  if (accessToken) {
    const headers: AxiosRequestHeaders = {
      Authorization: `Bearer ${accessToken}`,
    };
    config.headers = { ...config.headers, ...headers };
  }
  return config;
});

// Add a response interceptor
client.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response;
  },
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const errorData = error.response;
      return Promise.reject(errorData);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return Promise.reject(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      return Promise.reject(error.message);
    }
  }
);
