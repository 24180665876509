import React, { Dispatch, SetStateAction, memo, useRef, useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { Toast } from '@utils';
import { AxiosRequestHeaders } from 'axios';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import clsx from 'clsx';

// Register the plugins
registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
);

export const AvatarUpload = ({
  onFileUpload,
  className,
  setLoading,
  onFileRemoved,
}: {
  onFileUpload: (string) => void;
  onFileRemoved?: () => void;
  className?: string;
  setLoading?: Dispatch<SetStateAction<boolean>>;
}) => {
  const filePondRef = useRef(null);
  const handleFileProcess = (error, file) => {
    if (!error) {
      const fileURL = JSON.parse(file.serverId);
      onFileUpload(fileURL.url as string);
      if (setLoading) setLoading(false);
    } else {
      Toast({
        text: error.body ?? 'File size is too large',
        variant: 'error',
        duration: 5000,
      });
    }
  };

  const defaultHeaders: AxiosRequestHeaders = {
    'x-api-key': process.env.NEXT_PUBLIC_SERVER_API_KEY || '',
    'Access-Control-Allow-Origin': '*',
  };

  return (
    <div className={clsx(' w-20 rounded-full cursor-pointer ', className)}>
      <FilePond
        ref={filePondRef}
        maxFileSize="5MB"
        imagePreviewMaxHeight={100}
        imagePreviewMinHeight={100}
        stylePanelLayout="compact circle"
        styleLoadIndicatorPosition="center"
        styleButtonRemoveItemPosition="center"
        styleButtonProcessItemPosition="center"
        styleProgressIndicatorPosition="center"
        allowMultiple={false}
        server={{
          process: {
            url: `${process.env.NEXT_PUBLIC_API_URL}/buyer/upload`,
            method: 'POST',
          },
          headers: defaultHeaders,
        }}
        onaddfile={() => {
          if (setLoading) setLoading(true);
        }}
        onprocessfile={handleFileProcess}
        onremovefile={onFileRemoved}
        name="avatar"
        labelIdle=""
        acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
        onerror={handleFileProcess}
      />
    </div>
  );
};
