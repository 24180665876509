import { Button } from '@components/button';
import { Form } from '@components/form';
import { Modal } from '@components/modal';
import { useAuthUser } from '@hooks';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { CustomInput } from '../custom-input';
import { Loading } from '@components/loading';
import { useMutation } from 'react-query';
import { ApiService } from '@services/Api';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ErrorMessage } from '@components/error-message';
import { Toast } from '@utils';

interface IInviteCoBorrowerFields {
  coBorrowersFirstName: string;
  coBorrowersLastName: string;
  coBorrowersEmail: string;
  inviteeUserId: string;
}

export const InviteCoBorrower = ({ label, disabled }: { label?: string; disabled?: boolean }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { profile, refetchUserInfo } = useAuthUser();

  const methods = useForm<IInviteCoBorrowerFields>({
    defaultValues: {
      coBorrowersFirstName: profile?.coBorrowerInvite?.coBorrowersFirstName || '',
      coBorrowersLastName: profile?.coBorrowerInvite?.coBorrowersLastName || '',
      coBorrowersEmail: profile?.coBorrowerInvite?.coBorrowersEmail || '',
      inviteeUserId: profile.userId,
    },
    reValidateMode: 'onChange',
  });

  const inviteMutation = useMutation<
    ApiService.InviteCoBorrowerResponse,
    AxiosResponse,
    ApiService.InviteCoBorrowerBody
  >(
    async (data) => {
      const resp = await ApiService.inviteCoBorrower(data);
      return resp.data;
    },
    {
      onSuccess: () => {
        setIsOpen(false);
        refetchUserInfo();
        methods.reset();
      },
      onError: (error: AxiosRequestConfig) => {
        Toast({
          text: error?.data?.message || 'Something went wrong ',
          variant: 'error',
        });
        setIsOpen(false);
        refetchUserInfo();
      },
    },
  );

  const onSubmit = (values: IInviteCoBorrowerFields) => {
    inviteMutation.mutate({
      email: values.coBorrowersEmail,
      firstName: values.coBorrowersFirstName,
      lastName: values.coBorrowersLastName,
      inviterId: profile.userId,
    });
  };
  return (
    <>
      <Modal
        open={isOpen && !profile.coBorrower}
        onClose={() => {
          setIsOpen(false);
        }}
        panelClassName="w-full lg:max-w-[800px] rounded-lg py-10 px-8 text-left "
        title="Invite Co-Borrower / Co-Applicant"
      >
        <Form
          id={'invite-co-borrower'}
          onSubmit={methods.handleSubmit((values) => {
            onSubmit(values);
          })}
          methods={methods}
        >
          <CustomInput.Input
            className="mb-4"
            label="Co-Borrower's First Name"
            name="coBorrowersFirstName"
            validations={{ required: true }}
          />
          <CustomInput.Input
            className="mb-4"
            label="Co-Borrower's Last Name"
            name="coBorrowersLastName"
            validations={{ required: true }}
          />
          <CustomInput.Email
            label="Co-Borrower's Email"
            type="email"
            name="coBorrowersEmail"
            responseError={inviteMutation.isError && inviteMutation.error}
            isError={inviteMutation.isError}
          />

          <Button type="submit" className="mt-10">
            Invite
          </Button>
        </Form>
        <Loading isLoading={inviteMutation.isLoading} />
      </Modal>
      <Button
        type="button"
        disabled={disabled}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {label ?? 'Invite Co-Borrower / Co-Applicant'}
      </Button>
    </>
  );
};
