import CopyTextIcon from '@icons/copy-text.svg';
import CloseIcon from '@icons/close.svg';
import { toast } from 'react-hot-toast';
import SuccessIcon from '@icons/tick-circle.svg';
import ErrorIcon from '@icons/circled-close.svg';

export const Toast = ({
  text,
  variant = 'primary',
  icon = variant === 'error' ? 'error' : 'success',
  duration,
}: {
  text: string;
  variant?: 'primary' | 'black' | 'error';
  icon?: 'copy' | 'success' | 'error';
  duration?: number;
}) =>
  toast.success(
    (t) => (
      <div className="flex items-center w-full text-left">
        {text}{' '}
        <button
          onClick={() => {
            toast.dismiss(t.id);
          }}
          className="ml-auto"
          type="button"
        >
          <CloseIcon width={20} height={20} strokeWidth={2} />
        </button>
      </div>
    ),
    {
      icon:
        icon === 'copy' ? (
          <CopyTextIcon className="shrink-0" width={20} height={20} />
        ) : icon === 'error' ? (
          <ErrorIcon className="shrink-0 keep-color" width={20} height={20} />
        ) : (
          <SuccessIcon className="shrink-0" width={20} height={20} />
        ),
      className: 'text-sm font-medium w-full lg:min-w-[600px] !text-white h-[48px] !px-4',
      id: 'clipboard',
      duration,
      style: {
        ...(variant === 'primary'
          ? {
              backgroundColor: '#0AAA82',
              color: 'white',
            }
          : variant === 'black'
          ? {
              backgroundColor: '#212121',
              color: 'white',
            }
          : variant === 'error'
          ? {
              backgroundColor: '#F56464',
              color: 'white',
            }
          : undefined),
        maxWidth: 'max-content',
        minWidth: 300,
        borderRadius: '4px',
      },
    },
  );
