import { useAuthUser } from '@hooks';
import { Toast } from '@utils';

export const useHandleReferralLink = () => {
  const { id } = useAuthUser(true);

  const handleReferralLink = () => {
    const origin = window.location.origin;
    navigator.clipboard.writeText(`${origin}/user/get-started?referredBy=${id}`).then(() => {
      Toast({ text: 'Referral link copied to clipboard.' });
    });
  };

  return handleReferralLink;
};
