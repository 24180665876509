import classNames from 'classnames';

interface ErrorMessageProps {
  error?: string | undefined;
  className?: string;
}

export const ErrorMessage = ({
  error = 'We encountered an error on our side. Please try again in a few minutes.',
  className,
}: ErrorMessageProps) => {
  return error ? (
    <p
      className={classNames(
        'text-red-500 pt-2 font-normal !text-xs first-letter:uppercase',
        className,
      )}
    >
      {error + (error[error.length - 1] === '.' ? '' : '.')}
    </p>
  ) : null;
};
